export const AMPLITUDE_EVENTS = {
  SSAI_LIVE_MODE_ENABLED: 'ssai live mode enabled',
  SSAI_LIVE_MODE_DISABLED: 'ssai live mode disabled',
  SSAI_PROACTIVE_OUTREACH_ENABLED: 'ssai proactive outreach enabled',
  SSAI_PROACTIVE_OUTREACH_DISABLED: 'ssai proactive outreach disabled',
};

export const SHOPPER_CONFIG_BASE_PATH = '/brand/shopper';
export const SHOPPER_OUTREACH_MESSAGES_PATH = `${SHOPPER_CONFIG_BASE_PATH}/outreach-messages`;
export const SHOPPER_DEMO_KEYWORD = 'SHOPPERDEMO';

export const SHOPPER_WELCOME_MODAL_ACKNOWLEDGE_KEY =
  'shopperWelcomeAcknowledged';

export const GET_SHOPPER_CONFIG_QUERY_KEY = ['get-shopper-config'] as const;
