/* eslint-disable @typescript-eslint/no-use-before-define */

import {
  DaysOfTheWeek,
  WaitActionCategories,
} from 'components/flowBuilder/constants';
import {
  ActionTypeStatuses,
  StaticActionsTypes,
  StaticActionsWithFormTypes,
} from 'components/flowBuilder/constants/actions';
import type {
  ABSplitAction,
  ABSplitActionParams,
  ActionParams,
  ActionType,
  ActionTypeAvailability,
  ActionTypeAvailabilityRuleset,
  ActionTypeStatus,
  ActionTypeWithStatus,
  ActionWithForm,
  AIManagedMessageAction,
  AIManagedMessageActionParams,
  AIManagedOutboundAction,
  AIManagedOutboundActionParams,
  BaseAction,
  BaseCategorizedWaitActionParams,
  BaseStaticAction,
  BaseWaitAction,
  DynamicAction,
  DynamicActionParams,
  DynamicActionType,
  DynamicSplitAction,
  EndAction,
  EndActionParams,
  LegacyWaitAction,
  LegacyWaitActionParams,
  NoCategoryWaitAction,
  NoCategoryWaitActionParams,
  OptimizedABSplitAction,
  OptimizedABSplitActionBackendConfig,
  OptimizedABSplitActionParams,
  RandomWaitAction,
  RandomWaitActionParams,
  RelativeWeekWaitAction,
  RelativeWeekWaitActionParams,
  RichMessageAction,
  RichMessageActionFrontendConfig,
  RichMessageActionParams,
  RichMessageFallback,
  SendMessageAction,
  SendMessageActionParams,
  SpecificDateTimeWaitAction,
  SpecificDateTimeWaitActionParams,
  SplitAction,
  StaticAction,
  StaticActionConfig,
  StaticActionParams,
  StaticActionsType,
  StaticActionsWithFormType,
  StaticActionType,
  StaticActionWithForm,
  StaticSplitAction,
  StaticSplitActionParams,
  SubscriberAttributeSplitAction,
  SubscriberAttributeSplitActionParams,
  TextToBuyAction,
  TextToBuyActionParams,
  TimeDeltaWaitAction,
  TimeDeltaWaitActionParams,
  TriggerEventSplitAction,
  TriggerEventSplitActionParams,
  UpdateSubscriberAction,
  UpdateSubscriberActionParams,
  WaitAction,
  WaitActionParams,
  WaitForEventSplitAction,
  WaitForEventSplitActionParams,
} from 'components/flowBuilder/types/actions';
import { Action } from 'components/flowBuilder/types/actions';
import { isDynamicField } from 'components/flowBuilder/types/dynamicFields/typeGuards';
import { BaseStepWithForm } from 'components/flowBuilder/types/steps';
import {
  isBaseStep,
  isBaseStepConfig,
  isBaseStepParams,
  isStaticStepsType,
} from 'components/flowBuilder/types/steps/typeGuards';
import {
  isABSplitActionBranch,
  isEventSplitBranch,
  isFlowType,
  isOptimizedABSplitTestStatus,
  isRichMessagePanel,
} from 'components/flowBuilder/types/typeGuards';
import { DYNAMIC_ACTION_TYPES } from 'components/flowBuilder/utils/dynamicActions';
import { Channels } from 'constants/constants';
import { BaseDynamicSplitField } from '../dynamicFields';

/* ----------------------------- Static Actions ----------------------------- */

export const isStaticActionsType = (
  type: unknown,
): type is StaticActionsType => {
  return Object.values<unknown>(StaticActionsTypes).includes(type);
};

export const isStaticActionsWithFormType = (
  type: unknown,
): type is StaticActionsWithFormType => {
  return Object.values<unknown>(StaticActionsWithFormTypes).includes(type);
};

export const isBaseStaticAction = (
  action: unknown,
): action is BaseStaticAction => {
  const actionValue = action as BaseStaticAction;

  if (!isBaseAction(actionValue)) return false;
  if (!isStaticActionsType(actionValue.type)) return false;

  return true;
};

export const isSendMessageAction = (
  action: unknown,
): action is SendMessageAction => {
  const actionValue = action as SendMessageAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isSendMessageActionType(actionValue.type)) return false;
  if (!isSendMessageActionParams(actionValue.params)) return false;

  return true;
};

export const isRichMessageAction = (
  action: unknown,
): action is RichMessageAction => {
  const actionValue = action as RichMessageAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isRichMessageActionType(actionValue.type)) return false;
  if (!isRichMessageActionParams(actionValue.params)) return false;
  if (
    !isRichMessageActionFrontendConfig(actionValue.frontendConfig) &&
    actionValue.frontendConfig !== undefined
  )
    return false;

  return true;
};

export const isBaseWaitAction = (action: unknown): action is BaseWaitAction => {
  const actionValue = action as BaseWaitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isWaitActionType(actionValue.type)) return false;

  return true;
};

export const isLegacyWaitAction = (
  action: unknown,
): action is LegacyWaitAction => {
  const actionValue = action as LegacyWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isLegacyWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isNoCategoryWaitAction = (
  action: unknown,
): action is NoCategoryWaitAction => {
  const actionValue = action as NoCategoryWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isNoCategoryWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isTimeDeltaWaitAction = (
  action: unknown,
): action is TimeDeltaWaitAction => {
  const actionValue = action as TimeDeltaWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isTimeDeltaWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isSpecificDateTimeWaitAction = (
  action: unknown,
): action is SpecificDateTimeWaitAction => {
  const actionValue = action as SpecificDateTimeWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isSpecificDateTimeWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isRelativeWeekWaitAction = (
  action: unknown,
): action is RelativeWeekWaitAction => {
  const actionValue = action as RelativeWeekWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isRelativeWeekWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isRandomWaitAction = (
  action: unknown,
): action is RandomWaitAction => {
  const actionValue = action as RandomWaitAction;

  if (!isBaseWaitAction(actionValue)) return false;
  if (!isRandomWaitActionParams(actionValue.params)) return false;

  return true;
};

export const isWaitAction = (action: unknown): action is WaitAction => {
  return (
    isNoCategoryWaitAction(action) ||
    isLegacyWaitAction(action) ||
    isTimeDeltaWaitAction(action) ||
    isSpecificDateTimeWaitAction(action) ||
    isRelativeWeekWaitAction(action) ||
    isRandomWaitAction(action)
  );
};

export const isUpdateSubscriberAction = (
  action: unknown,
): action is UpdateSubscriberAction => {
  const actionValue = action as UpdateSubscriberAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isUpdateSubscriberActionType(actionValue.type)) return false;
  if (!isUpdateSubscriberActionParams(actionValue.params)) return false;

  return true;
};

export const isABSplitAction = (action: unknown): action is ABSplitAction => {
  const actionValue = action as ABSplitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isABSplitActionType(actionValue.type)) return false;
  if (!isABSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isOptimizedABSplitAction = (
  action: unknown,
): action is OptimizedABSplitAction => {
  const actionValue = action as OptimizedABSplitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isOptimizedABSplitActionType(actionValue.type)) return false;
  if (!isOptimizedABSplitActionParams(actionValue.params)) return false;
  if (!isOptimizedABSplitActionBackendConfig(actionValue.backendConfig))
    return false;

  return true;
};

export const isWaitForEventSplitAction = (
  action: unknown,
): action is WaitForEventSplitAction => {
  const actionValue = action as WaitForEventSplitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isWaitForEventSplitActionType(actionValue.type)) return false;
  if (!isWaitForEventSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isTriggerEventSplitAction = (
  action: unknown,
): action is TriggerEventSplitAction => {
  const actionValue = action as TriggerEventSplitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isTriggerEventSplitActionType(actionValue.type)) return false;
  if (!isTriggerEventSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isSubscriberAttributeSplitAction = (
  action: unknown,
): action is SubscriberAttributeSplitAction => {
  const actionValue = action as SubscriberAttributeSplitAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isSubscriberAttributeSplitActionType(actionValue.type)) return false;
  if (!isSubscriberAttributeSplitActionParams(actionValue.params)) return false;

  return true;
};

export const isTextToBuyAction = (
  action: unknown,
): action is TextToBuyAction => {
  const actionValue = action as TextToBuyAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isTextToBuyActionType(actionValue.type)) return false;
  if (!isTextToBuyActionParams(actionValue.params)) return false;

  return true;
};

export const isAIManagedMessageAction = (
  action: unknown,
): action is AIManagedMessageAction => {
  const actionValue = action as AIManagedMessageAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isAIManagedMessageActionType(actionValue.type)) return false;
  if (!isAIManagedMessageActionParams(actionValue.params)) return false;

  return true;
};

export const isAIManagedOutboundAction = (
  action: unknown,
): action is AIManagedOutboundAction => {
  const actionValue = action as AIManagedOutboundAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isAIManagedOutboundActionType(actionValue.type)) return false;
  if (!isAIManagedOutboundActionParams(actionValue.params)) return false;

  return true;
};

export const isStaticSplitAction = (
  action: unknown,
): action is StaticSplitAction => {
  return (
    isABSplitAction(action) ||
    isOptimizedABSplitAction(action) ||
    isWaitForEventSplitAction(action) ||
    isTriggerEventSplitAction(action) ||
    isSubscriberAttributeSplitAction(action) ||
    isTextToBuyAction(action)
  );
};

export const isSplitAction = (action: unknown): action is SplitAction => {
  return isStaticSplitAction(action) || isDynamicSplitAction(action);
};

export const isEndAction = (action: unknown): action is EndAction => {
  const actionValue = action as EndAction;

  if (!isBaseStaticAction(actionValue)) return false;
  if (!isEndActionType(actionValue.type)) return false;
  if (!isEndActionParams(actionValue.params)) return false;

  return true;
};

export const isStaticActionWithForm = (
  action: unknown,
): action is StaticActionWithForm => {
  return (
    isSendMessageAction(action) ||
    isRichMessageAction(action) ||
    isWaitAction(action) ||
    isUpdateSubscriberAction(action) ||
    isSplitAction(action) ||
    isAIManagedMessageAction(action) ||
    isAIManagedOutboundAction(action)
  );
};

export const isStaticAction = (action: unknown): action is StaticAction => {
  return isStaticActionWithForm(action) || isEndAction(action);
};

export const isStaticActionType = (
  actionType: unknown,
): actionType is StaticActionType => {
  const actionTypeValue = actionType as StaticActionType;

  if (isStaticActionsType(actionTypeValue.name)) return false;
  if (typeof actionTypeValue.helpText !== 'string') return false;
  if (typeof actionTypeValue.category !== 'string') return false;

  return true;
};

/* ---------------------------- Dynamic Actions ----------------------------- */

export const isDynamicAction = (action: unknown): action is DynamicAction => {
  const actionValue = action as DynamicAction;

  if (!isBaseAction(actionValue)) return false;
  if (isStaticStepsType(actionValue.type)) return false;

  return true;
};

export const isDynamicActionType = (
  actionType: unknown,
): actionType is DynamicActionType => {
  const actionTypeValue = actionType as DynamicActionType;

  if (!isActionType(actionTypeValue)) return false;
  if (typeof actionTypeValue.description !== 'string') return false;
  if (!Array.isArray(actionTypeValue.fields)) return false;
  if (actionTypeValue.fields.some((field) => !isDynamicField(field)))
    return false;

  return true;
};

export const isDynamicSplitActionType = (
  dynamicActionType: unknown,
): boolean => {
  // If we keep the dynamic action type defitions on the FE, we can access them
  // in utilities like this, which is handy. Otherwise, we will need people who
  // implement dynamic action types in the BE to also update the FE to let us
  // know which dynamic action types are split actions.
  const dynamicActionSchema = DYNAMIC_ACTION_TYPES.find(
    (actionType) => actionType.type === dynamicActionType,
  );

  const splitField =
    dynamicActionSchema &&
    dynamicActionSchema.fields.find((field) => {
      // @TODO - Is there a better way to do this?
      return field.options.paramName === 'cases';
    });

  return !!splitField;
};

export const isDynamicSplitAction = (
  action: unknown,
): action is DynamicSplitAction => {
  return isDynamicAction(action) && isDynamicSplitActionType(action);
};

export const isDynamicSplitField = (
  field: unknown,
): field is BaseDynamicSplitField => {
  const fieldValue = field as BaseDynamicSplitField;

  if (!isDynamicField(fieldValue)) return false;
  if (fieldValue.options.paramName !== 'cases') return false;

  return true;
};

/* -------------------------------- Actions --------------------------------- */

export const isBaseAction = (action: unknown): action is BaseAction => {
  const actionValue = action as BaseStepWithForm;

  if (!isBaseStep(actionValue)) return false;
  if (!isBaseStepParams(actionValue.params)) return false;

  return true;
};

export const isActionWithForm = (action: unknown): action is ActionWithForm => {
  return isStaticActionWithForm(action) || isDynamicAction(action);
};

export const isAction = (action: unknown): action is Action => {
  return isStaticAction(action) || isDynamicAction(action);
};

export const isActionTypeAvailabilityRuleset = (
  ruleset: unknown,
): ruleset is ActionTypeAvailabilityRuleset => {
  const rulesetValue = ruleset as ActionTypeAvailabilityRuleset;

  if (!rulesetValue || typeof rulesetValue !== 'object') return false;
  if (
    typeof rulesetValue.featureFlag !== 'string' &&
    rulesetValue.featureFlag !== undefined
  )
    return false;
  if (
    !Array.isArray(rulesetValue.flowTypes) &&
    rulesetValue.flowTypes !== undefined
  )
    return false;
  if (rulesetValue.flowTypes?.some((type) => !isFlowType(type))) return false;
  if (
    rulesetValue.transactional !== true &&
    rulesetValue.transactional !== undefined
  )
    return false;
  if (
    !Array.isArray(rulesetValue.triggerEvents) &&
    rulesetValue.triggerEvents !== undefined
  )
    return false;
  if (rulesetValue.triggerEvents?.some((type) => typeof type !== 'string'))
    return false;

  return true;
};

export const isActionTypeAvailability = (
  availability: unknown,
): availability is ActionTypeAvailability => {
  const availabilityValue = availability as ActionTypeAvailability;

  if (!availabilityValue || typeof availabilityValue !== 'object') return false;
  if (
    !isActionTypeAvailabilityRuleset(availabilityValue.enabled) &&
    availabilityValue.enabled !== undefined
  )
    return false;
  if (
    !isActionTypeAvailabilityRuleset(availabilityValue.hidden) &&
    availabilityValue.hidden !== undefined
  )
    return false;

  return true;
};

export const isActionType = (actionType: unknown): actionType is ActionType => {
  const actionTypeValue = actionType as ActionType;

  if (!actionTypeValue || typeof actionTypeValue !== 'object') return false;
  if (
    !isActionTypeAvailability(actionTypeValue.availability) &&
    actionTypeValue.availability !== undefined
  )
    return false;
  if (
    typeof actionTypeValue.badge !== 'string' &&
    actionTypeValue.badge !== null
  )
    return false;
  if (typeof actionTypeValue.category !== 'string') return false;
  if (typeof actionTypeValue.helpText !== 'string') return false;
  if (typeof actionTypeValue.iconName !== 'string') return false;
  if (typeof actionTypeValue.label !== 'string') return false;
  if (typeof actionTypeValue.type !== 'string') return false;

  return true;
};

export const isActionTypeStatus = (type: unknown): type is ActionTypeStatus => {
  return Object.values<unknown>(ActionTypeStatuses).includes(type);
};

export const isActionTypeWithStatus = (
  actionType: unknown,
): actionType is ActionTypeWithStatus => {
  const actionTypeValue = actionType as ActionTypeWithStatus;

  if (!isActionType(actionTypeValue)) return false;
  if (!isActionTypeStatus(actionTypeValue.status)) return false;

  return true;
};

/* --------------------------- Static Action Types -------------------------- */

export const isSendMessageActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.SEND_MESSAGE => {
  return type === StaticActionsTypes.SEND_MESSAGE;
};

export const isRichMessageActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.RICH_MESSAGE => {
  return type === StaticActionsTypes.RICH_MESSAGE;
};

export const isWaitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.WAIT => {
  return type === StaticActionsTypes.WAIT;
};

export const isUpdateSubscriberActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.UPDATE_SUBSCRIBER => {
  return type === StaticActionsTypes.UPDATE_SUBSCRIBER;
};

export const isABSplitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.AB_SPLIT => {
  return type === StaticActionsTypes.AB_SPLIT;
};

export const isOptimizedABSplitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.OPTIMIZED_AB_SPLIT => {
  return type === StaticActionsTypes.OPTIMIZED_AB_SPLIT;
};

export const isWaitForEventSplitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.WAIT_FOR_EVENT_SPLIT => {
  return type === StaticActionsTypes.WAIT_FOR_EVENT_SPLIT;
};

export const isTriggerEventSplitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.TRIGGER_EVENT_SPLIT => {
  return type === StaticActionsTypes.TRIGGER_EVENT_SPLIT;
};

export const isSubscriberAttributeSplitActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.SUBSCRIBER_ATTRIBUTE_SPLIT => {
  return type === StaticActionsTypes.SUBSCRIBER_ATTRIBUTE_SPLIT;
};

export const isTextToBuyActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.TEXT_TO_BUY => {
  return type === StaticActionsTypes.TEXT_TO_BUY;
};

export const isAIManagedMessageActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.AI_MANAGED_MESSAGE => {
  return type === StaticActionsTypes.AI_MANAGED_MESSAGE;
};

export const isAIManagedOutboundActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.AI_MANAGED_OUTBOUND => {
  return type === StaticActionsTypes.AI_MANAGED_OUTBOUND;
};

export const isSplitActionType = (type: unknown): boolean => {
  return (
    isABSplitActionType(type) ||
    isOptimizedABSplitActionType(type) ||
    isWaitForEventSplitActionType(type) ||
    isTriggerEventSplitActionType(type) ||
    isSubscriberAttributeSplitActionType(type) ||
    isTextToBuyActionType(type) ||
    isDynamicSplitActionType(type)
  );
};

export const isEndActionType = (
  type: unknown,
): type is typeof StaticActionsTypes.END => {
  return type === StaticActionsTypes.END;
};

/* -------------------------- Static Action Params -------------------------- */

export const isSendMessageActionParams = (
  params: unknown,
): params is SendMessageActionParams => {
  const paramsValue = params as SendMessageActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (typeof paramsValue.content !== 'string') return false;
  if (
    typeof paramsValue.mediaUrl !== 'string' &&
    paramsValue.mediaUrl !== null &&
    paramsValue.mediaUrl !== undefined
  )
    return false;

  return true;
};

export const isRichMessageFallback = (
  fallback: unknown,
): fallback is RichMessageFallback => {
  const fallbackValue = fallback as RichMessageFallback;

  if (!isSendMessageActionParams(fallbackValue)) return false;
  if (!fallbackValue.guid || typeof fallbackValue.guid !== 'string')
    return false;

  return true;
};

export const isRichMessageActionParams = (
  params: unknown,
): params is RichMessageActionParams => {
  const paramsValue = params as RichMessageActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (paramsValue.channel !== Channels.RCS) return false;
  if (typeof paramsValue.content !== 'string') return false;
  if (!isRichMessageFallback(paramsValue.fallback)) return false;
  if (
    typeof paramsValue.mediaUrl !== 'string' &&
    paramsValue.mediaUrl !== null &&
    paramsValue.mediaUrl !== undefined
  )
    return false;

  return true;
};

export const isLegacyWaitActionParams = (
  params: unknown,
): params is LegacyWaitActionParams => {
  const paramsValue = params as LegacyWaitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (paramsValue.category !== undefined) return false;
  if (typeof paramsValue.duration !== 'string') return false;

  return true;
};

export const isBaseCategorizedWaitActionParams = (
  params: unknown,
): params is BaseCategorizedWaitActionParams => {
  const paramsValue = params as BaseCategorizedWaitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (
    !Object.values<unknown>(WaitActionCategories).includes(
      paramsValue.category,
    ) &&
    paramsValue.category !== null
  )
    return false;
  if (
    typeof paramsValue.useSubscriberTz !== 'boolean' &&
    paramsValue.useSubscriberTz !== null &&
    paramsValue.useSubscriberTz !== undefined
  )
    return false;
  if (
    typeof paramsValue.clientTzOffset !== 'string' &&
    paramsValue.clientTzOffset !== null &&
    paramsValue.clientTzOffset !== undefined
  )
    return false;

  return true;
};

export const isNoCategoryWaitActionParams = (
  params: unknown,
): params is NoCategoryWaitActionParams => {
  const paramsValue = params as NoCategoryWaitActionParams;

  if (!isBaseCategorizedWaitActionParams(paramsValue)) return false;
  if (paramsValue.category !== null) return false;

  return true;
};

export const isTimeDeltaWaitActionParams = (
  params: unknown,
): params is TimeDeltaWaitActionParams => {
  const paramsValue = params as TimeDeltaWaitActionParams;

  if (!isBaseCategorizedWaitActionParams(paramsValue)) return false;
  if (paramsValue.category !== WaitActionCategories.TIMEDELTA) return false;
  if (typeof paramsValue.duration !== 'string') return false;

  return true;
};

export const isSpecificDateTimeWaitActionParams = (
  params: unknown,
): params is SpecificDateTimeWaitActionParams => {
  const paramsValue = params as SpecificDateTimeWaitActionParams;

  if (!isBaseCategorizedWaitActionParams(paramsValue)) return false;
  if (paramsValue.category !== WaitActionCategories.SPECIFIC_DATETIME)
    return false;
  if (typeof paramsValue.date !== 'string') return false;
  if (
    typeof paramsValue.time !== 'string' &&
    paramsValue.time !== null &&
    paramsValue.time !== undefined
  )
    return false;
  if (
    typeof paramsValue.useRecommendedSendTime !== 'boolean' &&
    paramsValue.useRecommendedSendTime !== null &&
    paramsValue.useRecommendedSendTime !== undefined
  )
    return false;

  return true;
};

export const isRelativeWeekWaitActionParams = (
  params: unknown,
): params is RelativeWeekWaitActionParams => {
  const paramsValue = params as RelativeWeekWaitActionParams;

  if (!isBaseCategorizedWaitActionParams(paramsValue)) return false;
  if (paramsValue.category !== WaitActionCategories.RELATIVE_WEEK) return false;
  if (!Object.values<unknown>(DaysOfTheWeek).includes(paramsValue.relativeDate))
    return false;
  if (typeof paramsValue.time !== 'string') return false;

  return true;
};

export const isRandomWaitActionParams = (
  params: unknown,
): params is RandomWaitActionParams => {
  const paramsValue = params as RandomWaitActionParams;

  if (!isBaseCategorizedWaitActionParams(paramsValue)) return false;
  if (paramsValue.category !== WaitActionCategories.RANDOM) return false;
  if (typeof paramsValue.minDuration !== 'string') return false;
  if (typeof paramsValue.maxDuration !== 'string') return false;

  return true;
};

export const isWaitActionParams = (
  params: unknown,
): params is WaitActionParams => {
  return (
    isLegacyWaitActionParams(params) ||
    isNoCategoryWaitActionParams(params) ||
    isTimeDeltaWaitActionParams(params) ||
    isSpecificDateTimeWaitActionParams(params) ||
    isRelativeWeekWaitActionParams(params) ||
    isRandomWaitActionParams(params)
  );
};

export const isUpdateSubscriberActionParams = (
  params: unknown,
): params is UpdateSubscriberActionParams => {
  const paramsValue = params as UpdateSubscriberActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.addTags)) return false;
  if (paramsValue.addTags.some((tag) => typeof tag !== 'string'))
    if (!Array.isArray(paramsValue.removeTags)) return false;
  if (paramsValue.removeTags.some((tag) => typeof tag !== 'string'))
    return false;

  return true;
};

export const isABSplitActionParams = (
  params: unknown,
): params is ABSplitActionParams => {
  const paramsValue = params as ABSplitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.cases)) return false;
  if (paramsValue.cases.some((branch) => !isABSplitActionBranch(branch)))
    return false;

  return true;
};

export const isOptimizedABSplitActionParams = (
  params: unknown,
): params is OptimizedABSplitActionParams => {
  const paramsValue = params as OptimizedABSplitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.cases)) return false;
  if (paramsValue.cases.some((branch) => !isABSplitActionBranch(branch)))
    return false;
  if (
    paramsValue.holdbackRatio !== undefined &&
    paramsValue.holdbackRatio !== null &&
    typeof paramsValue.holdbackRatio !== 'number'
  )
    return false;
  if (
    paramsValue.maxDuration !== undefined &&
    paramsValue.maxDuration !== null &&
    typeof paramsValue.maxDuration !== 'string'
  )
    return false;
  if (typeof paramsValue.metric !== 'string') return false;

  return true;
};

export const isWaitForEventSplitActionParams = (
  params: unknown,
): params is WaitForEventSplitActionParams => {
  const paramsValue = params as WaitForEventSplitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.cases)) return false;
  if (paramsValue.cases.some((branch) => !isEventSplitBranch(branch)))
    return false;

  return true;
};

export const isTriggerEventSplitActionParams = (
  params: unknown,
): params is TriggerEventSplitActionParams => {
  const paramsValue = params as TriggerEventSplitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.cases)) return false;
  if (paramsValue.cases.some((branch) => !isEventSplitBranch(branch)))
    return false;

  return true;
};

export const isSubscriberAttributeSplitActionParams = (
  params: unknown,
): params is SubscriberAttributeSplitActionParams => {
  const paramsValue = params as SubscriberAttributeSplitActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.cases)) return false;
  if (paramsValue.cases.some((branch) => !isEventSplitBranch(branch)))
    return false;

  return true;
};

export const isTextToBuyActionParams = (
  params: unknown,
): params is TextToBuyActionParams => {
  const paramsValue = params as TextToBuyActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (!isSendMessageActionParams(paramsValue)) return false;
  if (!Array.isArray(paramsValue.products)) return false;

  return true;
};

export const isAIManagedMessageActionParams = (
  params: unknown,
): params is AIManagedMessageActionParams => {
  const paramsValue = params as AIManagedMessageActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  if (
    paramsValue.content !== undefined &&
    paramsValue.content !== null &&
    typeof paramsValue.content !== 'string'
  )
    return false;

  return true;
};

export const isAIManagedOutboundActionParams = (
  params: unknown,
): params is AIManagedOutboundActionParams => {
  const paramsValue = params as AIManagedOutboundActionParams;

  if (!isBaseStepParams(paramsValue)) return false;
  return true;
};

export const isStaticSplitActionParams = (
  params: unknown,
): params is StaticSplitActionParams => {
  return (
    isABSplitActionParams(params) ||
    isOptimizedABSplitActionParams(params) ||
    isWaitForEventSplitActionParams(params) ||
    isTriggerEventSplitActionParams(params) ||
    isSubscriberAttributeSplitActionParams(params) ||
    isTextToBuyActionParams(params)
  );
};

export const isEndActionParams = (
  params: unknown,
): params is EndActionParams => {
  const paramsValue = params as EndActionParams;

  if (!isBaseStepParams(paramsValue)) return false;

  return true;
};

export const isStaticActionParams = (
  params: unknown,
): params is StaticActionParams => {
  return (
    isSendMessageActionParams(params) ||
    isRichMessageActionParams(params) ||
    isWaitActionParams(params) ||
    isUpdateSubscriberActionParams(params) ||
    isStaticSplitActionParams(params) ||
    isAIManagedMessageActionParams(params) ||
    isEndActionParams(params)
  );
};

/* ------------------------- Dynamic Action Params -------------------------- */

export const isDynamicActionParams = (
  params: unknown,
): params is DynamicActionParams => {
  return !!params && typeof params === 'object';
};

/* ----------------------------- Action Params ------------------------------ */

export const isActionParams = (params: unknown): params is ActionParams => {
  return isStaticActionParams(params) || isDynamicActionParams(params);
};

/* -------------------------- Static Action Config -------------------------- */

export const isRichMessageActionFrontendConfig = (
  config: unknown,
): config is RichMessageActionFrontendConfig => {
  const configValue = config as RichMessageActionFrontendConfig;

  if (!isBaseStepConfig(configValue)) return false;
  if (!isRichMessagePanel(configValue.selectedPanel)) return false;

  return true;
};

export const isOptimizedABSplitActionBackendConfig = (
  config: unknown,
): config is OptimizedABSplitActionBackendConfig => {
  const configValue = config as OptimizedABSplitActionBackendConfig;

  if (!isBaseStepConfig(configValue)) return false;
  if (!isOptimizedABSplitTestStatus(configValue.test_status)) return false;
  if (
    configValue.status_updated_at !== undefined &&
    configValue.status_updated_at !== null &&
    typeof configValue.status_updated_at !== 'string'
  )
    return false;

  return true;
};

export const isStaticActionConfig = (
  config: unknown,
): config is StaticActionConfig => {
  return (
    isRichMessageActionFrontendConfig(config) ||
    isOptimizedABSplitActionBackendConfig(config)
  );
};

export const isActionConfig = isStaticActionConfig;
