import { toast } from '@postscript/components';
import Events from './events';
import Requests from './network/network_requests';
import Utils from './utils';

const YC = {
  login() {
    const path = '/login';
    const payload = {
      username: 'test@test.com',
      password: 'test',
    };
    Events.track('YC Login');
    Requests.post(path, payload).then(function (result) {
      if (result.access_token) {
        Utils.saveAuthTokens({
          accessToken: result.access_token,
          refreshToken: result.refresh_token,
        });
        window.location.replace('/dashboard');
      } else {
        toast.error('Invalid email address and/or password. Please try again.');
      }
    });
  },
};

export default YC;
