import {
  ShopperVerificationStatus,
  TFNVerificationStatus,
} from 'components/notifications/types';
import { api } from 'controllers/network/apiClient';

export type BillingAccountStatus =
  | 'ACTIVE'
  | 'VOLUNTARY_DEACTIVATED'
  | 'SYSTEMATIC_DEACTIVATED';

interface BillingStatus {
  account: BillingAccountStatus;
}

interface ComplianceStatus {
  tfn: TFNVerificationStatus;
  shopper: ShopperVerificationStatus;
}

interface ShopifyStatus {
  installed: boolean;
}

export interface AccountStatus {
  billing: BillingStatus;
  compliance: ComplianceStatus;
  shopify: ShopifyStatus;
}

const url = '/v2/shops';

// Get account status for this shop
export async function getAccountStatus(): Promise<AccountStatus> {
  return api.get(`${url}/account_status/status`);
}

// Set account status for this shop
export async function setAccountStatus(
  status: Partial<AccountStatus>,
): Promise<AccountStatus> {
  return api.post(`${url}/account_status/status`, status);
}
