import { MESSAGE_SUBTYPES } from '../constants';
import { StyledMetadata } from '../styles/message';
import { Message } from '../types';

interface MessageMetadataProps {
  message: Message;
  showTimestamp?: boolean;
}

const MessageMetadata = ({
  message,
  showTimestamp = true,
}: MessageMetadataProps) => {
  return (
    <StyledMetadata
      messageType={message.type}
      incoming={message.direction === 'incoming'}
    >
      {message.direction === 'outgoing' &&
      message.subtype === MESSAGE_SUBTYPES.SHOPPER ? (
        <span>
          Sent by Shopper
          {showTimestamp && ` • ${message.createdAt.format('HH:mm')}`}
        </span>
      ) : (
        showTimestamp && message.createdAt.format('HH:mm')
      )}
    </StyledMetadata>
  );
};

export default MessageMetadata;
