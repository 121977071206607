import useAccountStatus from 'components/account/useAccountStatus';
import { useHasProductAddOn } from 'components/billing/modules/plans/hooks/useHasProductAddOn';
import { useProductTrialStatus } from 'components/billing/modules/plans/hooks/useProductTrialStatus';
import { useShopperStatus } from 'hooks/useShopperStatus';

/**
 * Provide information on the billing status of Shopper.
 */
export const useShopperBillingStatus = () => {
  const { isEligibleForShopperSelfServe } = useShopperStatus();
  const { shopperVerificationStatus } = useAccountStatus();
  const hasProduct = useHasProductAddOn('SHOPPER', 'SHOPPER_PLATFORM');
  const { onProductFreeTrial, hasExpiredProductFreeTrial } =
    useProductTrialStatus('SHOPPER_PLATFORM');

  const isEligible =
    isEligibleForShopperSelfServe && shopperVerificationStatus === 'ACTIVE';

  return {
    /** Is eligible for a Shopper plan, and has not been manually denied compliance status */
    isEligible,

    /** Has a Shopper plan */
    hasProduct,

    /** Is eligible for a free trial of Shopper. Mutually exclusive with hasProduct. */
    trialAvailable: isEligible && !hasProduct && !hasExpiredProductFreeTrial,

    /** Is currently on a trial of Shopper */
    trialOngoing: onProductFreeTrial,

    /** Has an expired trial of Shopper */
    trialExpired: hasExpiredProductFreeTrial,
  };
};
