import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { SUBSCRIBER_CHAT_CHANNEL_PREFIX } from '../constants';
import useBroadcastToChannel from './useBroadcastToChannel';
import useWebSocket from './useWebSocket';

export const getChannel = (id = 0): string => {
  return `${SUBSCRIBER_CHAT_CHANNEL_PREFIX}-${id}`;
};

interface UseSubscriberChannel {
  emitIsTyping: () => void;
  emitSentMessage: () => void;
}

const useSubscriberChannel = (subscriberId?: number): UseSubscriberChannel => {
  const client = useQueryClient();
  const channel = getChannel(subscriberId);
  const { broadcast } = useBroadcastToChannel(channel);
  const [lastEmitIsTyping, setLastEmitIsTyping] = useState(0);
  const { lastMessage } = useWebSocket();

  useEffect(() => {
    const isCurrentChannel = lastMessage?.channel === channel;
    const shouldInvalidateSubscriberQuery =
      lastMessage?.type === 'broadcast_to_channel' &&
      lastMessage?.message?.type === 'user_sent_message';
    if (lastMessage && isCurrentChannel && shouldInvalidateSubscriberQuery) {
      client.invalidateQueries(['subscriber']);
    }
  }, [lastMessage]);

  const emitIsTyping = () => {
    // Only emit every 5 seconds
    if (subscriberId && Date.now() - lastEmitIsTyping > 5_000) {
      broadcast({ type: 'user_typing' });
      setLastEmitIsTyping(Date.now());
    }
  };

  const emitSentMessage = () => {
    broadcast({ type: 'user_sent_message' });
  };

  // emit methods are memoized to prevent unnecessary re-renders
  return useMemo(
    () => ({
      emitIsTyping,
      emitSentMessage,
    }),
    [],
  );
};

export default useSubscriberChannel;
