import { api } from 'controllers/network/apiClient';
import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';

interface TotalSubscribersResponse {
  total: number;
}

export const TOTAL_SUBSCRIBERS_QUERY_KEY = 'totalSubscribers';
export const TOTAL_SUBSCRIBERS_URL = '/v2/subscribers/total/';

const fetchTotalSubscribers = async (): Promise<number> => {
  const response = await api.get<TotalSubscribersResponse>(
    TOTAL_SUBSCRIBERS_URL,
  );
  return response.total;
};

export const useTotalSubscribers = (
  options?: UseQueryOptions<
    number,
    any,
    number,
    [typeof TOTAL_SUBSCRIBERS_QUERY_KEY]
  >,
): UseQueryResult<number> =>
  useQuery([TOTAL_SUBSCRIBERS_QUERY_KEY], fetchTotalSubscribers, {
    ...options,
    staleTime: 5_000 * 60,
  });
