import { useBanners } from 'components/GlobalBanners/globalBanners';
import { useEffect } from 'react';
import { usePSLabs } from '../../controllers/contexts/labsFeatures';
import { PS_PAY } from '../admin/utils/feature-flags';

const OnboardingStatusBanner = () => {
  const {
    location: { pathname: path },
  } = window;

  const { addBanner, removeBanner } = useBanners();
  const { hasLabsFlag } = usePSLabs();

  const updateStatusAndBanner = async () => {
    // /* FYI, killed the API call because no longer exists. All the PSPay code needs ripping out. */
    // const { action_message: actionMessage, action_url: actionUrl } =
    //   await api.get('/v2/pspay/shop/status');

    const actionMessage = '';
    const actionUrl = '';

    if (actionMessage && !path.includes('/pspay-customer')) {
      addBanner({
        id: 'pspay-setup',
        isDismissable: true,
        data: {
          variant: 'warning',
          heading: 'Complete Text-to-Buy setup',
          bodyText: actionMessage,
          primaryAction: {
            text: 'Update on Shopify',
            onClick: () => {
              removeBanner('pspay-setup');
              window.location.replace(actionUrl);
            },
          },
        },
      });
    }
  };

  useEffect(() => {
    updateStatusAndBanner();
  }, [hasLabsFlag(PS_PAY)]);

  return null;
};

export default OnboardingStatusBanner;
