import { useQuery } from 'react-query';

import {
  useGetInvoicingSettings,
  useGetPaymentMethod,
} from 'components/billing/context/useBilling';
import { api } from 'controllers/network/apiClient';

import { StripePaymentMethodsResponse } from 'components/billing/common/types';

export const paymentMethodsQueryKey = [
  'billing',
  'stripe',
  'paymentMethods',
] as const;

/**
 * Aggregates all of the payment methods available to the user, the active payment method,
 * and the verification status of Stripe payment methods
 */
export const usePaymentMethods = () => {
  /** Identify if the payment provider is Shopify */
  const activePaymentMethod = useGetPaymentMethod();

  /** Identify if the Shop is making manual payments */
  const invoicingSettings = useGetInvoicingSettings();

  /** List all of the available payment methods from Stripe including the default payment method */
  const stripePaymentMethods = useQuery({
    queryKey: paymentMethodsQueryKey,
    queryFn: async () => {
      const paymentMethods = await api.get(
        '/v2/billing/payments/stripe/payment_methods',
      );

      return paymentMethods as StripePaymentMethodsResponse;
    },
  });

  return {
    isLoading:
      activePaymentMethod.isLoading ||
      invoicingSettings.isLoading ||
      stripePaymentMethods.isLoading,
    isShopifyBilled: activePaymentMethod?.data?.paymentProvider === 'SHOPIFY',
    isManuallyBilled: invoicingSettings?.data?.manualPayments,
    stripePaymentMethods: stripePaymentMethods.data?.paymentMethods.map(
      (paymentMethod) => ({
        ...paymentMethod,
        isDefault:
          paymentMethod.id ===
          stripePaymentMethods.data?.defaultPaymentMethodId,

        // TODO: Pretty sure we're only getting verified payment methods from the API
        isVerified: true,
      }),
    ),
  };
};
