import { Button, DataCard, Icon, IconSet } from '@postscript/components';
import PageHeader from 'components/layout/PageHeader';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';
import {
  AGENT_DASHBOARD_QUERY,
  useAgentDashboard,
} from '../hooks/useDashboard';
import { AgentInfo } from '../types';
import { setTitleWithCount } from '../utils/setTitleWithCount';
import AgentList from './AgentList';

const StyledDashboardAnalytics = styled.div`
  display: grid;
  gap: var(--spacing-2);
  grid-template-columns: repeat(
    auto-fit,
    minmax(calc(var(--spacing-1) * 30), 1fr)
  );
  margin-bottom: var(--spacing-6);
`;

const AgentDashboard = (): JSX.Element => {
  const { data, isLoading } = useAgentDashboard();
  const queryClient = useQueryClient();
  const refreshAgentData = () => {
    queryClient.invalidateQueries([AGENT_DASHBOARD_QUERY]);
  };

  const onlineAgents: number = Object.values(data?.active_agents ?? {}).filter(
    (agent?: AgentInfo): boolean => agent?.status === 'online',
  ).length;

  // Need to set the page title with the total online agents
  setTitleWithCount(onlineAgents);

  return (
    <div>
      <PageHeader
        pageTitle="Agent Activity"
        description="View current state of SMS Sales agents."
        actions={<Button onClick={() => refreshAgentData()}>Refresh</Button>}
      />
      {data && (
        <>
          <StyledDashboardAnalytics>
            <DataCard
              accordion={false}
              description="Total number of agents online for at least one store"
              expanded
              metric={onlineAgents.toString()}
              title="Agents Online"
              visual={<Icon component={IconSet.Users} size="large" />}
            />

            <DataCard
              accordion={false}
              description="Total number of prospects currently assigned to any agent"
              expanded
              metric={Object.values(data.active_agents).reduce(
                (sum, agent) => agent.assigned_conversations.length + sum,
                0,
              )}
              title="Assigned Prospects"
              visual={<Icon component={IconSet.Message} size="large" />}
            />
          </StyledDashboardAnalytics>

          <AgentList data={data} isLoading={isLoading} />
        </>
      )}
    </div>
  );
};

export default AgentDashboard;
