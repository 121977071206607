import { CORE_EVENT_TRIGGER_NAMES } from 'constants/constants';
import produce from 'immer';
import { get, isEmpty, isNil, omit, omitBy } from 'lodash';
import PropTypes from 'prop-types';
import qs, { stringify } from 'qs';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { api } from '../../controllers/network/apiClient';
import Requests from '../network/network_requests';

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const SET_SHOP_OPTIONS = 'SET_SHOP_OPTIONS';
const SET_TAGS = 'SET_TAGS';
const SET_TOTAL_RESULTS = 'SET_TOTAL_RESULTS';
const DATA_TYPE = 'templates';

const defaultInitialState = {
  loading: false,
  shopOptions: [],
  tags: [],
  [DATA_TYPE]: null,
};

// Deprecated in that they use a trigger event that we support in automation flows,
// so we prefer that users create create a flow instead if they're wanting to use
// a "core" trigger event.
const removeDeprecatedTemplates = (templates) => {
  return templates.filter((template) => {
    const triggerName = template?.data?.trigger?.name;

    if (triggerName) {
      return !CORE_EVENT_TRIGGER_NAMES.includes(triggerName);
    }

    return true;
  });
};

export const AutomationTemplatesContext = createContext(defaultInitialState);
export const useAutomationTemplates = () =>
  useContext(AutomationTemplatesContext);

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_TOTAL_RESULTS:
      draft.totalResults = action.totalResults;
      break;
    case SET_TAGS:
      draft.tags = action.tags;
      break;
    case SET_SHOP_OPTIONS:
      draft.shopOptions = action.shopOptions;
      break;
    case SET_ITEMS:
      draft[DATA_TYPE] = action.data;
      break;
    case ADD_ITEM:
      if (isNil(draft[DATA_TYPE])) draft[DATA_TYPE] = [];

      draft[DATA_TYPE].push(action.data);
      break;
    case UPDATE_ITEM: {
      if (isEmpty(draft[DATA_TYPE])) return draft;

      const index = draft[DATA_TYPE].findIndex(
        ({ slug }) => slug === action.slug,
      );

      const item = draft[DATA_TYPE][index];

      draft[DATA_TYPE][index] = {
        ...item,
        ...action.data,
      };
      break;
    }
    case REMOVE_ITEM:
      if (isEmpty(draft[DATA_TYPE])) return draft;

      draft[DATA_TYPE] = draft[DATA_TYPE].filter(
        ({ slug }) => slug !== action.slug,
      );
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const AutomationTemplatesProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { search: searchParamsString, pathname } = useLocation();
  const { push } = useHistory();

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setItems = (data) => {
    dispatch({
      type: SET_ITEMS,
      data,
    });
  };

  const addItem = (data) => {
    dispatch({
      type: ADD_ITEM,
      data,
    });
  };

  const updateItem = (slug, data) => {
    dispatch({
      type: UPDATE_ITEM,
      slug,
      data,
    });
  };

  const setShopOptions = (shopOptions) => {
    dispatch({
      type: SET_SHOP_OPTIONS,
      shopOptions,
    });
  };

  const setTags = (tags) => {
    dispatch({
      type: SET_TAGS,
      tags,
    });
  };

  const removeItem = (slug) => {
    dispatch({
      type: REMOVE_ITEM,
      slug,
    });
  };

  const fetchTemplate = async (slug) => {
    const automationTemplate = (state.templates || []).find(
      (template) => template.slug === slug,
    );

    if (!isEmpty(automationTemplate)) return automationTemplate;

    const { data: template } = await Requests.get(
      `/automations/templates/${slug}`,
    );

    return template;
  };

  const setTotalResults = (totalResults) => {
    dispatch({
      type: SET_TOTAL_RESULTS,
      totalResults,
    });
  };

  const search = async ({
    title = '',
    tags = [],
    shopIds = [],
    me = true,
    limit = 35,
    page = 1,
    catagory = '',
  } = {}) => {
    setLoading(true);

    const paramArguments = omitBy(
      {
        title,
        shop_ids: shopIds.join(),
        tags: tags.join(),
        me: me.toString(),
        limit,
        page,
        catagory,
      },
      (val) => isEmpty(val) && !Number.isInteger(val),
    );

    const params = stringify(paramArguments);
    const urlParams = stringify(omit(paramArguments, ['me']));

    const { data } = await Requests.get(`/automations/templates?${params}`);
    const { templates } = data;
    const filteredTemplates = removeDeprecatedTemplates(templates);

    if (
      ['/automations/create', '/admin/templates'].some((templatePath) =>
        pathname.includes(templatePath),
      )
    ) {
      const p = qs.parse(urlParams, { ignoreQueryPrefix: true });
      const existing = qs.parse(window.location.search, {
        ignoreQueryPrefix: true,
      });
      push({
        search: `?${qs.stringify({ ...existing, ...p })}`,
      });
    }

    setItems(filteredTemplates);
    setTotalResults(data.total);
    setLoading(false);

    return data;
  };

  const getAllShopOptions = async () => {
    const { shops } = await api.get('/admin/get_all_shops');

    setShopOptions(shops);
  };

  const getTags = async () => {
    const {
      data: { tags },
    } = await Requests.get('/tags');

    setTags(
      tags.map(({ name }) => ({
        value: name,
        label: name,
      })),
    );
  };

  const create = async (automationTemplate) => {
    const { data } = await Requests.post(
      '/automations/templates',
      automationTemplate,
      false,
      'POST',
      false,
      true,
    ).catch((errors) => {
      throw get(errors, '[0]', 'An unknown error has occurred.');
    });

    addItem(data);
    return data;
  };

  const createAutomationFromTemplate = async (automationTemplateId) => {
    // pass empty payload for legacy endpoint
    const { automation, error = '' } = await Requests.post(
      `/automations/save?from_template=${automationTemplateId}`,
      {},
    );

    if (error) {
      throw new Error(error);
    }

    return automation;
  };

  const update = async (automationTemplateUpdate) => {
    const { data } = await Requests.put(
      `/automations/templates/${automationTemplateUpdate.slug}`,
      automationTemplateUpdate,
    );

    updateItem(data.slug, data);

    return data;
  };

  const createTemplateFromAutomation = async (id, values) => {
    const template = await Requests.post(
      `/automations/templates?from_automation=${id}`,
      values,
      false,
      'POST',
      false,
      true,
    ).catch((errors) => {
      throw get(errors, '[0]', 'An unknown error has occurred.');
    });

    addItem({
      template,
    });

    return template;
  };

  const remove = async (slug) => {
    await Requests.delete(`/automations/templates/${slug}`);

    removeItem(slug);
  };

  const getQueryParameters = () => {
    const parsedParams = qs.parse(searchParamsString, {
      ignoreQueryPrefix: true,
    });

    const formattedParams = {
      title: parsedParams.title,
      shop_ids: !isEmpty(parsedParams.shopIds)
        ? parsedParams.shopIds.split(',')
        : [],
      tags: !isEmpty(parsedParams.tags) ? parsedParams.tags.split(',') : [],
      me: parsedParams.me === 'true',
      limit: parsedParams.limit,
      page: parsedParams.page,
    };

    return formattedParams;
  };

  useEffect(() => {
    getTags();
  }, []);

  return (
    <AutomationTemplatesContext.Provider
      value={{
        ...state,
        remove,
        search,
        create,
        update,
        createAutomationFromTemplate,
        createTemplateFromAutomation,
        getQueryParameters,
        getAllShopOptions,
        fetchTemplate,
      }}
    >
      {children}
    </AutomationTemplatesContext.Provider>
  );
};

AutomationTemplatesProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

AutomationTemplatesProvider.defaultProps = {
  initialState: defaultInitialState,
};
