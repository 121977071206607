import { APP_LAYOUT_CSS_SELECTORS } from 'constants/constants';
import styled, { css } from 'styled-components';
import MediaQueries from 'utils/mediaQueries';

type IsExperimentalViewportHeight = Record<
  '$isExperimentalViewportHeight',
  boolean
>;

export const StyledAppContainer = styled.div<IsExperimentalViewportHeight>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${MediaQueries.desktopOnly} {
    &${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR}${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} {
      overflow: hidden;
      height: 100vh;
    }

    ${({ $isExperimentalViewportHeight }) =>
      $isExperimentalViewportHeight &&
      css`
        height: 100vh;
      `}
  }

  ${MediaQueries.tabletAndPhone} {
    &${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} {
      overflow: hidden;
      height: 100vh;
    }
  }
`;

export const StyledSidebarAndMain = styled.div<IsExperimentalViewportHeight>`
  --nav-menu-transition-speed: 500ms;
  --nav-menu-inner-transition-speed: 150ms;
  --nav-menu-width: 288px;

  align-items: stretch;
  display: flex;
  flex: 1 0 auto;
  margin: 0 auto;
  max-width: 1632px;
  min-height: 100vh;
  padding: var(--spacing-6) var(--spacing-6) 0;
  position: relative;
  transition: padding var(--nav-menu-transition-speed),
    transform var(--nav-menu-transition-speed);
  width: 100%;
  z-index: 1;

  ${MediaQueries.desktopOnly} {
    ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      max-width: 100%;
      padding-top: var(--spacing-2);
    }

    ${({ $isExperimentalViewportHeight }) =>
      $isExperimentalViewportHeight &&
      css`
        height: 100%;
        min-height: unset;
      `}
  }

  ${MediaQueries.tabletOnly} {
    padding: var(--spacing-4) var(--spacing-5) 0;

    ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      padding-top: var(--spacing-2);
    }

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} & {
      transform: translateX(calc(var(--nav-menu-width) + var(--spacing-4)));
    }
  }

  ${MediaQueries.phoneOnly} {
    padding: 0;

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} & {
      transform: translateX(var(--nav-menu-width));
    }
  }
`;

export const StyledMain = styled.main<IsExperimentalViewportHeight>`
  display: flex;
  flex: 1 0 calc(100% - var(--nav-menu-width));
  padding: 0 var(--spacing-9) var(--spacing-6) var(--spacing-3);
  padding-right: var(
    --spacing-3
  ); /* remove when new modular layouts are implemented */
  flex-direction: column;
  transition: opacity var(--nav-menu-transition-speed),
    padding var(--nav-menu-transition-speed),
    max-width var(--nav-menu-transition-speed);

  ${MediaQueries.desktopOnly} {
    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      padding-left: var(--spacing-7);
    }

    ${StyledAppContainer}:not(${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR}) & {
      max-width: calc(100% - var(--nav-menu-width));
      padding-top: var(--spacing-3);
    }

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR}:not(${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN}) & {
      max-width: 100%;
      padding-right: var(--spacing-3);
    }

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR}${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} & {
      max-width: calc(100% - var(--nav-menu-width));
      opacity: 0.5;

      * {
        pointer-events: none !important;
      }
    }

    ${({ $isExperimentalViewportHeight }) =>
      $isExperimentalViewportHeight &&
      css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
  }

  ${MediaQueries.tabletAndPhone} {
    flex: 1 0 100%;
    padding: 0 var(--spacing-3) var(--spacing-6);
    width: 100%;

    ${StyledAppContainer}:not(${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR}) & {
      padding-top: var(--spacing-5);
    }

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN} & {
      opacity: 0.5;

      * {
        pointer-events: none !important;
      }
    }
  }

  ${MediaQueries.tabletOnly} {
    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      padding-left: var(--spacing-7);
    }
  }

  ${MediaQueries.phoneOnly} {
    padding: var(--spacing-9) var(--spacing-4) var(--spacing-8);

    ${StyledAppContainer}${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      padding-left: 45px;
    }
  }
`;

export const StyledContent = styled.div<IsExperimentalViewportHeight>`
  height: 100%;
  width: 100%;

  ${({ $isExperimentalViewportHeight }) =>
    $isExperimentalViewportHeight &&
    css`
      display: flex;
      flex-direction: column;
      flex: 1 1 auto;
      overflow: hidden;
    `}
`;
