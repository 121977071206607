import Helpers from 'controllers/helpers';
import { api } from 'controllers/network/apiClient';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useInfiniteQuery, useQuery, useQueryClient } from 'react-query';
import { useHistory, useParams } from 'react-router-dom';
import { SegmentSubscriber } from '../types';
import { ResponsesAPI } from '../withResponses';
import {
  GetResponsesParams,
  listResponses,
  RESPONSES_CACHE_KEY_PREFIX,
  useResponses,
} from './useResponses';
import useSegments, {
  listSubscribersForSegment,
  ListSubscribersForSegmentResponse,
  SEGMENTS_QUERY_KEY,
} from './useSegments';
import { SUBSCRIBER_QUERY_KEY } from './useSubscriber';
import { getChannel } from './useSubscriberChannel';
import useWebSocket, { WebSocketMessageType } from './useWebSocket';

export type ChatQueueType =
  | 'unresolved'
  | 'all'
  | 'filtered'
  | 'segment'
  | null;

export interface Chat {
  subscriberId: number;
  displayName: string;
  lastMessage: string | null;
  receivedTime: string;
  lastMessageTime: string | null;
  incomingMessageId?: number;
  initialViewers: any[];
  href: string;
}

export interface ChatQueue {
  id: number;
  type: ChatQueueType;
  name: string;
  count: number;
  isUnread: boolean;
}

interface UseChatQueue {
  setQueue: (type: ChatQueueType, id?: number) => void;
  queueType: ChatQueueType;
  segmentQueues: ChatQueue[];
  responseQueues: ChatQueue[];
  chats: Chat[];
  nextChat: Chat | null;
  queue: ChatQueue | null;
  subscriberId: number | null;
  filters: GetResponsesParams | null;
  setFilters: (filters: GetResponsesParams | null) => void;
  isQueueLoading: boolean;
  isChatsLoading: boolean;
  isRefreshing: boolean;
  refresh: () => void;
  selectedChat: Chat | null;
  isSegmentsLoading: boolean;
  loadMoreChats: (start: number, end: number) => void;
  totalChats: number;
}

const ChatQueueContext = createContext<UseChatQueue>({
  setQueue: () => {
    throw new Error('<ChatQueueContext /> not initialized');
  },
  queueType: null,
  responseQueues: [],
  segmentQueues: [],
  chats: [],
  nextChat: null,
  queue: null,
  subscriberId: null,
  filters: null,
  setFilters: () => {
    throw new Error('<ChatQueueContext /> not initialized');
  },
  isQueueLoading: false,
  isChatsLoading: false,
  isRefreshing: false,
  refresh: () => {
    throw new Error('<ChatQueueContext /> not initialized');
  },
  selectedChat: null,
  isSegmentsLoading: false,
  loadMoreChats: () => {
    throw new Error('<ChatQueueContext /> not initialized');
  },
  totalChats: 0,
});

export const useChatQueue = (): UseChatQueue => {
  const context = React.useContext(ChatQueueContext);
  if (context === undefined) {
    throw new Error('useChatQueue must be used within a ChatQueueProvider');
  }
  return context;
};

const GROUPED_RESPONSES_FILTERS: Record<string, GetResponsesParams> = {
  ALL: {
    includeSubscribers: true,
    includeOptedOutSubscriber: false,
  },
  UNRESOLVED: {
    unresolved: true,
    includeSubscribers: true,
    includeOptedOutSubscriber: false,
  },
};
const WINDOW_PAGE_SIZE = 25;

export const CHAT_QUERY_KEYS = {
  ALL: 'allResponses',
  UNRESOLVED: 'unresolvedResponses',
  FILTERED: 'filteredResponses',
  SEGMENT: 'subscriberForSegment',
};

interface ChatQueueProviderProps {
  children: React.ReactNode;
  defaultQueueType?: ChatQueueType;
  baseUrl?: string;
}

export const ChatQueueProvider = ({
  children,
  defaultQueueType = 'unresolved',
  baseUrl = '/responses',
}: ChatQueueProviderProps) => {
  const { id: subscriberId } = useParams<{ id: string }>();
  const [nextChat, setNextChat] = useState<Chat | null>(null);
  const [queueType, setQueueType] = useState<ChatQueueType>(null);
  const [segmentId, setSegmentId] = useState<number | null>(null);
  const [filters, setFilters] = useState<GetResponsesParams | null>(null);
  const [isRefreshing, setIsRefreshing] = useState(false);
  const [responseQueues, setResponseQueues] = useState<ChatQueue[]>([]);
  const [originalTitle, setOriginalTitle] = useState<string | null>(null);
  const [lastSeenUnresolvedCount, setLastSeenUnresolvedCount] = useState<
    number | null
  >(null);
  const client = useQueryClient();
  const history = useHistory();
  const { data: segments, isLoading: isSegmentsLoading } = useSegments();
  const { lastMessage } = useWebSocket();

  const getChatHref = (subscriberId: number): string => {
    return `${baseUrl}/${subscriberId}`;
  };

  useEffect(() => {
    const invalidate = (key: string): void => {
      client.cancelQueries([key]);
      client.invalidateQueries([key]);
    };
    if (!lastMessage?.type) {
      return;
    }
    const invalidateUnresolvedOn: WebSocketMessageType[] = [
      'new_inbound_message',
      'resolved_subscriber_chat',
      'unresolved_subscriber_chat',
    ];

    if (invalidateUnresolvedOn.includes(lastMessage.type)) {
      invalidate(CHAT_QUERY_KEYS.UNRESOLVED);
      const lastMessageSubscriberId = lastMessage.subscriber_id;
      if (lastMessageSubscriberId === +subscriberId) {
        invalidate(SUBSCRIBER_QUERY_KEY);
      }
    }
  }, [lastMessage]);

  const {
    data: subscribersForSegment,
    isLoading: isSubscribersForSegmentLoading,
    fetchNextPage: fetchNextSubscribersForSegment,
  } = useInfiniteQuery<ListSubscribersForSegmentResponse>({
    queryKey: [CHAT_QUERY_KEYS.SEGMENT, segmentId],
    queryFn: ({ pageParam }) => {
      return listSubscribersForSegment(segmentId, {
        page: pageParam?.page,
        pageLimit: WINDOW_PAGE_SIZE,
      });
    },
    getNextPageParam: (lastPage) => ({ page: +(lastPage.page ?? 0) + 1 }),
    enabled: !!segmentId,
  });

  const {
    data: allResponses,
    isLoading: isAllResponsesLoading,
    fetchNextPage: fetchNextAllResponses,
  } = useInfiniteQuery<ResponsesAPI>({
    queryKey: [CHAT_QUERY_KEYS.ALL],
    queryFn: ({ pageParam }) =>
      listResponses({
        ...GROUPED_RESPONSES_FILTERS.ALL,
        limit: WINDOW_PAGE_SIZE,
        start: pageParam?.start ?? 0,
      }),
    getNextPageParam: (lastPage) => ({
      start: +(lastPage?.start ?? 0) + WINDOW_PAGE_SIZE,
    }),
  });

  const {
    data: unresolveResponses,
    isLoading: isUnresolvedResponsesLoading,
    isFetching: isUnresolvedResponsesFetching,
    fetchNextPage: fetchNextUnresolvedResponses,
  } = useInfiniteQuery<ResponsesAPI>({
    queryKey: [CHAT_QUERY_KEYS.UNRESOLVED],
    queryFn: ({ pageParam }) => {
      return listResponses({
        ...GROUPED_RESPONSES_FILTERS.UNRESOLVED,
        limit: WINDOW_PAGE_SIZE,
        start: pageParam?.start ?? 0,
      });
    },
    getNextPageParam: (lastPage) => ({
      start: +(lastPage?.start ?? 0) + WINDOW_PAGE_SIZE,
    }),
    onSuccess: (data) => {
      if (queueType === 'unresolved' || !lastSeenUnresolvedCount) {
        setLastSeenUnresolvedCount(data?.pages[0].totalResults ?? null);
      }
    },
  });

  const { data: filteredResponses, isLoading: isFilteredResponsesLoading } =
    useResponses(
      {
        ...GROUPED_RESPONSES_FILTERS.ALL,
        ...filters,
      },
      [
        RESPONSES_CACHE_KEY_PREFIX,
        queueType ?? 'not-selected',
        JSON.stringify(filters),
      ],
      {
        enabled: queueType === 'filtered',
      },
    );

  const {
    data: initialChannelViewers,
    isLoading: isInitialChannelViewersLoading,
  } = useQuery(['channelViewers'], () => {
    return api.get('/v2/websockets/shop/channels');
  });

  useEffect(() => {
    setOriginalTitle(document.title);
    return () => {
      document.title = originalTitle ?? 'Postscript';
    };
  }, []);

  // Set response queues and update document title
  useEffect(() => {
    const getIsUnread = (type: ChatQueueType): boolean => {
      if (
        type !== 'unresolved' ||
        isUnresolvedResponsesLoading ||
        !unresolveResponses?.pages[0].data ||
        !lastSeenUnresolvedCount
      ) {
        return false;
      }
      return (
        lastSeenUnresolvedCount !== unresolveResponses.pages[0].totalResults
      );
    };

    const queues: Record<string, ChatQueue> = {
      unresolved: {
        id: 1,
        type: 'unresolved',
        name: 'Unresolved',
        count: unresolveResponses?.pages[0].totalResults ?? 0,
        isUnread: getIsUnread('unresolved'),
      },
      all: {
        id: 2,
        type: 'all',
        name: 'All',
        count: allResponses?.pages[0].totalResults ?? 0,
        isUnread: false,
      },
      filtered: {
        id: 3,
        type: 'filtered',
        name: 'Filtered',
        count: filteredResponses?.totalResults ?? 0,
        isUnread: false,
      },
    };

    if (filters && queueType === 'filtered') {
      setResponseQueues([queues.filtered, queues.unresolved, queues.all]);
      return;
    }

    setResponseQueues([queues.unresolved, queues.all]);

    // Update page title with unread count
    const unreadCount = queues.unresolved.count;

    // if title include unread count, remove it. Slice to avoid mutating original title
    const titleWithoutUnreadCount = (originalTitle ?? document.title)
      .slice()
      .replace(/\(\d+\)/, '');
    // Keep exiting title if no unread count, otherwise add unread count to title
    document.title = unreadCount
      ? `(${unreadCount}) ${titleWithoutUnreadCount}`
      : titleWithoutUnreadCount;
  }, [
    filters,
    queueType,
    allResponses,
    filteredResponses,
    unresolveResponses,
    isUnresolvedResponsesLoading,
    isAllResponsesLoading,
    isFilteredResponsesLoading,
    lastSeenUnresolvedCount,
    originalTitle,
  ]);

  const segmentQueues = useMemo((): ChatQueue[] => {
    if (!segments || isSegmentsLoading) return [];
    return segments.map((segment) => ({
      id: segment.segment_id,
      type: 'segment',
      name: segment.name,
      count: segment.total_customers_sendable,
      isUnread: false,
    }));
  }, [segments, isSegmentsLoading, queueType]);

  const queue = useMemo((): ChatQueue | null => {
    if (queueType === 'segment' && segmentId) {
      return segmentQueues.find((queue) => queue.id === segmentId) || null;
    }
    return responseQueues.find((queue) => queue.type === queueType) || null;
  }, [queueType, segmentId, segmentQueues, responseQueues]);

  const chats = useMemo((): Chat[] => {
    const isSegmentEmptyOrLoading =
      !subscribersForSegment || isSubscribersForSegmentLoading;
    const isAllResponsesEmptyOrLoading = !allResponses || isAllResponsesLoading;
    const isUnresolvedResponsesEmptyOrLoading =
      !unresolveResponses || isUnresolvedResponsesLoading;
    const isResponsesEmptyOrLoading =
      queueType === 'all'
        ? isAllResponsesEmptyOrLoading
        : isUnresolvedResponsesEmptyOrLoading;
    const isFilteredResponsesEmptyOrLoading =
      !filteredResponses?.data || isFilteredResponsesLoading;

    const getDisplayNameForSubscriber = (subscriber: SegmentSubscriber) => {
      return subscriber.name || subscriber.subscriber_id.toString();
    };

    const getDisplayNameForResponse = (response: any) => {
      return response?.customer && response.customer !== 'Subscriber'
        ? response?.customer
        : response.subscriber_id;
    };

    const getInitialViewers = (subscriberId: number) => {
      if (isInitialChannelViewersLoading) return [];
      const activeChannel = initialChannelViewers?.active_channels.find(
        (channel: any) => {
          return channel.channel_name === getChannel(subscriberId);
        },
      );
      if (!activeChannel) return [];
      return activeChannel.connections.map((connection: any) => ({
        username: connection.username,
        connection_id: connection.connection_id,
      }));
    };

    if (queueType === 'segment' && !isSegmentEmptyOrLoading) {
      return subscribersForSegment.pages
        .map((i) => i.data.subscribers)
        .flat()
        .map((subscriber: SegmentSubscriber) => ({
          subscriberId: subscriber.subscriber_id,
          displayName: getDisplayNameForSubscriber(subscriber),
          lastMessage: subscriber.last_message_text,
          receivedTime: subscriber.last_message_received_at,
          lastMessageTime: Helpers.formatTimeSince(
            subscriber?.last_message_received_at * 1000,
          ),
          initialViewers: getInitialViewers(subscriber.subscriber_id),
          href: getChatHref(subscriber.subscriber_id),
        }));
    }

    if (
      (queueType === 'unresolved' || queueType === 'all') &&
      !isResponsesEmptyOrLoading
    ) {
      const responses =
        queueType === 'unresolved' ? unresolveResponses : allResponses;

      if (!responses) return [];

      const chatQueue = responses.pages
        .map((page) => page.data)
        .flat()
        .map(
          (response): Chat => ({
            subscriberId: response.subscriber_id,
            displayName: getDisplayNameForResponse(response),
            lastMessage: response.response,
            receivedTime: response.received_time,
            lastMessageTime: Helpers.formatTimeSince(response.received_time),
            incomingMessageId: response.incoming_message_id,
            initialViewers: getInitialViewers(response.subscriber_id),
            href: getChatHref(response.subscriber_id),
          }),
        );

      if (queueType === 'unresolved') {
        chatQueue.sort(
          (a: Chat, b: Chat) =>
            new Date(a.receivedTime).getTime() -
            new Date(b.receivedTime).getTime(),
        );
      }

      return chatQueue;
    }

    if (queueType === 'filtered' && !isFilteredResponsesEmptyOrLoading) {
      return filteredResponses.data.map(
        (response): Chat => ({
          subscriberId: response.subscriber_id,
          displayName: getDisplayNameForResponse(response),
          lastMessage: response.response,
          receivedTime: response.received_time,
          lastMessageTime: Helpers.formatTimeSince(response.received_time),
          incomingMessageId: response.incoming_message_id,
          initialViewers: getInitialViewers(response.subscriber_id),
          href: getChatHref(response.subscriber_id),
        }),
      );
    }

    return [];
  }, [
    queueType,
    subscribersForSegment,
    isSubscribersForSegmentLoading,
    allResponses,
    isAllResponsesLoading,
    unresolveResponses,
    isUnresolvedResponsesLoading,
    filteredResponses,
    isFilteredResponsesLoading,
    initialChannelViewers,
    isInitialChannelViewersLoading,
  ]);

  useEffect(() => {
    if (
      isSubscribersForSegmentLoading ||
      isUnresolvedResponsesLoading ||
      isAllResponsesLoading ||
      isFilteredResponsesLoading ||
      isUnresolvedResponsesFetching
    ) {
      return;
    }

    // Auto-select first chat in queue if no chat is selected
    if (!subscriberId) {
      const firstChat = chats[0];
      if (firstChat) {
        history.replace(firstChat.href);
      }
    }
    // Find the next chat to open in the queue after the current chat is closed,
    // looping back to the beginning if necessary
    const currentChatIndex = chats.findIndex(
      (chat) => chat.subscriberId === +subscriberId,
    );
    const nextChatIndex =
      currentChatIndex === chats.length - 1 ? 0 : currentChatIndex + 1;
    const nextChat = chats[nextChatIndex];
    if (nextChat && nextChat.subscriberId !== +subscriberId) {
      setNextChat(nextChat);
    } else {
      setNextChat(null);
    }
  }, [
    chats,
    subscriberId,
    isAllResponsesLoading,
    isUnresolvedResponsesLoading,
    isFilteredResponsesLoading,
    isSubscribersForSegmentLoading,
    isUnresolvedResponsesFetching,
  ]);

  useEffect(() => {
    // check in subsceriberId is not in chats
    if (!subscriberId && !queueType) {
      setQueueType(defaultQueueType);
    }
  }, [subscriberId, queueType]);

  const totalChats = useMemo(() => {
    if (queueType === 'segment') {
      return subscribersForSegment?.pages[0].data.count || 0;
    }
    if (queueType === 'unresolved') {
      return unresolveResponses?.pages[0].totalResults || 0;
    }
    if (queueType === 'all') {
      return allResponses?.pages[0].totalResults || 0;
    }
    if (queueType === 'filtered') {
      return filteredResponses?.totalResults || 0;
    }
    return 0;
  }, [
    queueType,
    subscribersForSegment,
    unresolveResponses,
    allResponses,
    filteredResponses,
  ]);

  const setQueue = (type: ChatQueueType, id?: number) => {
    if (type === 'segment') {
      if (!id) {
        throw new Error('Segment ID is required for segment queue type');
      }
      setSegmentId(id);
      setQueueType(type);
    } else {
      setSegmentId(null);
      setQueueType(type);
    }
    // invalidate queries
    if (type === 'unresolved') {
      client.invalidateQueries([CHAT_QUERY_KEYS.UNRESOLVED]);
    } else if (type === 'all') {
      client.invalidateQueries([CHAT_QUERY_KEYS.ALL]);
    } else if (type === 'segment') {
      client.invalidateQueries([CHAT_QUERY_KEYS.SEGMENT]);
    } else if (type === 'filtered') {
      client.invalidateQueries([CHAT_QUERY_KEYS.FILTERED]);
    }
  };

  const handleSetFilters = (f: GetResponsesParams | null) => {
    if (f === null || !Object.values(f)) {
      setQueueType(defaultQueueType);
    } else {
      setQueueType('filtered');
    }
    history.push(baseUrl);
    setFilters(f);
  };

  const refresh = () => {
    setIsRefreshing(true);
    client.invalidateQueries([SEGMENTS_QUERY_KEY]);
    client.invalidateQueries([SUBSCRIBER_QUERY_KEY]);
    client.invalidateQueries([CHAT_QUERY_KEYS.UNRESOLVED]);
    client.invalidateQueries([CHAT_QUERY_KEYS.ALL]);
    client.invalidateQueries([CHAT_QUERY_KEYS.FILTERED]);
    client.invalidateQueries([CHAT_QUERY_KEYS.SEGMENT]);
    setTimeout(() => {
      setIsRefreshing(false);
    }, 250);
  };

  const loadMoreChats: UseChatQueue['loadMoreChats'] = () => {
    if (queueType === 'all') {
      fetchNextAllResponses();
    } else if (queueType === 'unresolved') {
      fetchNextUnresolvedResponses();
    } else if (queueType === 'segment') {
      fetchNextSubscribersForSegment();
    }
  };

  return (
    <ChatQueueContext.Provider
      value={{
        segmentQueues,
        responseQueues,
        chats,
        nextChat,
        setQueue,
        queueType,
        queue,
        subscriberId: +subscriberId || null,
        filters,
        isRefreshing,
        refresh,
        setFilters: handleSetFilters,
        isSegmentsLoading,
        isQueueLoading:
          isSegmentsLoading ||
          isUnresolvedResponsesLoading ||
          isAllResponsesLoading,
        isChatsLoading:
          isUnresolvedResponsesLoading ||
          isAllResponsesLoading ||
          isSubscribersForSegmentLoading ||
          isFilteredResponsesLoading,
        selectedChat:
          chats.find((chat) => chat.subscriberId === +subscriberId) ?? null,
        loadMoreChats,
        totalChats,
      }}
    >
      {children}
    </ChatQueueContext.Provider>
  );
};

export default useChatQueue;
