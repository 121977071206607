import { Badge, IconSet, Tooltip } from '@postscript/components';
import React, { ComponentType } from 'react';
import styled from 'styled-components';
import { useShopperStatus } from '../../../hooks/useShopperStatus';

const StyledBadge = styled(Badge)`
  margin-left: var(--spacing-2);
`;

const StyledAnchor = styled.a`
  text-decoration: none !important;
  font-weight: 400 !important;
  color: black;

  &:hover {
    color: black;
  }
`;

type InjectedDaysLeftProps = {
  daysLeft: number;
  withCancellationGuidance: boolean;
};

export const withShopperTrialDays = (
  WrappedComponent: ComponentType<InjectedDaysLeftProps>,
) => {
  const wrapped: React.FC<{
    withCancellationGuidance: boolean;
  }> = ({ withCancellationGuidance }) => {
    const { trialDaysRemaining } = useShopperStatus();

    if (trialDaysRemaining === 0) {
      return null;
    }

    return (
      <WrappedComponent
        daysLeft={trialDaysRemaining}
        withCancellationGuidance={withCancellationGuidance}
      />
    );
  };

  return wrapped;
};

type ShopperStatusTooltipContentProps = {
  daysLeft: number;
  withCancellationGuidance: boolean;
};

const ShopperStatusTooltipContent = ({
  daysLeft,
  withCancellationGuidance,
}: ShopperStatusTooltipContentProps) => (
  <>
    <>
      There are {String(daysLeft).trim()} {daysLeft === 1 ? 'day' : 'days'} left
      in your <br />
      Shopper trial before you will be <br />
      charged for a paid plan.
    </>{' '}
    {withCancellationGuidance ? (
      <>
        Click to <br />
        manage your plan in your <br />
        billing settings.
      </>
    ) : (
      ''
    )}
  </>
);

type ShopperStatusBadgeProps = {
  daysLeft: number;
  withCancellationGuidance: boolean;
};

export const ShopperStatusBadge = ({
  daysLeft,
  withCancellationGuidance,
}: ShopperStatusBadgeProps) => {
  /* TODO: Additional render state here for when a trial + plan has expried. */
  /* return <StyledBadge>Not included in plan.</StyledBadge> */

  const tooltipId = 'shopper-status-details';

  const badgeLabelContent = `${String(daysLeft).trim()} ${
    daysLeft === 1 ? 'Day' : 'Days'
  } left of trial`;

  return (
    <>
      <Tooltip id={tooltipId} place="right" size="small">
        <ShopperStatusTooltipContent
          daysLeft={daysLeft}
          withCancellationGuidance={withCancellationGuidance}
        />
      </Tooltip>

      <StyledBadge
        size="medium"
        icon={IconSet.Information}
        rounded={false}
        variant="neutral"
        data-tip
        data-for={tooltipId}
        data-testid="shopper-status-badge"
      >
        <StyledAnchor href="/billing/plans">{badgeLabelContent}</StyledAnchor>
      </StyledBadge>
    </>
  );
};

export const ShopperTrialStatusTooltipContent = withShopperTrialDays(
  ShopperStatusTooltipContent,
);

export const ShopperStatusBadgeWithTrialDays =
  withShopperTrialDays(ShopperStatusBadge);
