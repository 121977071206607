import { Badge, BodyText, IconSet } from '@postscript/components';
import styled from 'styled-components';

import { useGetInvoicingSettings } from 'components/billing/context/useBilling';

import { FeeDisclosureBanner } from 'components/billing/modules/payments/PaymentMethods/FeeDisclosureBanner';
import GenericBankAccount from 'img/bank-account.svg?react';
import AmericanExpressLogo from 'img/card-brands/american-express.svg?react';
import DiscoverLogo from 'img/card-brands/discover.svg?react';
import GenericCard from 'img/card-brands/generic.svg?react';
import MastercardLogo from 'img/card-brands/mastercard.svg?react';
import VisaLogo from 'img/card-brands/visa.svg?react';

import { StripePaymentMethod } from 'components/billing/common/types';

import {
  Badges,
  Stack,
} from 'components/billing/modules/payments/PaymentMethods/styles';

const brands = {
  amex: {
    name: 'American Express',
    logo: AmericanExpressLogo,
  },
  visa: {
    name: 'Visa',
    logo: VisaLogo,
  },
  mastercard: {
    name: 'Mastercard',
    logo: MastercardLogo,
  },
  discover: {
    name: 'Discover',
    logo: DiscoverLogo,
  },
  generic: {
    name: 'Debit/Credit Card',
    logo: GenericCard,
  },
};

type KnownBrands = keyof typeof brands;

const PaymentMethodCard = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  align-self: stretch;

  padding: var(--spacing-2);
  border-radius: var(--border-radius-medium);
  background: var(--gray-1);
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2);
  flex: 1 0 0;
`;

const PaymentMethodHeading = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-2);
  align-self: stretch;
`;

const PaymentDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

interface Props {
  paymentMethod: StripePaymentMethod & {
    isDefault: boolean;
    isVerified: boolean;
  };
  /** React component to render on the right-side */
  actions?: React.ReactNode;
}

export const PaymentMethodDetails = ({ paymentMethod, actions }: Props) => {
  const { card, usBankAccount, isDefault, isVerified } = paymentMethod;
  const { data: invoicingSettings } = useGetInvoicingSettings();

  const getPaymentMethodFields = () => {
    if (card) {
      const { logo: CardBrandLogo, name: cardBrandName } =
        brands[card.brand as KnownBrands] || brands.generic;

      return {
        CardIcon: CardBrandLogo,
        header: `${cardBrandName} ••••${card.last4}`,
        subheader: `Expires ${String(card.expMonth).padStart(2, '0')}/${
          card.expYear
        }`,
        tags: invoicingSettings?.shouldApplyCreditFee && [
          <Badge variant="error" size="small" key="fee">
            +2.9% Fee
          </Badge>,
        ],
      };
    }

    return {
      CardIcon: GenericBankAccount,
      header: usBankAccount?.bankName,
      subheader: `•••• ${usBankAccount?.last4}`,
      tags: isVerified && [
        // Verified bank accounts
        // TODO: Are unverified bank accounts ever rendered?
        <Badge
          variant="neutral"
          size="small"
          icon={IconSet.CheckCircle}
          style={{ background: 'transparent' }}
          key="verified"
        >
          Verified
        </Badge>,
      ],
    };
  };

  const { CardIcon, header, subheader, tags } = getPaymentMethodFields();
  const shouldDiscloseFee =
    card && isDefault && invoicingSettings?.shouldApplyCreditFee;

  return (
    <Stack gap="var(--spacing-2)">
      <PaymentMethodCard>
        <Content>
          <PaymentMethodHeading>
            <CardIcon height={24} width={35} />

            <PaymentDetails>
              <BodyText size="small" color="var(--text-color)">
                {header}
              </BodyText>

              <BodyText size="x-small" color="var(--text-color-dim)">
                {subheader}
              </BodyText>
            </PaymentDetails>
          </PaymentMethodHeading>

          <Badges>
            <Badge variant={isDefault ? 'success' : 'neutral'} size="small">
              {isDefault ? 'Active' : 'Inactive'}
            </Badge>

            {tags}
          </Badges>
        </Content>

        {!isDefault && actions}
      </PaymentMethodCard>

      {shouldDiscloseFee && <FeeDisclosureBanner />}
    </Stack>
  );
};
