import { Input, SelectMenu } from '@postscript/components';
import { Option } from '@postscript/components/dist/esm/components/form/SelectMenu/types';
import { POPUP_PROGRAMMATIC_TRIGGER } from 'components/admin/utils/feature-flags';
import FormikField from 'components/billing/common/FormikField';
import { nameofFactory } from 'components/flowBuilder/types/util';
import { POPUP_TYPES, TRIGGER_TYPES } from 'components/Popup/constants';
import {
  DESKTOP_POPUP_TRIGGER_OPTIONS,
  MOBILE_POPUP_TRIGGER_OPTIONS,
} from 'components/Popup/constants/PopupSelectMenuOptions';
import { PopupFormValues, PopupTypes } from 'components/Popup/types';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { useFormikContext } from 'formik';
import { CustomTriggerHelpCard } from './CustomTriggerHelpCard';

const nameof = nameofFactory<PopupFormValues>();

const getTriggerOptions = (
  popupType: PopupTypes,
  hasProgrammaticTriggersEnabled: boolean,
): Option[] => {
  const options =
    popupType === POPUP_TYPES.DESKTOP
      ? DESKTOP_POPUP_TRIGGER_OPTIONS
      : MOBILE_POPUP_TRIGGER_OPTIONS;

  return hasProgrammaticTriggersEnabled
    ? options
    : options.filter((option) => option.value !== TRIGGER_TYPES.CUSTOM);
};

const TriggerSettings = (): JSX.Element => {
  const { values, setFieldValue } = useFormikContext<PopupFormValues>();
  const { hasLabsFlag } = usePSLabs();
  const { id, popupType, trigger } = values;

  const triggerOptions = getTriggerOptions(
    popupType as PopupTypes,
    hasLabsFlag(POPUP_PROGRAMMATIC_TRIGGER),
  );

  return (
    <>
      {triggerOptions.length > 1 && (
        <FormikField
          name={nameof('trigger')}
          label="Popup trigger"
          as={SelectMenu}
          options={triggerOptions}
          value={triggerOptions.find((o) => o.value === values.trigger)}
          onChange={({ value }: { value: string }) => {
            setFieldValue(`trigger`, value);
          }}
          isClearable={false}
        />
      )}
      {trigger === TRIGGER_TYPES.CUSTOM && (
        <CustomTriggerHelpCard id={id} popupType="legacy" />
      )}
      {trigger === TRIGGER_TYPES.DELAY && (
        <FormikField
          name={nameof('delay')}
          label="Popup delay"
          type="number"
          as={Input}
          suffix="seconds"
        />
      )}
    </>
  );
};

export default TriggerSettings;
