import { Badge, BodyText, Icon, IconSet } from '@postscript/components';

import { useGetInvoicingSettings } from 'components/billing/context/useBilling';

import {
  Badges,
  ButtonHeading,
  IconContainer,
  StyledButton,
} from 'components/billing/modules/payments/PaymentMethods/styles';

interface Props {
  onClick: () => void;
}

export const AddAchButton = ({ onClick }: Props) => {
  const { data: invoicingSettings } = useGetInvoicingSettings();

  return (
    <StyledButton onClick={onClick}>
      <ButtonHeading>
        <BodyText size="small">
          <Icon component={IconSet.Bank} size="large" />
          Bank Account (ACH)
        </BodyText>

        <IconContainer>
          <Icon component={IconSet.Plus} size="small" />
        </IconContainer>
      </ButtonHeading>

      <Badges>
        <Badge variant="info" size="small">
          Recommended
        </Badge>

        {invoicingSettings?.shouldApplyCreditFee && (
          <Badge variant="neutral" size="small">
            No Fee
          </Badge>
        )}
      </Badges>
    </StyledButton>
  );
};

export const AddCreditCardButton = ({ onClick }: Props) => {
  const { data: invoicingSettings } = useGetInvoicingSettings();

  return (
    <StyledButton onClick={onClick}>
      <ButtonHeading>
        <BodyText size="small">
          <Icon component={IconSet.CreditCard} size="large" />
          {/* Add zero-width space to ensure this breaks across lines on small screens */}
          Credit/&#8203;Debit Card
        </BodyText>

        <IconContainer>
          <Icon component={IconSet.Plus} size="small" />
        </IconContainer>
      </ButtonHeading>

      {invoicingSettings?.shouldApplyCreditFee && (
        <Badges>
          <Badge variant="error" size="small">
            +2.9% Fee
          </Badge>
        </Badges>
      )}
    </StyledButton>
  );
};
