import { BrowserTracing, init, Replay } from '@sentry/browser';
import { SENTRY_RELEASE, SENTRY_SAMPLE_RATE } from 'utils/envVars';

type GoAway = {
  filter: string;
  strictMatch?: boolean;
};

const AMPLITUDE_ERRORS: GoAway[] = [
  { filter: 'amplitude is not defined' },
  { filter: "Can't find variable: amplitude" },
  { filter: 'window.amplitude' },
  {
    filter: "Cannot read properties of undefined (reading 'add')",
    strictMatch: true,
  },
];

const PENDO_ERRORS: GoAway[] = [
  { filter: 'pendo is not defined' },
  { filter: "Can't find variable: pendo" },
];

const FILTERED_ERRORS: GoAway[] = [
  { filter: "Unexpected token '<'" },
  { filter: "Can't find variable: _hsq" },
  { filter: '_hsq is not defined' },
  { filter: 'Please load Stripe' },
  { filter: 'window.zE is not a function' },
  ...PENDO_ERRORS,
  ...AMPLITUDE_ERRORS,
];

// Be careful adding to this list, we should only allow urls that do not contain PII
const REPLAY_PATHS = [
  '/automations',
  '/keywords',
  '/shopify_triggers',
  '/tags',
  '/v2/shops/labs/features',
  /\/v2\/billing\/admin\/.*/,
];

export const initSentry = (environment: 'production' | 'development') => {
  const replay = new Replay({ networkDetailAllowUrls: REPLAY_PATHS });

  init({
    dsn: 'https://63a92b5c13944fcab35b6f19347b4c97@o142330.ingest.sentry.io/1251963',
    release: SENTRY_RELEASE,
    integrations: [new BrowserTracing(), replay],
    environment,
    allowUrls: [/https:\/\/(dev|app)\.postscript\.io/],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      'ResizeObserver loop completed with undelivered notifications',
    ],
    tracesSampleRate: parseFloat(SENTRY_SAMPLE_RATE),
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: environment === 'production' ? 1 : 0,
    beforeSend(event, { originalException }) {
      const message =
        originalException instanceof Error ? originalException.message : null;

      if (!message) return event;

      const isFiltered = FILTERED_ERRORS.some(({ filter, strictMatch }) => {
        return strictMatch ? filter === message : message.includes(filter);
      });

      return isFiltered ? null : event;
    },
  });

  return {
    replay,
  };
};
