/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Icon, IconSet } from '@postscript/components';
import parseMessageForHtml from 'components/responses/utils/parseMessageForHtml';
import parseMessagesForPreview from 'components/responses/utils/parseMessageForPreview';
import { isVcardFileUrl } from 'components/vcards/VcardFileUrl';
import VcardPhoneMessage from 'components/vcards/VcardPhoneMessage';
import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import { MESSAGE_SUBTYPES } from '../constants';
import { useView } from '../hooks/useView';
import {
  MediaFlexWrapper,
  MediaThreadPreview,
  MessageFlexWrapper,
  StyledAvatar,
  StyledBodyText,
  StyledMessageBubble,
} from '../styles/message';
import { Message } from '../types';
import { getUserAvatar } from '../utils/roles';
import MessageMetadata from './MessageMetadata';

interface MessageMediaProps {
  message: Message;
  shopName?: string;
  onLightboxOpen: () => void;
  incoming: boolean;
}

const shouldObscureOutboundAvatar = () => {
  const storageKey = localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY);
  return !storageKey || storageKey === '' || storageKey === 'Merchant View';
};

const parseMessageText = (message: string) =>
  parseMessageForHtml(parseMessagesForPreview(message));

interface MessageProps {
  message: Message;
  shopName?: string;
  id: string;
  showTimestamp?: boolean;
  truncate?: boolean;
  forceObscureAvatar?: boolean;
}

interface MessageAvatarProps {
  message: Message;
  currentView: string;
  shopName?: string;
  id: string;
  forceObscureAvatar?: boolean;
}

export const getIconAvatarConfig = (
  message: Message,
  forceObscureAvatar?: boolean,
) => {
  if (
    message.direction === 'outgoing' &&
    message.subtype === MESSAGE_SUBTYPES.SHOPPER
  ) {
    return {
      messageType: message.type,
      incoming: false,
      icon: IconSet.Sparkles,
      iconColor: 'var(--color-message-text-outbound)',
    };
  }

  if (message.direction === 'incoming') {
    return {
      messageType: message.type,
      incoming: true,
      icon: IconSet.User,
      iconColor: 'var(--blue-5)',
    };
  }

  if (shouldObscureOutboundAvatar() || forceObscureAvatar) {
    return {
      messageType: 'system',
      incoming: false,
      icon: IconSet.Bub,
      iconColor: 'var(--purple-core)',
    };
  }
};

const MessageAvatar = ({
  message,
  currentView,
  shopName,
  id,
  forceObscureAvatar,
}: MessageAvatarProps) => {
  const iconAvatarConfig = getIconAvatarConfig(message, forceObscureAvatar);

  if (iconAvatarConfig) {
    return (
      <StyledAvatar
        messageType={iconAvatarConfig.messageType}
        incoming={iconAvatarConfig.incoming}
      >
        <Icon
          color={iconAvatarConfig.iconColor}
          component={iconAvatarConfig.icon}
          size={18}
        />
      </StyledAvatar>
    );
  }

  return getUserAvatar({ message, currentView, shopName, id });
};

const MessageMedia = ({
  message,
  shopName,
  onLightboxOpen,
  incoming,
}: MessageMediaProps) => {
  if (!message.media_url) return null;

  return (
    <MediaFlexWrapper incoming={incoming}>
      {isVcardFileUrl(message.media_url) ? (
        <VcardPhoneMessage messageContent={shopName} />
      ) : (
        <MediaThreadPreview
          data-testid="media-thread-preview"
          alt="mms-media"
          src={message.media_url}
          onClick={onLightboxOpen}
          incoming={incoming}
          hasText={!!message.text}
        />
      )}
    </MediaFlexWrapper>
  );
};

const MessageContainer = ({
  message,
  shopName,
  id,
  showTimestamp = true,
  truncate = false,
  forceObscureAvatar = false,
}: MessageProps): JSX.Element => {
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const { view: currentView } = useView();
  const incoming = message.direction === 'incoming';
  return (
    <>
      <div style={{ position: 'relative' }}>
        <MessageMedia
          message={message}
          shopName={shopName}
          onLightboxOpen={() => setIsLightboxOpen(true)}
          incoming={incoming}
        />

        {(!message.media_url || message.text) && (
          <MessageFlexWrapper incoming={incoming}>
            <StyledMessageBubble
              className="message-bubble"
              messageType={message.type}
              incoming={incoming}
            >
              <StyledBodyText
                forwardedAs="span"
                color="currentColor"
                size={
                  message.type?.includes('convert_event') ? 'x-small' : 'small'
                }
                truncate={truncate}
              >
                {parseMessageText(message.text)}
              </StyledBodyText>
            </StyledMessageBubble>
          </MessageFlexWrapper>
        )}

        <MessageAvatar
          message={message}
          currentView={currentView}
          shopName={shopName}
          id={id}
          forceObscureAvatar={forceObscureAvatar}
        />
      </div>

      <MessageMetadata message={message} showTimestamp={showTimestamp} />

      {/* Message Lightbox */}
      {isLightboxOpen && message.media_url ? (
        <Lightbox
          mainSrc={message.media_url}
          onCloseRequest={() => setIsLightboxOpen(false)}
        />
      ) : null}
    </>
  );
};

export default MessageContainer;
