/* eslint-disable camelcase */
import { api } from 'controllers/network/apiClient';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import { ResponsesAPI } from '../withResponses';
import { CLASSIFICATIONS_BASE_URL } from './useClassifications';

export interface Response {
  classification: string | null;
  customer: string;
  incoming_message_id: number;
  opted_out: boolean;
  received_time: string;
  response: string;
  subscriber_id: number;
  total_unresolved_messages: number;
  sentiment?: string | null;
  resolved_at?: string;
  customer_id?: number;
  previous_message_type_id?: string;
  phone_number?: string;
  previous_message_type?: string;
  shop_name?: string;
  shop_id?: number;
  new_prospect?: boolean;
  data: any;
}

export interface GetResponsesParams {
  includeSubscribers: boolean;
  includeOptedOutSubscriber: boolean;
  limit?: number;
  start?: number;
  unresolved?: boolean;
  resolved?: boolean;
  classification?: string;
  previous_message_type_id?: string;
  previous_message_type?: string;
  sentiment?: string[];
  filter_last_30?: boolean;
  assigned?: boolean;
  cross_shop?: boolean;
  include_keywords?: boolean;
}

export const RESPONSES_CACHE_KEY_PREFIX = 'responses';
export const NUMBER_OF_UNRESOLVED_RESPONSES_QUERY_KEY = [
  'numberOfUnresolvedResponses',
];

export const listResponses = async (
  params: GetResponsesParams | null,
): Promise<ResponsesAPI> => {
  const { includeSubscribers, includeOptedOutSubscriber, sentiment, ...rest } =
    params || ({} as GetResponsesParams);
  const queryString: { [key: string]: string | boolean | number } = {
    ...rest,
    include_subscribers: includeSubscribers,
    include_opted_out_subscribers: includeOptedOutSubscriber,
  };

  const urlParams = new URLSearchParams();
  sentiment?.forEach((entry) => urlParams.append('sentiment', entry));
  Object.keys(queryString).forEach((entry) =>
    urlParams.append(entry, String(queryString[entry])),
  );
  const data = await api.get(`/responses/grouped?${urlParams.toString()}`);
  return {
    ...data,
    start: urlParams.get('start') || 0,
    limit: urlParams.get('limit') || 0,
  };
};

export const useResponses = (
  params: GetResponsesParams | null,
  queryKey?: string[],
  options: UseQueryOptions<ResponsesAPI> = {},
): UseQueryResult<ResponsesAPI> => {
  return useQuery<ResponsesAPI>(
    !queryKey ? [RESPONSES_CACHE_KEY_PREFIX, params] : queryKey,
    () => listResponses(params),
    {
      enabled: !!params && Object.keys(params).length > 0,
      staleTime: 1_000 * 60,
      ...options,
    },
  );
};

const deleteClassification = async (messageId: number) =>
  api.delete(`${CLASSIFICATIONS_BASE_URL}/${messageId}`);

export const useRemoveMessageClassification = (): UseMutationResult<
  void,
  any,
  number
> => {
  const queryClient = useQueryClient();

  return useMutation((messageId: number) => deleteClassification(messageId), {
    onSuccess: (_, messageId: number) => {
      queryClient.setQueriesData<ResponsesAPI>(
        [RESPONSES_CACHE_KEY_PREFIX],
        (oldData) => {
          if (!oldData) {
            return { success: true, data: [], totalResults: 0 };
          }

          return {
            ...oldData,
            data: oldData.data.map((response) =>
              response.incoming_message_id !== messageId
                ? response
                : {
                    ...response,
                    classification: null,
                  },
            ),
          };
        },
      );

      queryClient.invalidateQueries([RESPONSES_CACHE_KEY_PREFIX], {
        refetchActive: false,
      });
    },
  });
};
