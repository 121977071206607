import {
  ProductPlanType,
  RecurringFeeTypes,
} from 'components/billing/common/types';
import { useGetCurrentProductPlansByType } from 'components/billing/context/useBilling';
import { isNil } from 'lodash';
import { useProductTrialStatus } from './useProductTrialStatus';

export const useHasProductAddOn = (
  type: ProductPlanType,
  feeType: RecurringFeeTypes,
): boolean => {
  const { data: productPlan, isFetched } =
    useGetCurrentProductPlansByType(type);

  const { onProductFreeTrial } = useProductTrialStatus(feeType);

  if (!isFetched) return false;

  return !isNil(productPlan?.[0]) || onProductFreeTrial;
};
