/* eslint-disable-next-line react/no-array-index-key */
import { Banner, Switch } from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import { useSalesShopData } from 'components/sales/hooks/useShopData';
import { useUser } from 'controllers/contexts/user';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useIdentifyLogRocketUser } from '../../../hooks/useLogRocket';
import {
  Controls,
  HAS_SUMMARY,
  NO_SUMMARY,
} from '../components/ConversationIntelligenceControls';
import { useConversationData } from '../hooks/useConversationIntelligenceData';
import { useConversationIntelligenceQueryParams } from '../hooks/useConversationIntelligenceQueryParams';
import { ConversationWithInsights } from '../hooks/useConversationSummaries';
import useSyncHistoryWithControls from '../hooks/useSyncHistoryWithControls';
import ConversationDrawer from './components/ConversationDrawer';
import { ConversationIntelligenceMetrics } from './components/ConversationIntelligenceMetrics';
import ConversationSummaries from './components/ConversationSummaries';
import DateRangeTabGroup from './components/DateRangeTabGroup';
import InsightsPagination from './components/InsightsPagination';
import NoConversations from './components/NoConversations';
import SearchControls, {
  SearchControlsTitle,
} from './components/SearchControls';
import TrendChart from './components/TrendChart';
import { TrendChartInfoMessages } from './components/TrendChart/TrendChartInfoMessages';
import { MAX_PAGE_SIZE } from './constants';
import * as styles from './styles';
import { ConversationIntelligenceStatus, NavItems } from './types';
import { dateRangeLabel, getIsMultiShop, normalizeFilters } from './utils';

const ConversationIntelligence = (): JSX.Element => {
  const chatDrawerRef = useRef<HTMLDivElement>(null);
  const isMultiShop = getIsMultiShop();
  const user = useUser();
  const currentShopId = getShopId();

  const navItems: NavItems = useMemo(() => {
    const now = moment();
    return {
      today: {
        display: 'Today',
        range: {
          start: now.clone().startOf('day'),
          end: now,
        },
        id: 'today',
      },
      last_7_days: {
        display: 'Last 7 Days',
        range: {
          start: now.clone().subtract(6, 'days').startOf('day'),
          end: now,
        },
        id: 'last_7_days',
      },
      last_30_days: {
        display: 'Last 30 Days',
        range: {
          start: now.clone().subtract(29, 'days').startOf('day'),
          end: now,
        },
        id: 'last_30_days',
      },
    };
  }, []);

  /* We use this ref to prevent infinite cycling of query params
   updating controls and controls updating query params. */
  const isLastUpdateFromControlsRef = useRef(false);

  const queryParams = useConversationIntelligenceQueryParams(
    isMultiShop,
    currentShopId,
  );
  const {
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    topicLabel,
    page,
    maxPageSize,
    filters,
    sortBy,
    dateRangeStart,
    dateRangeEnd,
    clearFilterParams,
  } = queryParams;

  const [controls, setControls] = useState<Controls>({
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    filters: normalizeFilters(filters),
    topicLabel,
    sortBy,
    dateRangeEnd,
    dateRangeStart,
    page,
    maxPageSize,
  });
  useSyncHistoryWithControls({
    controls,
    isLastUpdateFromControlsRef,
  });

  const clearFilters = () => {
    clearFilterParams(); // Clears filters from URL
    setControls((prevControls) => ({
      ...prevControls,
      filters: [],
    }));
  };

  useEffect(() => {
    if (isLastUpdateFromControlsRef.current) {
      isLastUpdateFromControlsRef.current = false;
      return;
    }

    setControls((prevControls) => {
      if (JSON.stringify(prevControls) !== JSON.stringify(queryParams)) {
        return queryParams;
      }
      return prevControls;
    });
  }, [queryParams]);

  const [subscriberId, setSubscriberId] = useState<number | undefined>(
    undefined,
  );

  const numberOfDays =
    moment(controls.dateRangeEnd).diff(
      moment(controls.dateRangeStart),
      'days',
    ) + 1; // inclusive of today.
  const rangeIdMapping: { [key: number]: string } = {
    1: navItems.today.id,
    7: navItems.last_7_days.id,
    30: navItems.last_30_days.id,
  };

  const [selectedDateId, setSelectedDateId] = useState<string | null>(
    rangeIdMapping[numberOfDays] || null,
  );

  useEffect(() => {
    setSelectedDateId(rangeIdMapping[numberOfDays] || null);
  }, [controls.dateRangeStart, controls.dateRangeEnd]);

  const [selectedCardId, setSelectedCardId] = useState<string | null>(null);
  useState(false);
  const { data: salesShopsData } = useSalesShopData();

  const [showNoInsightsBanner, setShowNoInsightsBanner] = useState(() => {
    return localStorage.getItem('insightsBannerDismissed') !== 'true';
  });

  const {
    metrics,
    isConversationsLoading,
    isMetricsLoading,
    isTopicLabelTrendsLoading,
    summaries,
    topicLabelTrends,
    conversationIntelligenceStatus,
    triggerJSONDownload,
  } = useConversationData(controls, isMultiShop, user);
  useIdentifyLogRocketUser('ssovkv/conversation-intelligence');

  const toggleHasSummaryFilter = () => {
    setControls((prevControls) => {
      const updatedFilters = prevControls.filters.includes(HAS_SUMMARY)
        ? prevControls.filters.filter((filter) => filter !== HAS_SUMMARY)
        : [...filters.filter((filter) => filter !== NO_SUMMARY), HAS_SUMMARY];

      return {
        ...prevControls,
        filters: updatedFilters,
      };
    });
  };

  /* ZERO STATE */
  const noConversations =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.NO_CONVERSATIONS;

  /* PARTIAL STATE BANNER */
  const hasInsights =
    conversationIntelligenceStatus ===
    ConversationIntelligenceStatus.PARTIAL_INSIGHTS;

  const handleDateRangeTabGroupClick = (
    formattedFrom: string,
    formattedTo: string,
    selectedId: string,
  ) => {
    setControls((prevControls) => ({
      ...prevControls,
      dateRangeStart: formattedFrom,
      dateRangeEnd: formattedTo,
    }));
    setSelectedDateId(selectedId);
  };

  const handleViewConversationClick = (
    conversation: ConversationWithInsights,
  ) => {
    setSubscriberId(conversation.subscriber?.id);
    setSelectedCardId(conversation.id);
  };

  const conversation = summaries.conversations.find(
    (conversation) => selectedCardId === conversation.id,
  );

  const shopData = salesShopsData?.shop_data.find(
    (shop) => shop.id === conversation?.shop?.id,
  );

  return (
    <>
      {!noConversations && (
        <styles.ActionsContainer>
          <DateRangeTabGroup
            navItems={navItems}
            selectedDateId={selectedDateId}
            handleClick={handleDateRangeTabGroupClick}
          />
        </styles.ActionsContainer>
      )}

      {noConversations ? (
        <NoConversations />
      ) : (
        <styles.CIColumns className="single-col">
          <styles.MainColumn vertical>
            {!hasInsights && showNoInsightsBanner && (
              <Banner
                bodyText="Summaries, tags, and sentiments are available 48 hours after each conversation ends. Topic insights begin generating after 500-1000 conversations."
                heading="Your insights will be available here soon"
                onDismiss={() => {
                  setShowNoInsightsBanner(false);
                  localStorage.setItem('insightsBannerDismissed', 'true');
                }}
                variant="guidance"
              />
            )}
            <styles.TitleAndChart>
              <SearchControls
                controls={controls}
                setControls={setControls}
                isLoading={isConversationsLoading || isTopicLabelTrendsLoading}
                title={
                  <SearchControlsTitle
                    controls={controls}
                    dateLabel={dateRangeLabel({ selectedDateId, numberOfDays })}
                  />
                }
                clearFilters={clearFilters}
              />
              <TrendChartInfoMessages
                controls={controls}
                clearFilters={clearFilters}
              />
              <TrendChart
                isTopicLabelTrendsLoading={isTopicLabelTrendsLoading}
                controls={controls}
                data={topicLabelTrends.trends}
                days={numberOfDays}
              />
            </styles.TitleAndChart>

            <styles.MetricsContainer>
              <ConversationIntelligenceMetrics
                onDownload={triggerJSONDownload}
                metrics={metrics}
                isLoading={isMetricsLoading}
                dateLabel={dateRangeLabel({ selectedDateId, numberOfDays })}
              />
            </styles.MetricsContainer>

            <styles.SummaryCardsAndTitle>
              <styles.CardHeadingRow>
                <styles.CardHeading size="x-small">
                  Highlights and summaries
                </styles.CardHeading>
                <Switch
                  checked={!controls.filters.includes(HAS_SUMMARY)}
                  onChange={toggleHasSummaryFilter}
                  fieldSize="small"
                  id="unsummarized-toggle"
                  label="Show unsummarized"
                />
              </styles.CardHeadingRow>

              <ConversationSummaries
                isLoading={isConversationsLoading}
                users={summaries.users}
                conversations={summaries.conversations}
                selectedCardId={selectedCardId}
                salesShopsData={salesShopsData}
                onViewConversationClick={handleViewConversationClick}
              />

              <InsightsPagination
                isLoading={isConversationsLoading || isMetricsLoading}
                totalItems={metrics.matching_conversations}
                pageSize={MAX_PAGE_SIZE}
                page={controls.page}
                onPageChange={(page) =>
                  setControls((prevControls) => ({ ...prevControls, page }))
                }
              />
            </styles.SummaryCardsAndTitle>
          </styles.MainColumn>
        </styles.CIColumns>
      )}

      {conversation && (
        <styles.ChatContainer isOpen={!!selectedCardId} ref={chatDrawerRef}>
          <ConversationDrawer
            chatDrawerRef={chatDrawerRef}
            subscriberId={subscriberId}
            conversation={conversation}
            selectedCardId={selectedCardId}
            clearConversation={() => {
              setSubscriberId(undefined);
              setSelectedCardId(null);
            }}
            shopData={shopData}
          />
        </styles.ChatContainer>
      )}
    </>
  );
};

export default ConversationIntelligence;
