import { Banner } from '@postscript/components';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import {
  Controls,
  CONVERTED,
  NOT_CONVERTED,
} from '../../../components/ConversationIntelligenceControls';

interface ConversionDataEpochMessageProps {
  controls: Controls;
  clearFilters: () => void;
}

const InfoMessagesContainer = styled.div`
  max-width: 636px;
`;

export const ConversionDataEpochMessage = ({
  controls,
  clearFilters,
}: ConversionDataEpochMessageProps) => {
  if (
    !controls.filters.includes(CONVERTED) &&
    !controls.filters.includes(NOT_CONVERTED)
  ) {
    return null;
  }

  /* This is the moment in time we started filling conversation outcome data. */
  const cutoffTime = moment.utc('2025-01-25T00:00:00');
  const dateRangeStart = moment(controls.dateRangeStart);

  const isQueryingBeforeDataBackfillEpoch = dateRangeStart.isBefore(cutoffTime);

  if (!isQueryingBeforeDataBackfillEpoch) {
    return null;
  }

  return (
    <Banner
      primaryAction={{
        text: 'Clear Conversion Filters',
        onClick: clearFilters,
      }}
      bodyText="Data is only available for orders on or after Jan. 25th when filtering by “Converted” or “Did Not Convert” conversations."
    />
  );
};

interface Props {
  controls: Controls;
  clearFilters: () => void;
}

export const TrendChartInfoMessages = ({ controls, clearFilters }: Props) => {
  const location = useLocation();

  const isShopperTrendsPage = location.pathname === '/analytics/shopper';

  if (!isShopperTrendsPage) {
    return null;
  }

  return (
    <InfoMessagesContainer>
      <ConversionDataEpochMessage
        controls={controls}
        clearFilters={clearFilters}
      />
    </InfoMessagesContainer>
  );
};
