/* eslint-disable no-param-reassign */
/* eslint-disable func-names */
/* eslint-disable no-unused-vars */
import { isEmpty } from 'lodash';
import { DateTime } from 'luxon';
import cookie from 'react-cookies';
import Requests from './network/network_requests';

const LOGIN_ROUTE = '/login';
const TOKEN_MAX_AGE_DAYS = 14;
const TOKEN_MAX_AGE_HOUR = 2; // 2:00 AM

function logOutAccess() {
  const promise = new Promise(function (resolve, reject) {
    Requests.post('/logout/access', {}).then(function (result) {
      resolve(true);
    });
  });
  return promise;
}

function logOutRefresh() {
  const promise = new Promise(function (resolve, reject) {
    Requests.post('/logout/refresh', {}, true).then(function (result) {
      resolve(true);
    });
  });
  return promise;
}

const Utils = {
  findGetParameter(parameterName) {
    let result = null;
    let tmp = [];
    window.location.search
      .substr(1)
      .split('&')
      .forEach(function (item) {
        tmp = item.split('=');
        if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
      });
    return result;
  },
  checkIfLoggedIn() {
    const promise = new Promise(function (resolve, _reject) {
      const hasNeededTokens =
        !isEmpty(cookie.load('access_token')) &&
        !isEmpty(cookie.load('refresh_token'));
      resolve(hasNeededTokens);
    });
    return promise;
  },

  createFakeUser() {
    const promise = new Promise(function (resolve, reject) {
      const payload = {
        username: Math.floor(Math.random() * 1000),
        password: Math.floor(Math.random() * 1000),
      };
      Requests.post('/registration', payload).then(function (result) {
        if (result.access_token) {
          this.saveAuthTokens({
            accessToken: result.access_token,
            refreshToken: result.refresh_token,
          });
          resolve(true);
        }
      });
    });
    return promise;
  },

  createUser(username, password) {
    const promise = new Promise(function (resolve, reject) {
      // Check cookies to make sure shop is included
      // If no shop, do not create user
      // Popup something if no shop exists, ask them to enter shop URL
      const payload = {
        username,
        password,
      };
      Requests.post('/registration', payload).then(function (result) {
        if (result.access_token) {
          this.saveAuthTokens({
            accessToken: result.access_token,
            refreshToken: result.refresh_token,
          });
          resolve(true);
        }
      });
    });
    return promise;
  },

  /**
   * @param {Object} tokens
   * @param {string} [tokens.accessToken]
   * @param {string} [tokens.refreshToken]
   */
  saveAuthTokens({ accessToken, refreshToken }) {
    const tokenDateTime = DateTime.now()
      .plus({ days: TOKEN_MAX_AGE_DAYS })
      .set({ hour: TOKEN_MAX_AGE_HOUR, minute: 0, second: 0 }); // Make the cookie expire in the middle of the night
    const maxAge = tokenDateTime.diffNow('seconds').seconds;

    if (accessToken) {
      cookie.save('access_token', accessToken, {
        path: '/',
        maxAge,
      });
    }
    if (refreshToken) {
      cookie.save('refresh_token', refreshToken, {
        path: '/',
        maxAge,
      });
    }
  },

  clearAuthTokens() {
    cookie.remove('access_token');
    cookie.remove('refresh_token');
  },

  clearAuthTokensAndRedirect() {
    this.clearAuthTokens();
    window.location.href = LOGIN_ROUTE;
  },

  logOut() {
    const promise = new Promise(function (resolve, reject) {
      logOutAccess().then(function () {
        cookie.remove('access_token');
        logOutRefresh().then(function () {
          cookie.remove('refresh_token');
          resolve(true);
        });
      });
    });
    return promise;
  },

  logOutAndRedirect() {
    this.logOut().then(function () {
      window.location.href = LOGIN_ROUTE;
    });
  },
};

// Promise for rejecting bad API requests.
const rejectErrors = (resp) => {
  if (!resp || resp.error) {
    resp = resp || {};
    const error = resp.error
      ? resp.error
      : 'An error occurred. Please try again.';
    console.log(error);
    throw error;
  }
  return resp;
};

export default Utils;

export { rejectErrors };
