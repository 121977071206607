import { toastError } from 'components/billing/common/utils';
import {
  ShopperVerificationStatus,
  TFNVerificationStatus,
} from 'components/notifications/types';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';
import {
  AccountStatus,
  BillingAccountStatus,
  getAccountStatus,
  setAccountStatus,
} from './accountClient';

const staleTime = 1000 * 60;

export const ACCOUNT_STATUS_KEY = 'account_status';

export function useGetAccountStatus(
  options?: UseQueryOptions<
    any,
    any,
    AccountStatus,
    [typeof ACCOUNT_STATUS_KEY]
  >,
): UseQueryResult<AccountStatus> {
  return useQuery({
    queryKey: [ACCOUNT_STATUS_KEY],
    queryFn: getAccountStatus,
    onError: toastError, // TODO: useQuery callbacks removed in v5
    staleTime,
    ...options,
  });
}

export function useSetAccountStatus(): UseMutationResult<
  Partial<AccountStatus>,
  unknown,
  Partial<AccountStatus>
> {
  const queryClient = useQueryClient();

  return useMutation(
    (status: Partial<AccountStatus>) => setAccountStatus(status),
    {
      onSuccess: () => queryClient.refetchQueries(ACCOUNT_STATUS_KEY),
      onError: toastError,
    },
  );
}

export interface FlatAccountStatus {
  billingStatus?: BillingAccountStatus;
  billingActive: boolean;
  installed: boolean;
  tfnVerificationStatus?: TFNVerificationStatus;
  shopperVerificationStatus?: ShopperVerificationStatus;
  isFetched: boolean;
}

export default function useAccountStatus(
  options?: UseQueryOptions<
    any,
    any,
    AccountStatus,
    [typeof ACCOUNT_STATUS_KEY]
  >,
): FlatAccountStatus {
  const { data, isFetched } = useGetAccountStatus(options);

  const billingStatus = data?.billing?.account;
  const billingActive = billingStatus === 'ACTIVE';
  const installed = data?.shopify?.installed === true;
  const tfnVerificationStatus = data?.compliance?.tfn;
  const shopperVerificationStatus = data?.compliance?.shopper;

  return {
    billingStatus,
    billingActive,
    installed,
    tfnVerificationStatus,
    shopperVerificationStatus,
    isFetched,
  };
}
