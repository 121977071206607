/* eslint-disable camelcase */
import { LinkText } from '@postscript/components';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import { api } from 'controllers/network/apiClient';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { BANNER_TYPES } from 'utils/events';

const SHOPIFY_APPS_PERMISSIONS_LINK =
  'https://help.shopify.com/en/manual/your-account/staff-accounts/staff-permissions/staff-permissions-descriptions#apps-and-channels-permissions';

const fetchScopes = () =>
  api.get<{ permission_url?: string }>(
    '/v2/platforms/shopify/scopes/upgrade_url',
  );

const ScopesUpdateBanner = () => {
  const { addBanner } = useBanners();
  const { data } = useQuery({
    queryKey: ['scopes'],
    queryFn: fetchScopes,
    staleTime: Infinity,
    refetchOnWindowFocus: 'always',
  });
  const { permission_url } = data || {};

  useEffect(() => {
    if (!permission_url) return;

    addBanner({
      id: 'SCOPES_UPDATE_BANNER',
      data: {
        variant: 'default',
        heading: 'Additional Shopify permissions required',
        bodyText: (
          <>
            New Postscript features will require additional Shopify access. To
            make this change, you&apos;ll need the{' '}
            <LinkText href={SHOPIFY_APPS_PERMISSIONS_LINK} target="_blank">
              Manage and install apps and channels
            </LinkText>{' '}
            permission.
          </>
        ),
        primaryAction: {
          onClick: () => window.open(permission_url, '_self'),
          text: 'Go to Shopify',
        },
      },
      eventTrackingOptions: {
        bannerType: BANNER_TYPES.SHOPIFY_SCOPES_UPDATE,
      },
    });
  }, [permission_url]);

  return null;
};

export default ScopesUpdateBanner;
