/* eslint-disable no-unused-vars */
/* eslint-disable func-names */
/* eslint-disable camelcase */
import React from 'react';
import Token from '../../controllers/network/tokens';
import Utils from '../../controllers/utils';

const initialState = {};

class ResetPasswordInivisible extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
    this.componentDidMount = this.componentDidMount.bind(this);
  }

  componentDidMount() {
    const refresh_token = Utils.findGetParameter('refresh_token');
    if (refresh_token) {
      Utils.saveAuthTokens({ refreshToken: refresh_token });
      Token.refreshAccessToken().then(function (result) {
        Utils.checkIfLoggedIn().then(function (result) {
          if (result) {
            window.location.replace('/account/reset');
          }
        });
      });
    }
  }

  render() {
    return <div />;
  }
}

export default ResetPasswordInivisible;
