export const ROUTE_POPUPS_BLOCK = '/block-popups';
export const SUBROUTE_CREATE_POPUP = '/create';
export const SUBROUTE_CREATE_SPLIT_TEST = '/create_split_test';
export const SUBROUTE_EDITOR = '/editor';
export const SUBROUTE_LIST = '/list';
export const SUBROUTE_ADMIN_TEMPLATES = '/popups';
export const BLOCK_POPUPS_QUERY_KEY_PREFIX = 'block-popups';
export const BLOCK_POPUPS_SPLIT_TESTS_QUERY_KEY_PREFIX =
  'block-popups/split-tests';

export const NEW_POPUP_QUERY_PARAM = 'newpopup';
export const NEW_POPUP_QUERY_PARAM_VALUE = 'true';
export const SPLIT_TEST_TOOLTIP_MESSAGE =
  'There must be at least two available popups to create an A/B test. Create a new popup or clone an existing one, make the changes you want to test, and try again.';
export const DISCLAIMER_TEXT_QUERY_KEY_PREFIX = 'disclaimer-text';
export const POPUP_TEMPLATES_QUERY = 'popup-templates';
export const POPUP_TEMPLATES_ACTIVE_QUERY = 'popup-templates-active';
