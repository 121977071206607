import { toast } from '@postscript/components';
import { api } from 'controllers/network/apiClient';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { camelCaseKeys } from 'utils/formatters';

export const SDK_APP_EXTENSION_QUERY_KEY = 'checkAppExtension';

export interface SdkExtensionStatus {
  installUrl: string;
  hasExtension: boolean;
  hasMetafield: boolean;
}

export const useSdkAppExtensionStatus = (options?: any) =>
  useQuery(
    [SDK_APP_EXTENSION_QUERY_KEY],
    async () => {
      const result = await api.get('/v2/shops/check_sdk_app_extension/');
      return camelCaseKeys(result) as SdkExtensionStatus;
    },
    {
      staleTime: 5 * 60 * 1000,
      ...options,
    },
  );

export const useRefreshExtensionMetafield = () => {
  const queryClient = useQueryClient();
  return useMutation(() => api.post('/v2/shops/fix_sdk_app_extension/'), {
    onSuccess: () => {
      toast.success('Successfully refreshed metafield.');
      queryClient.invalidateQueries([SDK_APP_EXTENSION_QUERY_KEY]);
    },
    onError: (error) => {
      toast.error(error);
    },
  });
};
