import { BodyText } from '@postscript/components';
import styled, { css } from 'styled-components';
import { MessageItemProps } from '../types';
import { getMessageType } from '../utils/messageUtils';

export const MediaFlexWrapper = styled.div<MessageItemProps>`
  padding: ${({ incoming }) =>
    incoming
      ? '0 var(--spacing-7) 0 var(--spacing-5)'
      : '0 var(--spacing-5) 0 var(--spacing-7)'};
  max-width: 192px;
  margin: 0 0 var(--spacing-1);
  margin-left: ${({ incoming }) => (incoming ? '0' : 'auto')};
`;

export const MediaThreadPreview = styled.img<
  MessageItemProps & { hasText?: boolean }
>`
  display: block;
  border-radius: var(--border-radius-small);
  ${({ incoming, hasText }) =>
    !hasText && `border-bottom-${incoming ? 'left' : 'right'}-radius: 0;`}
  width: 100%;
  cursor: pointer;
`;

export const MessageFlexWrapper = styled.div<MessageItemProps>`
  display: flex;
  padding: ${({ incoming }) =>
    incoming
      ? '0 var(--spacing-7) 0 var(--spacing-5)'
      : '0 var(--spacing-5) 0 var(--spacing-7)'};
  justify-content: ${({ incoming }) => (incoming ? 'flex-start' : 'flex-end')};
  position: relative;

  & .message-avatar {
    margin: 0;
    position: absolute;
    bottom: 0;
    ${({ incoming }) => (incoming ? 'left: 0;' : 'right: 0;')}
  }
`;

export const StyledMessageBubble = styled.div<MessageItemProps>`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-1);
  border-radius: var(--border-radius-medium);
  border-bottom-${({ incoming }) => (incoming ? 'left' : 'right')}-radius: 0;
  padding: var(--spacing-1) var(--spacing-2);
  background: var(--color-message-bubble-${({ incoming, messageType }) =>
    getMessageType({ incoming, messageType })});
  color: var(--color-message-text-${({ incoming, messageType }) =>
    getMessageType({ incoming, messageType })});
  word-break: break-word;
  text-align: left;
`;

export const StyledBodyText = styled(BodyText)<{ truncate?: boolean }>`
  display: inline;

  &:empty {
    display: none;
  }

  ${({ truncate }) =>
    truncate &&
    css`
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 10;
      line-clamp: 10;
      -webkit-box-orient: vertical;
    `}

  & a {
    color: currentColor;
    text-decoration: underline;

    &:visited {
      opacity: 0.85;
    }
    &:hover {
      opacity: 0.65;
    }
    &:active {
      opacity: 0.5;
    }
    &:focus-visible {
      color: var(--purple-core);
    }
  }
`;

export const StyledAvatar = styled.div<MessageItemProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  font: var(--body-text-small);
  font-weight: var(--body-text-bold-font-weight);
  background: transparent;
  background: var(
    --color-message-bubble-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  color: var(
    --color-message-text-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  width: 24px;
  height: 24px;
  border-radius: var(--border-radius-round);
  position: absolute;
  bottom: 0;
  ${({ incoming }) => (incoming ? 'left: 0;' : 'right: 0;')}
`;

export const StyledMetadata = styled.span<MessageItemProps>`
  font: var(--body-text-normal-font-weight) 0.625rem / 0.75rem
    var(--body-text-typeface);
  color: var(
    --color-message-timestamp-${({ incoming, messageType }) => getMessageType({ incoming, messageType })}
  );
  text-align: ${({ incoming }) => (incoming ? 'left' : 'right')};
  margin-top: var(--spacing-1);
  padding: ${({ incoming }) =>
    incoming
      ? '0 var(--spacing-7) 0 var(--spacing-5)'
      : '0 var(--spacing-5) 0 var(--spacing-7)'};
  display: block;
`;
