import { Modal } from '@postscript/components';
import ErrorBoundary from 'components/generic/ErrorBoundary';
import { useGlobalModal } from './globalModal';

export default function GlobalModalContainer(): JSX.Element {
  const { modal, hideModal } = useGlobalModal();

  return (
    <Modal
      isOpen={!!modal}
      onCancel={hideModal}
      dismissOnBackdropClick={modal?.dismissOnBackdropClick}
      style={modal?.style}
    >
      <ErrorBoundary>{modal?.children}</ErrorBoundary>
    </Modal>
  );
}
