/* ----------------------------- Static Actions ----------------------------- */

export const StaticActionsWithFormTypes = {
  SEND_MESSAGE: 'SendMessage',
  RICH_MESSAGE: 'RichMessage',
  WAIT: 'Wait',
  UPDATE_SUBSCRIBER: 'UpdateSubscriber',
  AB_SPLIT: 'Split',
  OPTIMIZED_AB_SPLIT: 'OptimizedSplit',
  WAIT_FOR_EVENT_SPLIT: 'WaitForEventSplit',
  TRIGGER_EVENT_SPLIT: 'TriggerEventSplit',
  SUBSCRIBER_ATTRIBUTE_SPLIT: 'SubscriberAttributeSplit',
  TEXT_TO_BUY: 'TextToBuy',
  AI_MANAGED_MESSAGE: 'AIManagedMessage',
  AI_MANAGED_OUTBOUND: 'AIManagedOutbound',
} as const;

export const StaticActionsTypes = {
  ...StaticActionsWithFormTypes,
  END: 'End',
} as const;

/* -------------------------------- Actions --------------------------------- */

export const ActionTypeStatuses = {
  ENABLED: 'Enabled',
  DISABLED: 'Disabled',
  HIDDEN: 'Hidden',
} as const;
