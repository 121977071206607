import { toast } from '@postscript/components';
import { CORE_EVENT_TRIGGER_NAMES } from 'constants/constants';
import produce from 'immer';
import { get, isEmpty, isNil } from 'lodash';
import PropTypes from 'prop-types';
import { createContext, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router-dom';
import Events from '../events';
import Requests from '../network/network_requests';
import Shops from '../shops';
import Todos from '../todos';
import { rejectErrors } from '../utils';

const LOADING = 'LOADING';
const SET_ITEMS = 'SET_ITEMS';
const ADD_ITEM = 'ADD_ITEM';
const UPDATE_ITEM = 'UPDATE_ITEM';
const REMOVE_ITEM = 'REMOVE_ITEM';
const SET_TRIGGERS = 'SET_TRIGGERS';
const SET_TOTAL_RESULTS = 'SET_TOTAL_RESULTS';
const SET_TOTAL_AUTOMATIONS = 'SET_TOTAL_AUTOMATIONS';
const DATA_TYPE = 'automations';

const TOGGLE_STATE_ENABLED = 'Enabled';
const TOGGLE_STATE_DISABLED = 'Disabled';

const defaultInitialState = {
  loading: true,
  triggers: [],
  [DATA_TYPE]: null,
};

export const AutomationsContext = createContext(defaultInitialState);
export const useAutomations = () => useContext(AutomationsContext);

export const cleanTriggers = (triggers, user) => {
  // Sort alphabetically, and then move all integration triggers with a colon to the end
  let cleanedTriggers = triggers.sort().sort((a, b) => {
    if (!a.name.includes(':') && b.name.includes(':')) return -1;
    if (a.name.includes(':') && !b.name.includes(':')) return 1;
  });

  // Remove integration triggers if the user is a competitor
  cleanedTriggers = get(user, 'data.competitor_shop')
    ? cleanedTriggers.filter((f) => !f.name.includes(':'))
    : cleanedTriggers;

  // Remove core triggers, as we will direct users to Flow Builder for these as
  // part of the legacy automation deprecation process.
  cleanedTriggers = cleanedTriggers.filter(
    (filter) => !CORE_EVENT_TRIGGER_NAMES.includes(filter.name),
  );

  return cleanedTriggers;
};

const reducerFn = (draft, action) => {
  switch (action.type) {
    case LOADING:
      draft.loading = action.loading;
      break;
    case SET_TOTAL_RESULTS:
      draft.totalResults = action.totalResults;
      break;
    case SET_TOTAL_AUTOMATIONS:
      draft.totalAutomations = action.totalAutomations;
      break;
    case SET_TRIGGERS:
      draft.triggers = action.triggers;
      break;
    case SET_ITEMS:
      draft[DATA_TYPE] = action.data;
      break;
    case ADD_ITEM:
      if (isNil(draft[DATA_TYPE])) draft[DATA_TYPE] = [];

      draft[DATA_TYPE].push(action.data);
      break;
    case UPDATE_ITEM: {
      const index = draft[DATA_TYPE].findIndex(({ id }) => id === action.id);

      const item = draft[DATA_TYPE][index];

      draft[DATA_TYPE][index] = {
        ...item,
        ...action.data,
      };
      break;
    }
    case REMOVE_ITEM:
      draft[DATA_TYPE] = draft[DATA_TYPE].filter(({ id }) => id !== action.id);
      break;
    default:
  }
};

const reducer = produce(reducerFn);

export const AutomationsProvider = ({ initialState, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { push } = useHistory();

  const setLoading = (loading) => {
    dispatch({
      type: LOADING,
      loading,
    });
  };

  const setItems = (data) => {
    dispatch({
      type: SET_ITEMS,
      data,
    });
  };

  const addItem = (data) => {
    dispatch({
      type: ADD_ITEM,
      data,
    });
  };

  const updateItem = (id, data) => {
    dispatch({
      type: UPDATE_ITEM,
      id,
      data,
    });
  };

  const setTriggers = (triggers) => {
    dispatch({
      type: SET_TRIGGERS,
      triggers,
    });
  };

  const removeItem = (id) => {
    dispatch({
      type: REMOVE_ITEM,
      id,
    });
  };

  const setTotalResults = (totalResults) => {
    dispatch({
      type: SET_TOTAL_RESULTS,
      totalResults,
    });
  };

  const setTotalAutomations = (totalAutomations) => {
    dispatch({
      type: SET_TOTAL_AUTOMATIONS,
      totalAutomations,
    });
  };

  const search = async ({
    currentPage = 1,
    limit = 10,
    automationFilter = '',
  } = {}) => {
    setLoading(true);
    let searchUrl = `/automations?start=${
      (currentPage - 1) * limit
    }&limit=${limit}`;
    if (automationFilter) {
      searchUrl += `&name=${automationFilter}`;
    }
    const response = await Requests.get(searchUrl);
    if (!response?.automations?.automations) {
      setItems([]);
      setTotalResults(0);
      setTotalAutomations(0);
      setLoading(false);
      return { automations: [], totalResults: 0 };
    }
    const {
      automations: { automations, totalResults, totalAutomations },
    } = response;

    setItems(automations);
    setTotalResults(totalResults);
    setTotalAutomations(totalAutomations);
    setLoading(false);

    return { automations, totalResults };
  };

  const getSingle = async (automationId) => {
    const {
      automation: [currentAutomation],
    } = await Requests.get(`/automations/${automationId}`);

    return currentAutomation;
  };

  const remove = async (automationId) => {
    try {
      // eslint-disable-next-line no-alert
      const confirm = window.confirm(
        'Are you sure you want to delete this Automation?',
      );

      if (!confirm) return;

      Events.track('Delete Automation Clicked', {
        automation_id: automationId,
      });
      const path = '/automations/delete';
      const payload = {
        automation_id: automationId,
      };

      await Requests.post(path, payload).then(rejectErrors);

      removeItem(automationId);

      Events.track('Deleted Automation', { automation_id: automationId });
    } catch (err) {
      toast.error(err.toString());
    }
  };

  const clone = async (automation, shop = {}) => {
    try {
      Events.track('Clone Automation', {
        automation_id: automation.id,
        shop_id: get(shop, 'id', null),
      });

      if (isEmpty(shop)) return push(`/automations/${automation.id}/clone`);

      const result = await Shops.cloneAutomationInto(
        { ...shop },
        { ...automation, id: undefined },
      ).then(rejectErrors);

      Events.track('Cloned Automation');

      toast.success(
        `Successfully cloned '${automation.name}'${
          shop ? ` into '${shop.label}'` : ''
        }`,
      );

      return result;
    } catch (err) {
      toast.error(err.toString());
    }
  };

  const toggleEnabledStatus = async (automationId, status) => {
    const originalStatus =
      status === TOGGLE_STATE_ENABLED
        ? TOGGLE_STATE_DISABLED
        : TOGGLE_STATE_ENABLED;
    try {
      // Optimistically set toggle since user needs immediate feedback
      updateItem(automationId, {
        status,
      });

      Events.track('Toggle Automation Clicked', {
        automation_id: automationId,
      });

      const path = '/automations/toggle';
      const payload = {
        automation_id: automationId,
        status,
      };

      // Upload toggled automation in state list
      await Requests.post(path, payload).then(rejectErrors);

      Events.track(`Automation ${payload.status}`, {
        automation_id: automationId,
      });

      Todos.complete('ac');
    } catch (err) {
      updateItem(automationId, {
        status: originalStatus,
      });
      toast.error(err);
    }
  };

  const getTriggers = async () => {
    const {
      data: { triggers },
    } = await Requests.get('/shopify_triggers');

    setTriggers(cleanTriggers(triggers));
  };

  useEffect(() => {
    search();
    getTriggers();
  }, []);

  return (
    <AutomationsContext.Provider
      value={{
        ...state,
        setItems,
        addItem,
        updateItem,
        removeItem,
        search,
        remove,
        toggleEnabledStatus,
        clone,
        getTriggers,
        getSingle,
      }}
    >
      {children}
    </AutomationsContext.Provider>
  );
};

AutomationsProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array])
    .isRequired,
  initialState: PropTypes.object,
};

AutomationsProvider.defaultProps = {
  initialState: defaultInitialState,
};
