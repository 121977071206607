import { toast } from '@postscript/components';
import { toastError } from 'components/billing/common/utils';
import { formatErrorMessage } from 'components/flowBuilder/utils/errors';
import { api } from 'controllers/network/apiClient';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { QueryCacheTimes } from '../../flowBuilder/constants';
import {
  TFNVerificationRequest,
  TFNVerificationRequestsResponse,
} from '../types';

const tfnVerification = 'tfn_verification';

const getTfnVerifications =
  async (): Promise<TFNVerificationRequestsResponse> => {
    return api.get('/v2/messaging/verification_requests/');
  };

export function useGetTFNVerification(): UseQueryResult<TFNVerificationRequest> {
  return useQuery(
    [tfnVerification],
    async (): Promise<TFNVerificationRequest | null> => {
      const { requests }: TFNVerificationRequestsResponse =
        await getTfnVerifications();
      if (!requests.length) return null;
      return requests[0];
    },
    {
      staleTime: QueryCacheTimes.SHORT,
      onError: (error) => toast.error(formatErrorMessage(error)),
    },
  );
}

export function useSkipTFNValidation(): UseMutationResult<void, any, void> {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      return api.post('/v2/messaging/verification_requests/skip_validation');
    },
    {
      onSuccess: () => {
        queryClient.refetchQueries([tfnVerification]);
      },
      onError: toastError,
    },
  );
}
