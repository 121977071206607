import { Plan } from 'components/billing/common/types';

const starterPackageName = 'Starter';

export function isMinSpendStarterPlan(plan?: Plan | null): boolean {
  return (
    !!plan?.minimumCommittedSpendCents &&
    plan.package?.name === starterPackageName
  );
}

export function isEndDateBeforeStartDate(
  endDate: string,
  startDate: string,
): boolean {
  const end = new Date(endDate);
  const start = new Date(startDate);
  return end < start;
}

export function isStartDateInFuture(startDate: string): boolean {
  const start = new Date(startDate);
  return start > new Date();
}
