import {
  BodyText,
  Button,
  Code,
  IconSet,
  Layout,
  LinkText,
} from '@postscript/components';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

const CustomTriggerHelpContainer = styled.div<{
  popupType: 'legacy' | 'block';
}>`
  border: 1px solid var(--border-color-dim);
  border-radius: var(--border-radius-medium);
  grid-column: 1/-1;
  padding: var(--spacing-2);

  ${({ popupType }) =>
    popupType === 'legacy' &&
    `
      background-color: var(--main-bkg-color);
    `}
`;

const StyledCodeBlock = styled(Code)<{ popupType: 'legacy' | 'block' }>`
  display: block;
  border: 1px solid var(--border-color-dim);
  border-radius: var(--border-radius-x-small);
  padding: var(--spacing-1) var(--spacing-2);
  white-space: pre-wrap;
  font-size: ${({ popupType }) =>
    popupType === 'block'
      ? 'var(--body-text-font-size-x-small)'
      : 'var(--body-text-font-size-small)'};
`;

const getEmbedCode = (popupId: number | string) =>
  `window.postscript.popups.open(${
    typeof popupId === 'string' ? `"${popupId}"` : popupId
  }, {
    activePopupBehavior: 'ALWAYS_DISMISS',
    respectPopupStatus: true
  });
  `;

type CustomTriggerHelpProps = {
  id: number | string;
  popupType: 'legacy' | 'block';
};

export const CustomTriggerHelpCard = ({
  id,
  popupType,
}: CustomTriggerHelpProps) => {
  const [hasCopied, setHasCopied] = useState(false);
  const textSize = popupType === 'block' ? 'x-small' : 'small';

  useEffect(() => {
    let timeout: NodeJS.Timeout;
    if (hasCopied) {
      timeout = setTimeout(() => {
        setHasCopied(false);
      }, 1000);
    }
    return () => clearTimeout(timeout);
  }, [hasCopied]);

  return (
    <CustomTriggerHelpContainer popupType={popupType}>
      <BodyText size={textSize} style={{ marginBottom: 'var(--spacing-2)' }}>
        Use custom criteria to trigger a popup by embedding code on your site.
        Learn more about setting additional criteria (e.g. popup priority,
        cookies, etc) in the{' '}
        <LinkText
          target="_blank"
          href="https://developers.postscript.io/docs/javascript-sdk-api-reference#windowpostscriptpopupsopen"
        >
          Developer Documentation
        </LinkText>
        .
      </BodyText>

      <Layout align="justify">
        <BodyText size={textSize}>Embed code</BodyText>
        <Button
          onClick={async () => {
            await navigator.clipboard.writeText(getEmbedCode(id));
            setHasCopied(true);
          }}
          size="small"
          variant="plain"
          icon={hasCopied ? IconSet.CheckCircle : IconSet.Copy}
          iconPosition="right"
        >
          {hasCopied ? 'Copied' : 'Copy code'}
        </Button>
      </Layout>
      <StyledCodeBlock popupType={popupType}>
        {getEmbedCode(id)}
      </StyledCodeBlock>
    </CustomTriggerHelpContainer>
  );
};
