/* eslint-disable camelcase */
import { api } from 'controllers/network/apiClient';
import {
  QueryClient,
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryOptions,
  UseQueryResult,
} from 'react-query';

export interface ResourceTemplate<TData = { [x: string]: any }> {
  id: number;
  name: string;
  description: string | null;
  type: string;
  category: string;
  data: TData;
  favorite?: boolean;
  tags?: string[];
  scoped_to?: string[];
  active?: boolean;
  /* The base 62 encoded version of the integer `id`. This is optional because
  it's serialized onto API responses, but is not part of the template schema (so
  it will be dropped if included in requests). */
  hashed_id?: string;
}

export const isResourceTemplate = (
  template: unknown,
): template is ResourceTemplate => {
  const templateValue = template as ResourceTemplate;

  if (!templateValue || typeof templateValue !== 'object') return false;
  if (typeof templateValue.id !== 'number') return false;
  if (!templateValue.name || typeof templateValue.name !== 'string')
    return false;
  if (
    templateValue.description !== null &&
    typeof templateValue.description !== 'string'
  )
    return false;
  if (typeof templateValue.type !== 'string') return false;
  if (!templateValue.category || typeof templateValue.category !== 'string')
    return false;
  if (!templateValue.data || typeof templateValue.data !== 'object')
    return false;

  return true;
};

export interface ResourceTemplatesResponse<TData> {
  templates: ResourceTemplate<TData>[];
}

const fetchResourceTemplates = async <TData>(
  resourceType: string,
): Promise<ResourceTemplate<TData>[]> => {
  const response = await api.get<ResourceTemplatesResponse<TData>>(
    `/v2/templates/?type__eq=${resourceType}`,
  );
  return response.templates;
};

export const RESOURCE_TEMPLATE_TYPES = {
  CAMPAIGN: 'campaign',
  POPUP: 'popup',
  RESPONSE: 'response',
} as const;

export const createResourceTemplateQueryKey = (
  resourceType: string,
): string[] => ['messageTemplates', resourceType];

const useResourceTemplates = <TData = { [x: string]: any }>(
  resourceType: string,
  options?: UseQueryOptions<
    ResourceTemplate<TData>[],
    unknown,
    ResourceTemplate<TData>[],
    string[]
  >,
): UseQueryResult<ResourceTemplate<TData>[]> =>
  useQuery(
    createResourceTemplateQueryKey(resourceType),
    () => fetchResourceTemplates<TData>(resourceType),
    {
      staleTime: Infinity,
      ...options,
    },
  );

const saveFavoriteTemplate = async (templateId: number, templateType: string) =>
  api.post('/v2/templates/favorite', {
    template_id: templateId,
    type: templateType,
  });

const deleteFavoriteTemplate = async (templateId: number) =>
  api.delete(`/v2/templates/favorite/${templateId}`);

const updateFavoriteRecord = (
  queryClient: QueryClient,
  queryKey: string[],
  templateId: number,
  favoriteValue: boolean,
) => {
  const existingTemplates =
    queryClient.getQueryData<ResourceTemplate[]>(queryKey) ?? [];
  const updateTemplates = existingTemplates.map((template) =>
    template.id === templateId
      ? {
          ...template,
          favorite: favoriteValue,
        }
      : template,
  );
  queryClient.setQueryData(queryKey, updateTemplates);
};

export const useSaveFavoriteTemplate = (
  templateType: string,
): UseMutationResult<void, any, number> => {
  const queryClient = useQueryClient();

  return useMutation(
    (templateId: number) => saveFavoriteTemplate(templateId, templateType),
    {
      onSuccess: (_, updatedTemplateId: number) =>
        updateFavoriteRecord(
          queryClient,
          createResourceTemplateQueryKey(templateType),
          updatedTemplateId,
          true,
        ),
    },
  );
};

export const useDeleteFavoriteTemplate = (
  templateType: string,
): UseMutationResult<void, any, number> => {
  const queryClient = useQueryClient();

  return useMutation(
    (templateId: number) => deleteFavoriteTemplate(templateId),
    {
      onSuccess: (_, updatedTemplateId: number) =>
        updateFavoriteRecord(
          queryClient,
          createResourceTemplateQueryKey(templateType),
          updatedTemplateId,
          false,
        ),
    },
  );
};

export default useResourceTemplates;
