import { SMS_SALES_VIEW_LOCAL_STORAGE_KEY } from 'constants/constants';
import {
  HAS_SUMMARY,
  NO_SUMMARY,
} from '../../components/ConversationIntelligenceControls';
import { VIEW_OPTIONS } from '../../constants';
import { ShopData } from '../../types';

export const isBubAI = () => {
  return (
    localStorage.getItem(SMS_SALES_VIEW_LOCAL_STORAGE_KEY) ===
    VIEW_OPTIONS.AI_DEBUGGER
  );
};

export const getIsMultiShop = () => window.location.href.includes('workspace');

export const formatTopicLabel = (label: string): string => {
  const lowerCaseLabel = label.trim().toLowerCase();
  const sentenceCase = `${lowerCaseLabel
    .charAt(0)
    .toUpperCase()}${lowerCaseLabel.slice(1)}`;
  return sentenceCase;
};

export const getBadgeStyles = (shopData?: ShopData): React.CSSProperties => ({
  backgroundColor: shopData?.sales_shop_color,
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflowX: 'hidden',
  color: shopData?.sales_shop_text_color,
});

export const formatPercentage = (
  numerator: number,
  denominator: number,
): string => {
  return (Math.round((numerator / denominator) * 10000) / 100).toFixed(2);
};

export const dateRangeLabel = ({
  selectedDateId,
  numberOfDays,
}: {
  selectedDateId: string | null;
  numberOfDays: number;
}): string => {
  return selectedDateId === 'today'
    ? 'today'
    : `in the last ${numberOfDays} days`;
};

/**
 * Ensures HAS_SUMMARY and NO_SUMMARY are mutually exclusive.
 * If neither are present, defaults to HAS_SUMMARY.
 */
export const normalizeFilters = (filters: string[]): string[] => {
  if (filters.includes(NO_SUMMARY)) {
    return filters.filter((filter) => filter !== HAS_SUMMARY);
  }
  if (!filters.includes(HAS_SUMMARY)) {
    return [...filters, HAS_SUMMARY];
  }
  return filters;
};
