import { APP_LAYOUT_CSS_SELECTORS } from 'constants/constants';
import { useFullScreenEditor } from 'controllers/contexts/fullScreenEditor';
import { useState } from 'react';

const useAppLayout = () => {
  const [isFullScreenEditor, , isExperimentalViewportHeight] =
    useFullScreenEditor();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  /* These were implemented at one point and other components make use of them.
  Ideally, we would pass state down to those components rather than selectors.
  */
  const appLayoutCssClasses = `${
    isSidebarOpen ? APP_LAYOUT_CSS_SELECTORS.SIDEBAR_OPEN : ''
  } ${
    isFullScreenEditor ? APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR : ''
  }`.replace(/\./g, '');

  return {
    appLayoutCssClasses,
    isExperimentalViewportHeight,
    isFullScreenEditor,
    isSidebarOpen,
    setIsSidebarOpen,
  };
};

export default useAppLayout;
