import { useGetUserProfile } from 'components/account/AccountView/users/hooks/useUsers';
import AdminShopDropdown from 'components/admin/AdminShopDropdown';
import { PS_PAY } from 'components/admin/utils/feature-flags';
import NavMenu from 'components/navigation/NavMenu';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import { api } from 'controllers/network/apiClient';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useMenuConfig } from './hooks/useMenuConfig';

const getPageLink = () => {
  let link = window.location.href;

  if (link.includes('?')) {
    link = link.substring(0, link.indexOf('?'));
  }
  if (link.includes('#')) {
    link = link.substring(0, link.indexOf('#'));
  }

  const splitLink = link.split('/');
  const slicedLink = splitLink.slice(3);

  return `/${slicedLink.join('/')}`;
};

export const customStyles = {
  menu: (provided) => ({
    ...provided,
    background: 'var(--surface-bkg-color)',
    boxShadow: 'var(--box-shadow-small)',
    margin: 'var(--spacing-1) 0',
    borderRadius: 'var(--border-radius-x-small)',
    border: '1px solid var(--border-color)',
  }),
  control: (provided) => ({
    ...provided,
    minHeight: 'var(--spacing-4)',
    height: 'var(--spacing-4)',
    boxShadow: null,
    borderRadius: 'var(--border-radius-x-small)',
    background: 'var(--main-bkg-color)',
    color: 'var(--text-color)',
    border: '1px solid var(--border-color)',
    font: 'var(--body-text-x-small)',
    cursor: 'pointer',
  }),
  menuList: (provided) => ({
    ...provided,
    padding: 'var(--spacing-1)',
  }),
  valueContainer: (provided) => ({
    ...provided,
    height: 'var(--spacing-4)',
    padding: '3px 9px',
  }),
  input: (provided) => ({
    ...provided,
    margin: '0',
    color: 'var(--text-color)',
  }),
  indicatorSeparator: () => ({
    display: 'none',
  }),
  indicatorsContainer: (provided) => ({
    ...provided,
    height: 'var(--spacing-4)',
    font: 'var(--body-text-x-small)',
  }),
  option: (provided, state) => ({
    ...provided,
    font: 'var(--body-text-x-small)',
    padding: 'var(--spacing-1) 9px',
    borderRadius: 'var(--border-radius-small)',
    cursor: 'pointer',
    background: state.isFocused ? 'var(--link-color)' : 'transparent',
    color: state.isFocused ? 'var(--text-color-inverse)' : 'var(--text-color)',
    '&:hover': {
      background: 'var(--dropdown-bkg-hover-color)',
      color: 'var(--link-color)',
    },
  }),
};

const SidebarNavigation = ({
  changeAppState,
  logOut,
  user,
  isFullScreenEditor,
  isSidebarNavigationOpen,
  setIsSidebarNavigationOpen,
  ...props
}) => {
  const pageLink = getPageLink();
  const [logoURL, setLogoURL] = useState();
  const { hasLabsFlag } = usePSLabs();
  const { data: userProfile } = useGetUserProfile();
  const menuConfig = useMenuConfig();

  useEffect(async () => {
    if (!hasLabsFlag(PS_PAY)) return;
    const branding = await api.get('/v2/pspay/branding');

    const logo = branding?.logo_url;
    if (logo !== '') setLogoURL(logo);
  }, [setLogoURL]);

  return (
    <NavMenu
      menuConfig={menuConfig}
      isFullScreenEditor={isFullScreenEditor}
      isSidebarNavigationOpen={isSidebarNavigationOpen}
      setIsSidebarNavigationOpen={setIsSidebarNavigationOpen}
      pageLink={pageLink}
      logOut={logOut}
      accountLogoURL={logoURL}
      userProfile={userProfile}
      user={user}
      changeAppState={changeAppState}
      SelectShop={
        <AdminShopDropdown
          getUserInfo={props.getUserInfo}
          user={user}
          shops={props.shops}
          noCSView
          selectStyles={customStyles}
          placeholder="Switch Shops"
        />
      }
    />
  );
};

SidebarNavigation.propTypes = {
  changeAppState: PropTypes.func.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  shops: PropTypes.array.isRequired,
  logOut: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  isFullScreenEditor: PropTypes.bool.isRequired,
  isSidebarNavigationOpen: PropTypes.bool.isRequired,
  setIsSidebarNavigationOpen: PropTypes.func.isRequired,
};

export default SidebarNavigation;
