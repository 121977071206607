/* eslint-disable react/no-did-update-set-state */
/* eslint-disable react/static-property-placement */
/* eslint-disable prefer-destructuring */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable func-names */
/* eslint-disable react/no-unused-state */
/* eslint-disable react/sort-comp */
/* eslint-disable no-restricted-globals */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-named-as-default */
/* eslint-disable no-alert */
// Button to let admins turn on extra keywords for stores.
/* eslint-disable react/require-default-props */
/* eslint-disable react/prop-types */
/* eslint-disable no-param-reassign */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable import/extensions */
import Utils from 'controllers/utils';
import { isEmpty } from 'lodash';
import React from 'react';
import ReactDOM from 'react-dom';
import FontAwesome from 'react-fontawesome';
import { Link } from 'react-router-dom';
import AsyncSelect from 'react-select/async';
import { UserContext } from '../../controllers/contexts';
import Events from '../../controllers/events';
import Requests from '../../controllers/network/network_requests';
import { COMMON_EVENT_NAMES, logEvent } from '../../utils/events';

const initialState = {
  switching: false,
  shops: null,
  search: '',
  selectStyles: undefined,
  container: null,
  placeholder: 'Change shop',
};

class AdminShopDropdown extends React.Component {
  constructor(props) {
    super(props);
    this.state = { ...initialState, shops: props.shops || null };
    this.switchShop = this.switchShop.bind(this);

    this.getAllShops = this.getAllShops.bind(this);
  }

  static contextType = UserContext;

  componentDidMount() {
    this.getAllShops();
  }

  componentDidUpdate() {
    if (
      (!this.state.shops && this.props.shops) ||
      (this.state.shops &&
        this.props.shops &&
        this.state.shops.length !== this.props.shops.length)
    ) {
      this.setState({
        shops: this.props.shops,
      });
    }
  }

  componentWillUnmount() {
    if (this.state.container) document.body.removeChild(this.state.container);
  }

  async getAllShops(inputValue = '') {
    const { shops } = this.state;
    const limit = 30;

    if (shops && !inputValue) return shops;

    const result = await Requests.get(`/admin/get_all_shops`, {
      search: inputValue,
      limit,
    });

    if (!result || isEmpty(result.shops)) return;

    this.setState({
      shops: result.shops,
      search: inputValue,
    });

    return result.shops;
  }

  switchShop(obj) {
    this.setState(
      {
        switching: true,
      },
      () => {
        const data = {
          new_shop_id: obj.value,
        };
        Requests.post('/admin/change_shop', data).then(
          function (result) {
            this.setState({
              switching: false,
            });
            Utils.saveAuthTokens({
              accessToken: result.access_token,
              refreshToken: result.refresh_token,
            });
            window.location.href = '/dashboard';

            logEvent(COMMON_EVENT_NAMES.SWITCHED_SHOP, {
              from_shop_id: result.old_shop_id,
              to_shop_id: result.new_shop_id,
            });
          }.bind(this),
        );
      },
    );
  }

  showLoading() {
    if (!this.state.container) {
      this.setState(
        {
          container: document.createElement('div'),
        },
        () => {
          ReactDOM.render(
            <div id="switching-shops-outer-container">
              <div id="switching-shops-inner-container">
                <FontAwesome name="circle-o-notch" spin />
                <span className="gimme-a-lil-room">Loading...</span>
              </div>
            </div>,
            document.body.appendChild(this.state.container),
          );
        },
      );
    }
  }

  hideLoading() {
    if (this.state.container)
      ReactDOM.unmountComponentAtNode(this.state.container);
  }

  render() {
    if (
      isEmpty(this.state.search) &&
      (!this.state.shops || this.state.shops.length < 2)
    )
      return null;

    return (
      <>
        {this.state.switching ? this.showLoading() : this.hideLoading()}
        <div className="filter-inline">
          <div className="filter-inline">
            <AsyncSelect
              name="Shops"
              className="admin-shop-drop"
              classNamePrefix="admin-shop-drop-inner"
              cacheOptions
              loadOptions={this.getAllShops}
              onChange={(val) => this.switchShop(val)}
              defaultOptions
              placeholder={this.state.placeholder}
              styles={this.props.selectStyles}
            />
          </div>
          {this.props.user &&
          !this.props.noCSView &&
          this.props.user.is_admin ? (
            <div className="filter-inline" id="admin-review-icon-container">
              <Link
                to="/account-overview"
                onClick={() => {
                  Events.track('Account Review Clicked');
                }}
              >
                <FontAwesome
                  name="check-square-o"
                  className="text-primary"
                  id="admin-review-icon"
                />
              </Link>
            </div>
          ) : null}
        </div>
      </>
    );
  }
}

export default AdminShopDropdown;
