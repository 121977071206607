import { captureException } from '@sentry/browser';
import {
  BASE_FLOWS_URL,
  BASE_FLOW_BUILDER_API_URL,
  DEFAULT_MESSAGE_CONTENT,
  DEFAULT_VALUE_OPTIONS_GROUP_LABEL,
  FlowModes,
  FlowStatuses,
  FlowTypes,
} from 'components/flowBuilder/constants';
import { SUBSCRIBER_CLICKED_LINK_EVENT_TYPE } from 'components/flowBuilder/constants/events';
import { dynamicImageTagsDict } from 'components/flowBuilder/constants/tags';
import type {
  ABSplitActionBranch,
  CancelEvent,
  ConditionCase,
  DetailedFlow,
  DraftFlow,
  EventField,
  Flow,
  FlowMode,
  FlowStatus,
  FlowType,
  GroupedValueOption,
  TextToBuyBranch,
  TextToBuyProduct,
  TriggerEvent,
  ValueOption,
} from 'components/flowBuilder/types';
import {
  ABSplitActionParams,
  Action,
  DynamicSplitActionParams,
  EndAction,
  OptimizedABSplitActionParams,
  SplitAction,
  StaticActionType,
  SubscriberAttributeSplitActionParams,
  TextToBuyActionParams,
  TriggerEventSplitActionParams,
  WaitForEventSplitActionParams,
} from 'components/flowBuilder/types/actions';
import {
  isABSplitAction,
  isDynamicSplitAction,
  isEndAction,
  isOptimizedABSplitAction,
  isSendMessageAction,
  isSplitAction,
  isStaticActionsType,
  isTextToBuyAction,
  isWaitForEventSplitAction,
} from 'components/flowBuilder/types/actions/typeGuards';
import {
  isClonedFlowResponse,
  isDetailedFlow,
  isFlow,
} from 'components/flowBuilder/types/typeGuards';
import { api } from 'controllers/network/apiClient';
import { cloneDeep, omit } from 'lodash';
import { DateTime } from 'luxon';
import { stringify } from 'query-string';
import { v4 as uuidv4, v4 } from 'uuid';
import {
  createEndAction,
  createSendMessageAction,
  mergeActionParams,
} from './steps';
import { convertLegacyMergeTags } from './tags';

interface ApiPayloadFlow extends Omit<Flow, 'cancelEvents' | 'triggerEvents'> {
  cancelEvents: CancelEvent[];
  triggerEvents: TriggerEvent[];
}

interface DraftFlowCreationParams {
  mode?: FlowMode;
  type?: FlowType;
}

export const createDraftFlow = (
  params?: DraftFlowCreationParams,
): DraftFlow => {
  const mode = params?.mode ?? FlowModes.ADVANCED;
  const type = params?.type ?? FlowTypes.CAMPAIGN;

  return {
    actions: [],
    description: '',
    name: `${DateTime.local().toLocaleString(DateTime.DATE_SHORT)} | Flow`,
    segmentIds: [],
    excludeSegmentIds: [],
    start: '',
    status: FlowStatuses.DRAFT,
    type,
    mode,
    activatedAt: null,
    scheduledFor: null,
    triggerEvents: [],
    cancelEvents: [],
    safeSend: false,
    userFilterCriteria: [],
    triggerFilters: [],
    templatedUserFilterCriteria: [],
    transactional: false,
    versionDescription: '',
  };
};

export const createDraftFlowWithEndAction = (
  params?: DraftFlowCreationParams,
): DraftFlow => {
  const draftFlow = createDraftFlow(params);
  const initialEndAction = createEndAction();

  return {
    ...draftFlow,
    actions: [initialEndAction],
    start: initialEndAction.guid,
  };
};

/**
 * Creates a draft flow with a send message action as the first action.
 * Defaults to also creating an end action
 *
 */
export const createDraftFlowWithSendMessageAction = (
  params?: DraftFlowCreationParams,
): DraftFlow => {
  const draftFlow = createDraftFlow(params);
  const initialEndAction = createEndAction();
  const initialSendMessageAction = {
    ...createSendMessageAction(),
    next: [initialEndAction.guid],
  };

  return {
    ...draftFlow,
    actions: [initialSendMessageAction, initialEndAction],
    start: initialSendMessageAction.guid,
  };
};

const createApiPayloadFlowFromFlow = (flow: Flow) => {
  const apiFlow: ApiPayloadFlow = {
    guid: flow.guid ?? uuidv4(),
    name: flow.name,
    description: flow.description,
    segmentIds: flow.segmentIds,
    excludeSegmentIds: flow.excludeSegmentIds,
    actions: flow.actions,
    start: flow.start,
    status: flow.status,
    templateId: flow.templateId,
    type: flow.type,
    mode: flow.mode,
    activatedAt: flow.activatedAt,
    scheduledFor: flow.scheduledFor,
    triggerEvents: flow.triggerEvents,
    cancelEvents: flow.cancelEvents,
    safeSend: flow.safeSend,
    userFilterCriteria: flow.userFilterCriteria,
    triggerFilters: flow.triggerFilters,
    templatedUserFilterCriteria: flow.templatedUserFilterCriteria,
    subscriptionDurationLimit: flow.subscriptionDurationLimit,
    transactional: flow.transactional,
    versionDescription: flow.versionDescription,
  };

  return apiFlow;
};

const hydrateFlowActions = (actions: Action[]) => {
  return actions.map((action) => {
    if (isTextToBuyAction(action)) {
      return {
        ...action,
        params: {
          ...action.params,
          products: action.params.products.map((product) => {
            return {
              ...product,
              guid: v4(),
            };
          }),
        },
      };
    }

    return action;
  });
};

export const cleanTextToBuyProducts = (
  products: TextToBuyProduct[],
): TextToBuyProduct[] => {
  return products.map((product) => {
    return omit(product, 'guid');
  });
};

const cleanDraftFlowActions = (actions: Action[]) => {
  return actions.map((action) => {
    if (isTextToBuyAction(action)) {
      return {
        ...action,
        params: {
          ...action.params,
          products: cleanTextToBuyProducts(action.params.products),
        },
      };
    }

    return action;
  });
};

const createFlowFromDraftFlow = (
  draftFlow: DraftFlow,
  versionDescription?: string,
) => {
  const flow: Flow = {
    guid: draftFlow.guid ?? uuidv4(),
    name: draftFlow.name,
    description: draftFlow.description,
    segmentIds: draftFlow.segmentIds,
    excludeSegmentIds: draftFlow.excludeSegmentIds,
    actions: cleanDraftFlowActions(draftFlow.actions),
    start: draftFlow.start,
    status: draftFlow.status,
    templateId: draftFlow.templateId,
    type: draftFlow.type,
    mode: draftFlow.mode,
    activatedAt: draftFlow.activatedAt,
    scheduledFor: draftFlow.scheduledFor,
    triggerEvents: draftFlow.triggerEvents,
    cancelEvents: draftFlow.cancelEvents,
    safeSend: draftFlow.safeSend,
    userFilterCriteria: draftFlow.userFilterCriteria,
    triggerFilters: draftFlow.triggerFilters,
    templatedUserFilterCriteria: draftFlow.templatedUserFilterCriteria,
    subscriptionDurationLimit: draftFlow.subscriptionDurationLimit,
    transactional: draftFlow.transactional,
    versionDescription: versionDescription || '',
  };

  return flow;
};

export const createFlow = (): Flow => {
  return createFlowFromDraftFlow(createDraftFlow());
};

interface RawFlowsResponse {
  flows: unknown[];
  totalPages: number;
}

const isRawFlowsResponse = (
  response: unknown,
): response is RawFlowsResponse => {
  const responseValue = response as FlowsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!Array.isArray(responseValue.flows)) return false;
  if (typeof responseValue.totalPages !== 'number') return false;

  return true;
};

interface FlowsResponse extends RawFlowsResponse {
  flows: Flow[];
}

export const fetchEventFields = async (
  eventType: string,
): Promise<EventField[]> => {
  const { fields } = await api.get<{ fields: EventField[] }>(
    `/v2/flowbuilder/events/${eventType}`,
  );

  return fields;
};

export const SortColumns = {
  CREATED_AT: 'createdAt',
  NAME: 'name',
  SCHEDULED_FOR: 'scheduledFor',
  UPDATED_AT: 'updatedAt',
} as const;

export type SortColumn = typeof SortColumns[keyof typeof SortColumns];

export const SortOrders = {
  ASC: 'asc',
  DESC: 'desc',
} as const;

export type SortOrder = typeof SortOrders[keyof typeof SortOrders];

export interface FlowsParams {
  name?: string;
  page?: number;
  perPage?: number;
  sortColumn?: SortColumn;
  sortOrder?: SortOrder;
  status?: FlowStatus | null;
  type?: FlowType;
}

export const fetchFlows = async ({
  name,
  page,
  perPage,
  sortColumn,
  sortOrder,
  status,
  type,
}: FlowsParams): Promise<FlowsResponse> => {
  const sort = sortColumn && sortOrder ? `${sortColumn}__${sortOrder}` : null;
  const queryParams = {
    name__contains: name,
    page,
    per_page: perPage,
    sort,
    status__eq: status,
    type__eq: type,
  };
  const queryString = stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  });
  const rawFlowsResponse: unknown = await api.get(
    `${BASE_FLOWS_URL}?${queryString}`,
  );

  if (!isRawFlowsResponse(rawFlowsResponse))
    throw new Error('Unexpected Flows response');

  const validFlows: Flow[] = [];
  const invalidFlows: unknown[] = [];

  rawFlowsResponse.flows.forEach((flow) => {
    if (isFlow(flow)) validFlows.push(flow);
    else invalidFlows.push(flow);
  });

  if (invalidFlows.length)
    captureException(new Error('Invalid flows found'), {
      extra: { invalidFlows },
    });

  const flowsResponse: FlowsResponse = {
    ...rawFlowsResponse,
    flows: validFlows,
  };

  return flowsResponse;
};

const isDetailedFlowsResponse = (
  response: unknown,
): response is DetailedFlowsResponse => {
  const responseValue = response as DetailedFlowsResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!Array.isArray(responseValue.flows)) return false;
  if (responseValue.flows.some((flow) => !isDetailedFlow(flow))) return false;

  return true;
};

export interface DetailedFlowsResponse {
  flows: DetailedFlow[];
}

interface DetailedFlowsParams extends FlowsParams {
  triggerEvent?: string;
}

export const fetchDetailedFlows = async ({
  name,
  page,
  perPage,
  sortColumn,
  sortOrder,
  status,
  triggerEvent,
  type,
}: DetailedFlowsParams): Promise<DetailedFlow[]> => {
  const sort = sortColumn && sortOrder ? `${sortColumn}__${sortOrder}` : null;
  const queryParams = {
    name__contains: name,
    page,
    per_page: perPage,
    sort,
    status__eq: status,
    triggerEvent__eq: triggerEvent,
    type__eq: type,
  };
  const queryString = stringify(queryParams, {
    skipEmptyString: true,
    skipNull: true,
  });
  const detailedFlowsResponse: unknown = await api.get(
    `${BASE_FLOWS_URL}lookup/detailed/?${queryString}`,
  );

  if (!isDetailedFlowsResponse(detailedFlowsResponse))
    throw new Error('Unexpected detailed Flows response');

  return detailedFlowsResponse.flows;
};

const getFirstEndActionInFlow = (
  firstAction: Action,
  actionMap: Map<string, Action>,
) => {
  let endAction: EndAction | undefined;
  let currentAction: Action | undefined = firstAction;

  while (currentAction) {
    if (isEndAction(currentAction)) endAction = currentAction;
    const nextActionGuid: string | undefined = currentAction.next[0];
    const nextAction: Action | undefined = nextActionGuid
      ? actionMap.get(nextActionGuid)
      : undefined;
    currentAction = nextAction;
  }

  return endAction;
};

// If 'action' is pointing to an EndAction that should be removed, it deletes the EndAction and mutates the action's 'next'/'params' properties
const replaceUnnecessaryEndActionsInAction = (
  action: Action,
  endAction: EndAction,
  actionMap: Map<string, Action>,
) => {
  action.next.forEach((nextActionGuid, i) => {
    const nextAction = actionMap.get(nextActionGuid);
    if (
      !nextAction || // If the action doesn't exist it means it was an EndAction and we've already deleted it
      (isEndAction(nextAction) && nextActionGuid !== endAction.guid)
    ) {
      actionMap.delete(nextActionGuid);
      // eslint-disable-next-line no-param-reassign
      action.next[i] = endAction.guid;
      // If it's a split action we also need to mutate its 'params'
      if (isSplitAction(action)) {
        action.params.cases.forEach((branch) => {
          if (branch.action_guid === nextActionGuid)
            // eslint-disable-next-line no-param-reassign
            branch.action_guid = endAction.guid;
        });
      }
    } else {
      replaceUnnecessaryEndActionsInAction(nextAction, endAction, actionMap);
    }
  });
};

// Mutates the flow's actions by retaining a single EndAction
const replaceUnnecessaryEndActionsInFlow = (flow: DraftFlow) => {
  const actionMap = new Map(
    flow.actions.map((action) => [action.guid, action]),
  );

  const firstAction = actionMap.get(flow.start);
  if (!firstAction) return;

  const endAction = getFirstEndActionInFlow(firstAction, actionMap);
  if (!endAction) return;

  replaceUnnecessaryEndActionsInAction(firstAction, endAction, actionMap);

  // eslint-disable-next-line no-param-reassign
  flow.actions = Array.from(actionMap.values());
};

export const ensureFlowActionsReconvene = (flow: DraftFlow): void => {
  if (flow.actions.filter(isEndAction).length < 2) return;
  replaceUnnecessaryEndActionsInFlow(flow);
};

export const fetchFlow = async (
  guid: string,
  version?: number | null,
): Promise<Flow> => {
  let url = `${BASE_FLOWS_URL}${guid}/`;

  if (version) {
    url += `?version=${version}`;
  }

  const flow: unknown = await api.get(url);
  if (!isFlow(flow)) throw new Error('Unexpected Flow response');

  // These methods each mutate the flow
  ensureFlowActionsReconvene(flow);
  convertLegacyMergeTags(flow);

  return {
    ...flow,
    actions: hydrateFlowActions(flow.actions),
  };
};

export const deleteFlow = async (guid: string): Promise<void> => {
  await api.delete(`${BASE_FLOWS_URL}${guid}/`);
};

const transformSplitActionParams = <T extends SplitAction>(
  action: T,
  guidsDict: {
    [key: string]: string;
  },
): T['params'] => {
  if (isABSplitAction(action)) {
    const newABSplitActionParams: ABSplitActionParams = { cases: [] };

    action.params.cases.forEach((branch, i) => {
      const newBranch: ABSplitActionBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        conditions: branch.conditions,
      };
      newABSplitActionParams.cases.push(newBranch);
    });
    return newABSplitActionParams;
  }

  if (isOptimizedABSplitAction(action)) {
    const newOptimizedABSplitActionParams: OptimizedABSplitActionParams = {
      ...action.params,
      cases: [],
    };

    action.params.cases.forEach((branch, i) => {
      const newBranch: ABSplitActionBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        conditions: branch.conditions,
      };
      newOptimizedABSplitActionParams.cases.push(newBranch);
    });
    return newOptimizedABSplitActionParams;
  }

  if (isWaitForEventSplitAction(action)) {
    const newWaitForEventSplitActionParams: WaitForEventSplitActionParams = {
      ...action.params,
      cases: [],
    };
    action.params.cases.forEach((branch, i) => {
      const newConditions = branch.conditions.map((condition) => {
        return {
          ...condition,
          value:
            action.params.event_type === SUBSCRIBER_CLICKED_LINK_EVENT_TYPE
              ? '{{flow.flow_collection_guid}}'
              : condition.value,
        };
      });

      const newBranch: ConditionCase = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        conditions: newConditions,
      };
      newWaitForEventSplitActionParams.cases.push(newBranch);
    });

    return newWaitForEventSplitActionParams;
  }

  if (isTextToBuyAction(action)) {
    const newTextToBuyActionParams: TextToBuyActionParams = {
      ...action.params,
      cases: [],
    };

    action.params.cases.forEach((branch, i) => {
      const newBranch: TextToBuyBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        event: branch.event,
      };

      newTextToBuyActionParams.cases.push(newBranch);
    });

    return newTextToBuyActionParams;
  }

  if (isDynamicSplitAction(action)) {
    const newDynamicSplitActionParams: DynamicSplitActionParams = {
      ...action.params,
      cases: [],
    };

    action.params.cases.forEach((branch, i) => {
      const newBranch = {
        action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
        conditions: branch.conditions,
      };

      newDynamicSplitActionParams.cases.push(newBranch);
    });

    return newDynamicSplitActionParams;
  }

  const newSplitActionParams:
    | TriggerEventSplitActionParams
    | SubscriberAttributeSplitActionParams = {
    ...action.params,
    cases: [],
  };

  action.params.cases.forEach((branch, i) => {
    const newBranch: ConditionCase = {
      action_guid: guidsDict[branch.action_guid] ?? guidsDict[action.next[i]],
      conditions: branch.conditions,
    };
    newSplitActionParams.cases.push(newBranch);
  });

  return newSplitActionParams;
};

export const createFlowCopy = (
  flow: DraftFlow,
  nameAppendix = '',
): DraftFlow => {
  const actionsToClone = flow.actions;
  const guidsDict: {
    [key: string]: string;
  } = {};

  actionsToClone.forEach((action) => {
    guidsDict[action.guid] = uuidv4();
  });

  const newActions = actionsToClone.map((action): Action => {
    const newAction = isSplitAction(action)
      ? mergeActionParams(action, transformSplitActionParams(action, guidsDict))
      : action;
    newAction.guid = guidsDict[action.guid];
    newAction.next = action.next.map((nextGuid) => guidsDict[nextGuid]);
    return newAction;
  });

  return {
    ...cloneDeep(flow),
    guid: undefined,
    actions: newActions,
    name: `${flow.name}${nameAppendix}`,
    start: guidsDict[flow.start],
  };
};

/**
 * Return the nodes from the new flow which differ.
 *
 * Note: If oldFlow is not provided, the entire newFlow will be returned.
 *
 * @param newFlow which may contain updated values
 * @param [oldFlow]
 *
 * @returns partial version of newFlow which differs from old
 */
export const getFlowDiff = (
  newFlow: Partial<DraftFlow>,
  oldFlow?: Partial<DraftFlow>,
): Partial<DraftFlow> => {
  if (!oldFlow) {
    return newFlow;
  }

  const allKeys = new Set([
    ...Object.keys(oldFlow),
    ...Object.keys(newFlow),
  ]) as Set<keyof DraftFlow>;

  const changedFields: Partial<DraftFlow> = {};

  allKeys.forEach((key) => {
    if (JSON.stringify(oldFlow[key]) !== JSON.stringify(newFlow[key])) {
      changedFields[key] = newFlow[key] as any;
    }
  });

  return changedFields;
};

/**
 * The list of fields which allow for a partial PATCH flow update.
 * Any fields that fall outside of this list will cause a full PUT.
 */
const PATCH_FLOW_FIELDS: Array<keyof DraftFlow> = ['name', 'description'];

/**
 * Returns whether a flow diff should warrant using a full or partial update
 * as its mechanism for saving.
 *
 * @param diff the object of changes between the old and new version
 *
 * @returns true if full update, false if partial
 */
export const shouldFlowDiffPerformFullUpdate = (
  diff: Partial<DraftFlow>,
): boolean => {
  return Object.keys(diff).some(
    (key) => !PATCH_FLOW_FIELDS.includes(key as keyof DraftFlow),
  );
};

/**
 * Reused options between all API calls.
 */
const SAVE_DRAFT_FLOW_API_OPTIONS = {
  throwErrorAsString: false,
};

export const saveDraftFlow = async (
  draftFlow: DraftFlow,
  initialDraftFlow?: DraftFlow,
  versionDescription?: string,
): Promise<Flow> => {
  const flow = createFlowFromDraftFlow(draftFlow, versionDescription);
  const apiPayloadFlow = createApiPayloadFlowFromFlow(flow);

  let response;
  if (draftFlow.guid) {
    const flowApiUrl = `${BASE_FLOWS_URL}${draftFlow.guid}/`;

    const flowDiff = getFlowDiff(draftFlow, initialDraftFlow);
    const performFullUpdate = shouldFlowDiffPerformFullUpdate(flowDiff);

    if (performFullUpdate) {
      response = await api.put(
        flowApiUrl,
        apiPayloadFlow,
        SAVE_DRAFT_FLOW_API_OPTIONS,
      );
    } else {
      response = await api.patch(
        flowApiUrl,
        flowDiff,
        SAVE_DRAFT_FLOW_API_OPTIONS,
      );
    }
  } else {
    // Creating a new flow...
    response = await api.post(
      BASE_FLOWS_URL,
      apiPayloadFlow,
      SAVE_DRAFT_FLOW_API_OPTIONS,
    );
  }

  // Merge the provided version and the backend response version, as some
  // fields may be missing, such as `status`.
  // FIXME: [FLOW-571] Remove this merging of objects to clean up the logic.
  const mergedFlow = {
    ...draftFlow,
    ...response,
    actions: hydrateFlowActions(response.actions),
  };

  if (!isFlow(mergedFlow)) {
    throw new Error('Invalid Flow state after save.');
  }

  return mergedFlow;
};

export const restoreFlowVersion = async (
  flowGuid: string,
  version: number,
): Promise<Flow> => {
  try {
    const response = await api.post(
      `${BASE_FLOWS_URL}${flowGuid}/restore/?version=${version}`,
    );

    return response;
  } catch (e) {
    throw new Error('Unable to restore flow version');
  }
};

export const cloneFlowViaEndpoint = async (guid: string): Promise<string> => {
  const clonedResponse = await api.post(`${BASE_FLOWS_URL}${guid}/clone/`);
  if (!isClonedFlowResponse(clonedResponse))
    throw new Error('Unexpected Flow response');
  return clonedResponse.guid;
};

export const unscheduleFlow = async (guid: string): Promise<void> => {
  await api.put(`${BASE_FLOWS_URL}cancel/${guid}/`);
};

export const unscheduleFlowDeactivation = async (
  guid: string,
): Promise<void> => {
  await api.put(`${BASE_FLOWS_URL}${guid}/schedule-deactivation/cancel/`);
};

interface ApiActionType {
  type: string;
  helpText: string;
  category: string;
}

const isApiActionType = (actionType: unknown): actionType is ApiActionType => {
  const actionTypeValue = actionType as ApiActionType;
  if (!actionTypeValue || typeof actionTypeValue !== 'object') return false;
  if (typeof actionTypeValue.type !== 'string') return false;
  if (typeof actionTypeValue.helpText !== 'string') return false;
  if (typeof actionTypeValue.category !== 'string') return false;
  return true;
};

interface ActionTypesResponse {
  actions: ApiActionType[];
}

const isActionTypesResponse = (
  response: unknown,
): response is ActionTypesResponse => {
  const responseValue = response as ActionTypesResponse;

  if (!responseValue || typeof responseValue !== 'object') return false;
  if (!responseValue.actions || !Array.isArray(responseValue.actions))
    return false;
  if (responseValue.actions.some((action) => !isApiActionType(action)))
    return false;

  return true;
};

export const fetchActionTypes = async (): Promise<StaticActionType[]> => {
  const actionTypesResponse: unknown = await api.get(
    `${BASE_FLOW_BUILDER_API_URL}actions/`,
  );

  if (!isActionTypesResponse(actionTypesResponse))
    throw new Error('Unexpected Action Types response');
  return actionTypesResponse.actions.reduce(
    (actionTypes: StaticActionType[], action: ApiActionType) => {
      if (isStaticActionsType(action.type))
        return [
          ...actionTypes,
          {
            name: action.type,
            helpText: action.helpText,
            category: action.category,
          },
        ];
      return actionTypes;
    },
    [],
  );
};

/**
 * Determines if a string is above or a below a certain length
 * If no min value, will assume no min constraint
 * If no max value, will assume no max constraint
 * Will also check if the string is within the range, which will return true if no min or max are provided.
 * Example:
 * parseTextLength({ max: 1000, min: 1, text: 'hello' }) => { isAboveMin: true, isWithinRange: true, isBelowMax: true }
 * parseTextLength({ max: 1000, min: 1, text: '' }) => { isAboveMin: false, isWithinRange: false, isBelowMax: true }
 */
export const parseTextLength = ({
  max,
  min,
  text,
}: {
  max?: number;
  min?: number;
  text: string;
}): {
  isAboveMin: boolean;
  isWithinRange: boolean;
  isBelowMax: boolean;
} => {
  const isAboveMin = !min || text.length >= min;
  const isBelowMax = !max || text.length <= max;
  return { isAboveMin, isBelowMax, isWithinRange: isAboveMin && isBelowMax };
};

export const parseFlowMode = (mode: FlowMode | undefined) => {
  return {
    isAdvanced: mode === FlowModes.ADVANCED,
    isBasic: mode === FlowModes.BASIC,
  };
};

export const parseFlowType = (type: FlowType | undefined) => {
  return {
    isAutomation: type === FlowTypes.AUTOMATION,
    isCampaign: type === FlowTypes.CAMPAIGN,
  };
};

export const parseFlowStatus = (status: FlowStatus | undefined) => {
  return {
    // Basic
    isActivationError: status === FlowStatuses.ACTIVATION_ERROR,
    isApprovalDenied: status === FlowStatuses.APPROVAL_DENIED,
    isApprovalPending: status === FlowStatuses.APPROVAL_PENDING,
    isCompleted: status === FlowStatuses.COMPLETED,
    isDraft: status === FlowStatuses.DRAFT,
    isDraining: status === FlowStatuses.DRAINING,
    isEnabled: status === FlowStatuses.ENABLED,
    isEnded: status === FlowStatuses.ENDED,
    isPreparing: status === FlowStatuses.PREPARING,
    isScheduled: status === FlowStatuses.SCHEDULED,
    // Combined
    isUnapproved:
      status === FlowStatuses.APPROVAL_DENIED ||
      status === FlowStatuses.APPROVAL_PENDING,
    isEditableCampaignStatus:
      status === FlowStatuses.APPROVAL_DENIED ||
      status === FlowStatuses.APPROVAL_PENDING ||
      status === FlowStatuses.DRAFT ||
      status === FlowStatuses.SCHEDULED,
  };
};

/**
 * Returns whether a flow's contents can be edited.
 *
 * NOTE: This function uses a hook, so it needs to be used in a component,
 * without any conditionals wrapping it.
 *
 * @param flow which is being viewed/validated
 * @returns true if editable, false otherwise
 */
export const flowIsEditable = (flow: DraftFlow): boolean => {
  const { isEditableCampaignStatus } = parseFlowStatus(flow.status);

  switch (flow.type) {
    case 'AUTOMATION':
      return true;
    case 'CAMPAIGN':
      return isEditableCampaignStatus;
    default:
      return false;
  }
};

const DYNAMIC_IMAGE_FLOW_TRIGGER_EVENT_TYPES =
  Object.keys(dynamicImageTagsDict);

/**
 * Returns whether a flow's current state would support dynamic images in
 * the messages it sends out.
 *
 * @param events which are being tested
 * @returns true if it supports dynamic images, false otherwise
 */
export const triggerEventsSupportDynamicImages = (
  events: TriggerEvent[],
): boolean => {
  const trigger = events[0];
  if (!trigger) {
    return false;
  }
  return DYNAMIC_IMAGE_FLOW_TRIGGER_EVENT_TYPES.includes(trigger.eventType);
};

/**
 * Returns array of [placeholder] text instances present in a given string.
 *
 * @param text the string which is being checked for placeholder content
 * @returns array of placeholder text instances, e.g. ["[NAME]", "[DISCOUNT CODE]"]
 */
export const getPlaceholders = (text: string): string[] => {
  const placeholders: string[] = [];

  for (let i = 0; i < text.length; i += 1) {
    // if we get to the opening braces for a merge tag, '{{'
    if (text[i] === '{' && text[i + 1] === '{') {
      // found end of merge tag
      const endIndex = text.indexOf('}}', i);

      if (endIndex === -1) {
        break;
      }

      // advance past this text because we don't want to check merge tags for []'s
      i = endIndex + 1;
    } else if (text[i] === '[') {
      // if we come to an opening bracket
      // found end of placeholder
      const endIndex = text.indexOf(']', i);

      if (endIndex === -1) {
        break;
      }

      placeholders.push(text.substring(i, endIndex + 1));
      // advance past this text because we don't need to check every character
      i = endIndex + 1;
    } else if (text.includes(DEFAULT_MESSAGE_CONTENT)) {
      // This is the default message for basic campaigns
      placeholders.push(DEFAULT_MESSAGE_CONTENT);
    }
  }

  return placeholders;
};

/**
 * Returns whether any of a flow's send message actions contain a template [placeholder].
 *
 * @param actions which are being validated
 * @returns true if flow contains a placeholder in any of its actions, false otherwise
 */
export const checkSendMessageActionsforPlaceholders = (
  actions: Action[],
): boolean => {
  return actions.some((action) => {
    return (
      isSendMessageAction(action) &&
      getPlaceholders(action.params.content).length
    );
  });
};

export const fetchSelectFlowValueOptions = async (
  inputValue: string,
): Promise<ValueOption[]> => {
  try {
    const detailedFlows = await fetchDetailedFlows({
      name: inputValue,
      perPage: 50,
    });
    return detailedFlows
      .sort((a, b) => a.name.localeCompare(b.name))
      .map((detailedFlow) => ({
        group: `${detailedFlow.type}S`,
        label: detailedFlow.name,
        value: detailedFlow.flow_collection_guid,
      }));
  } catch {
    return [];
  }
};

export const fetchSelectFlowValueOption = async (
  value: string,
): Promise<ValueOption | undefined> => {
  try {
    const flow = await fetchFlow(value);
    return {
      group: flow.type ? `${flow.type}S` : undefined,
      label: flow.name,
      value: flow.guid,
    };
  } catch {
    return undefined;
  }
};

export const fetchSelectedFlowValueOptions = async (
  value: string | string[],
): Promise<ValueOption[]> => {
  const guids = Array.isArray(value) ? value : [value];
  const promises = guids.map((guid) => {
    return fetchSelectFlowValueOption(guid);
  });
  const settledPromises = await Promise.allSettled(promises);
  const options = settledPromises.reduce<ValueOption[]>(
    (accOptions, settledPromise) => {
      if (settledPromise.status === 'fulfilled' && settledPromise.value)
        return [...accOptions, settledPromise.value];
      return accOptions;
    },
    [],
  );
  return options;
};

// Groups value options so they're rendered under a label in the dropdown
export const groupValueOptions = (valueOptions: ValueOption[]) => {
  const valueOptionsMap = new Map<string, ValueOption[]>();
  valueOptionsMap.set(DEFAULT_VALUE_OPTIONS_GROUP_LABEL, []);

  valueOptions.forEach((valueOption) => {
    const group = valueOption.group ?? DEFAULT_VALUE_OPTIONS_GROUP_LABEL;
    const valueOptions = valueOptionsMap.get(group) || [];
    valueOptions.push(valueOption);
    valueOptionsMap.set(group, valueOptions);
  });

  const groupedValueOptions: GroupedValueOption[] = [];
  valueOptionsMap.forEach((options, label) => {
    groupedValueOptions.push({ label, options });
  });

  return groupedValueOptions.sort((a, b) => a.label.localeCompare(b.label));
};

/*
  Throw an error if we detect that the provided flow has either missing or orphaned actions.
  Otherwise, return undefined.
*/
export const validateFlowStructure = (flow: Flow) => {
  let errorString = `Malformed flow ${flow.guid}: `;
  const actionGuids = [];
  const nexts = [flow.start];

  for (const action of flow.actions) {
    actionGuids.push(action.guid);
    nexts.push(...action.next);
  }

  actionGuids.sort();
  nexts.sort();

  const orphanActions = [];
  for (const action of actionGuids) {
    if (!nexts.includes(action)) orphanActions.push(action);
  }

  const missingActions = [];
  for (const next of nexts) {
    if (!actionGuids.includes(next)) missingActions.push(next);
  }

  const hasOrphanedActions = !!orphanActions.length;
  const hasMissingActions = !!missingActions.length;

  if (hasOrphanedActions) {
    errorString += `Orphaned actions [${orphanActions
      .map((actionGuid) => actionGuid)
      .join(', ')}]. `;
  }

  if (hasMissingActions) {
    errorString += `Missing actions [${missingActions
      .map((actionGuid) => actionGuid)
      .join(', ')}]. `;
  }

  if (hasOrphanedActions || hasMissingActions) {
    console.error(errorString);
    throw new Error(errorString);
  }
};
