import React, { createContext, useContext, useState } from 'react';

/*
  TODO(Kameron Ahler): isExperimentalViewportHeight is experimental and being
  implemented fast within this context because conversations AI needs it asap.
  It needs follow up work and more testing before being used elsewhere. Follow
  up items:

  - Combine this context and hook with useAppLayout, consider combining
    banner-related logic into that hook at that time.

  - Return an object, not an array. I would have done this now, but it increases
    the scope quite a bit to do so.

  - Ensure that isExperimentalViewportHeight works with isFullscreenEditor, and
    at that time, I think it would make sense to refactor a single use state if
    possible, rather than two. This should be done in a non-breaking fashion so
    people can start to refactor their current implementations as they have
    time.

  - Consider whether hiding the banner when using isExperimentalViewportHeight
    makes sense, we do this imperatively when using isFullScreenEditor.

  - Run this past design just so they're aware
*/

type FullScreenEditorProviderProps = {
  children: React.ReactNode;
};

type State = [
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
  boolean,
  React.Dispatch<React.SetStateAction<boolean>>,
];

const initialState: State = [false, () => undefined, false, () => undefined];

const FullScreenEditorContext = createContext<State>(initialState);

export const FullScreenEditorProvider = ({
  children,
}: FullScreenEditorProviderProps): JSX.Element => {
  const isFullscreenEditorState = useState(false);
  const isExperimentalViewportHeightState = useState(false);

  return (
    <FullScreenEditorContext.Provider
      value={[...isFullscreenEditorState, ...isExperimentalViewportHeightState]}
    >
      {children}
    </FullScreenEditorContext.Provider>
  );
};

/**
 * Only intended for deep editing experiences (e.g. flow builder, popup editor).
 */
export const useFullScreenEditor = (): State =>
  useContext(FullScreenEditorContext);
