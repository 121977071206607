import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import {
  PaymentMethodsProvider,
  PaymentMethodSteps as Steps,
} from 'components/billing/context/paymentMethods';
import { useEffect } from 'react';
import { STRIPE_PUBLIC_KEY } from 'utils/envVars';
import { COMMON_EVENT_NAMES, logEvent, MODAL_TYPES } from 'utils/events';
import PaymentMethodSteps from './PaymentMethodSteps';

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY || '');

interface Props {
  close: () => void;
  closeBtnTxt?: string;
  /** Open the PaymentMethodsModal on a specific step. Useful for deeplinking to the Add CC step. */
  initialStep?: Steps;
}

export default function PaymentMethodsModal({
  close,
  closeBtnTxt,
  initialStep,
}: Props): JSX.Element {
  useEffect(() => {
    logEvent(COMMON_EVENT_NAMES.MODAL_OPENED, {
      modal_type: MODAL_TYPES.BILLING_PAYMENT_METHODS,
    });
  }, []);

  return (
    <PaymentMethodsProvider initialStep={initialStep}>
      <Elements stripe={stripePromise}>
        <PaymentMethodSteps close={close} closeBtnTxt={closeBtnTxt} />
      </Elements>
    </PaymentMethodsProvider>
  );
}
