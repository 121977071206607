import {
  Badge,
  BodyText,
  Button,
  Highlight,
  Icon,
  IconSet,
} from '@postscript/components';
import { useState } from 'react';
import ConversationIntelligenceControls, {
  Controls,
  FILTER_LABEL_LOOKUP,
  HAS_SUMMARY,
} from '../../components/ConversationIntelligenceControls';
import { VIEW_OPTIONS } from '../../constants';
import { useView } from '../../hooks/useView';
import * as styles from '../styles';
import { getIsMultiShop, normalizeFilters } from '../utils';

export const SearchControlsTitle = ({
  dateLabel,
  controls,
}: {
  dateLabel: string;
  controls: Controls;
}): JSX.Element => {
  const hasNonSpecialCasedFilters =
    (controls.filters.length === 1 && controls.filters[0] !== HAS_SUMMARY) ||
    controls.filters.length > 1;

  if (controls.searchText) {
    return (
      <>
        Viewing conversations mentioning{' '}
        <Highlight>{controls.searchText}</Highlight>
      </>
    );
  }
  if (hasNonSpecialCasedFilters || controls.topicLabel || controls.tag) {
    return <>Viewing conversations matching filter criteria</>;
  }

  return <>Viewing all conversations {dateLabel}</>;
};

interface BadgeElementsProps {
  allowReset: boolean;
  topicLabel?: string;
  tag?: string;
  onTopicAction?: () => void;
  onTagAction?: () => void;
}

const BadgeElements = ({
  topicLabel,
  tag,
  onTopicAction,
  onTagAction,
  allowReset,
}: BadgeElementsProps) => {
  const { view } = useView();
  const isBubAI = view === VIEW_OPTIONS.AI_DEBUGGER;
  return (
    <>
      {topicLabel && (
        <Badge
          icon={allowReset ? IconSet.Close : undefined}
          iconAction={onTopicAction}
          variant="success"
        >
          #{topicLabel}
        </Badge>
      )}
      {tag && (
        <Badge
          icon={allowReset ? IconSet.Close : undefined}
          iconAction={onTagAction}
          variant={isBubAI ? 'success' : 'info'}
        >
          #{tag}
        </Badge>
      )}
    </>
  );
};

interface Props {
  title: JSX.Element;
  isLoading: boolean;
  controls: Controls;
  setControls: React.Dispatch<React.SetStateAction<Controls>>;
  clearFilters: () => void;
}

const SearchControls = ({
  title,
  isLoading,
  controls,
  setControls,
  clearFilters,
}: Props) => {
  const [searchControlsAreOpen, setSearchControlsAreOpen] = useState(false);
  const isMultiShop = getIsMultiShop();

  const listableFilters = controls.filters?.filter(
    (filter) => filter !== 'HAS_SUMMARY',
  );
  const hasListableFilters = listableFilters.length > 0;
  const filtersSelected = !!(
    controls.topicLabel ||
    controls.tag ||
    hasListableFilters
  );

  return (
    <>
      <styles.TitleContainer>
        <styles.SearchHeader
          filtersSelected={filtersSelected}
          onClick={() => setSearchControlsAreOpen(!searchControlsAreOpen)}
        >
          <styles.SearchHeaderTitle>
            <Icon
              color="var(--text-color-dim)"
              component={IconSet.Search}
              size={24}
            />
            <styles.SearchText
              forwardedAs="h2"
              color="var(--text-color-dim)"
              size="xx-small"
            >
              {title}
            </styles.SearchText>
            <styles.SearchButtons>
              <Button
                icon={IconSet.Filter}
                iconPosition="right"
                monochrome
                onClick={() => setSearchControlsAreOpen(!searchControlsAreOpen)}
                size="small"
                variant="text"
              >
                {filtersSelected ? 'Edit Filters' : 'Search and Filter'}
              </Button>
            </styles.SearchButtons>
          </styles.SearchHeaderTitle>
          <styles.SearchHeaderLogline>
            <BadgeElements
              allowReset={false}
              topicLabel={controls.topicLabel}
              tag={controls.tag}
            />
            {hasListableFilters &&
              listableFilters.map((filter) => {
                const label = FILTER_LABEL_LOOKUP[filter]?.label || filter;
                return <Badge key={filter}>{label}</Badge>;
              })}
            {!filtersSelected && (
              <BodyText size="x-small">Click to open search</BodyText>
            )}
          </styles.SearchHeaderLogline>
        </styles.SearchHeader>

        <ConversationIntelligenceControls
          badgeElements={
            <BadgeElements
              allowReset
              topicLabel={controls.topicLabel}
              tag={controls.tag}
              onTopicAction={() =>
                setControls((prevControls) => ({
                  ...prevControls,
                  topicLabel: undefined,
                }))
              }
              onTagAction={() =>
                setControls((prevControls) => ({
                  ...prevControls,
                  tag: undefined,
                }))
              }
            />
          }
          controls={controls}
          fullClearFunction={clearFilters}
          onControlsUpdate={(newControls) => {
            setControls((prevControls) => ({
              ...prevControls,
              ...newControls,
              filters: normalizeFilters(newControls.filters),
            }));
          }}
          isLoading={isLoading}
          isMultiShop={isMultiShop}
          isOpen={searchControlsAreOpen}
          toggleFunction={setSearchControlsAreOpen}
        />
      </styles.TitleContainer>
    </>
  );
};

export default SearchControls;
