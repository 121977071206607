/* eslint-disable camelcase */
import { toast } from '@postscript/components';
import { api } from 'controllers/network/apiClient';
import { useCallback, useEffect, useState } from 'react';
import { UserContextType } from '../../../controllers/contexts/user';
import { Controls } from '../components/ConversationIntelligenceControls';
import { MAX_PAGE_SIZE } from '../pages/constants';
import { ConversationIntelligenceStatus } from '../pages/types';
import { TopicLabelTrends } from '../types';
import { useConversationIntelligenceMetrics } from './useConversationIntelligenceMetrics';
import {
  ConversationsWithInsightsResponse,
  InsightsPayload,
} from './useConversationSummaries';

const getPayloadFromControls = (controls: Controls): InsightsPayload => {
  /**
   * This function converts the controls to a payload, making sure that empty array
   * and strings are not passed to the API e.g. shop_ids = []
   */
  const payload: InsightsPayload = {
    max_page_size: MAX_PAGE_SIZE,
    page: 0,
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  };
  payload.page = controls.page > 0 ? controls.page - 1 : 0;
  if (controls.maxPageSize) {
    payload.max_page_size = controls.maxPageSize;
  }

  if (controls.shopIds.length) {
    payload.shop_ids = controls.shopIds;
  }
  if (controls.agentIds.length) {
    payload.sales_agent_ids = controls.agentIds;
  }
  if (controls.filters.length) {
    payload.filters = controls.filters;
  }
  if (controls.searchText) {
    payload.search_text = controls.searchText;
  }
  if (controls.searchDirection) {
    payload.search_direction = controls.searchDirection;
  }
  if (controls.topicLabel) {
    payload.topic_label = controls.topicLabel;
  }
  if (controls.tag) {
    payload.tag = controls.tag;
  }
  if (controls.sortBy) {
    payload.sort_by = controls.sortBy;
  }
  if (controls.dateRangeStart) {
    payload.date_range_start = controls.dateRangeStart;
  }
  if (controls.dateRangeEnd) {
    payload.date_range_end = controls.dateRangeEnd;
  }

  return payload;
};

const getEndpoint = ({
  entity,
  isMultiShop,
}: {
  entity: string;
  isMultiShop: boolean;
}) => {
  return `/v2/convert/${isMultiShop ? '' : 'shop-'}${entity}`;
};

const fetchData = async <T extends Record<string, any>>({
  endpoint,
  controls,
  setLoading,
  errorMessage,
  method = 'post',
}: {
  endpoint: string;
  controls: Controls;
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>;
  errorMessage?: string;
  method?: 'get' | 'post';
}): Promise<T | undefined> => {
  try {
    setLoading?.(true);
    const response =
      method === 'get'
        ? await api.get(endpoint)
        : await api.post(endpoint, getPayloadFromControls(controls));
    return response;
  } catch (error) {
    console.error(`Error fetching ${endpoint}:`, error);
    toast.error(errorMessage ?? `Error fetching ${endpoint}`);
  } finally {
    setLoading?.(false);
  }
};

export const useConversationData = (
  controls: Controls,
  isMultiShop: boolean,
  user: UserContextType,
) => {
  const [isConversationsLoading, setIsConversationsLoading] = useState(false);
  const [isMetricsLoading, setIsMetricsLoading] = useState(false);
  const [isTopicLabelTrendsLoading, setIsTopicLabelTrendsLoading] =
    useState(false);
  const [summaries, setSummaries] = useState<ConversationsWithInsightsResponse>(
    {
      conversations: [],
      users: [],
      matching_conversations: 0,
      total_conversations: 0,
      active_conversations: 0,
      matching_unique_subscribers: 0,
      total_unique_subscribers: 0,
    },
  );
  const [topicLabelTrends, setTopicLabelTrends] = useState<TopicLabelTrends>({
    trends: [],
  });
  const [conversationIntelligenceStatus, setConversationIntelligenceStatus] =
    useState<ConversationIntelligenceStatus>(
      ConversationIntelligenceStatus.COMPLETE_INSIGHTS,
    );

  const { metrics, updateMetrics } = useConversationIntelligenceMetrics();

  const fetchConversationStatus = useCallback(async () => {
    const response = await fetchData<{
      status: ConversationIntelligenceStatus;
    }>({
      endpoint: '/v2/convert/shop-conversation-intelligence-status',
      controls,
      errorMessage: 'Error fetching conversation status',
      method: 'get',
    });
    if (response) setConversationIntelligenceStatus(response.status);
  }, [controls]);

  const fetchConversations = useCallback(async () => {
    const response = await fetchData<ConversationsWithInsightsResponse>({
      endpoint: getEndpoint({ entity: 'conversations', isMultiShop }),
      controls,
      setLoading: setIsConversationsLoading,
      errorMessage: 'Error fetching conversations',
    });
    if (response) setSummaries(response);
  }, [controls, isMultiShop]);

  const fetchMetrics = useCallback(async () => {
    try {
      setIsMetricsLoading(true);
      const [subscriberMetricsRes, conversationMetricsRes] = await Promise.all([
        fetchData({
          endpoint: getEndpoint({ entity: 'subscriber-metrics', isMultiShop }),
          controls,
        }),
        fetchData({
          endpoint: getEndpoint({
            entity: 'conversation-metrics',
            isMultiShop,
          }),
          controls,
        }),
      ]);
      if (subscriberMetricsRes && conversationMetricsRes) {
        updateMetrics({
          ...subscriberMetricsRes,
          ...conversationMetricsRes,
        });
      }
    } finally {
      setIsMetricsLoading(false);
    }
  }, [controls, isMultiShop, updateMetrics]);

  const fetchTopicLabelTrends = useCallback(async () => {
    const response = await fetchData<TopicLabelTrends>({
      endpoint: getEndpoint({ entity: 'topic-label-trends', isMultiShop }),
      controls,
      setLoading: setIsTopicLabelTrendsLoading,
      errorMessage: 'Error fetching topic label trends',
    });
    if (response) setTopicLabelTrends(response);
  }, [controls, isMultiShop]);

  useEffect(() => {
    if (isMultiShop && user.user.shop_id !== 1) {
      toast.error('Must be logged in as shop 1');
      return;
    }
    fetchConversationStatus();
    fetchConversations();
    fetchMetrics();
    fetchTopicLabelTrends();
  }, [
    fetchConversationStatus,
    fetchConversations,
    fetchMetrics,
    fetchTopicLabelTrends,
    isMultiShop,
    user.user.shop_id,
  ]);

  const triggerJSONDownload = async () => {
    const route = '/v2/convert/json-conversations';

    try {
      const response: { signed_url: Location } = await api.post(
        route,
        getPayloadFromControls(controls),
      );
      window.location = response.signed_url;
    } catch (error) {
      toast.error('Error downloading JSON:', error);
    }
  };

  return {
    metrics,
    isConversationsLoading,
    isMetricsLoading,
    isTopicLabelTrendsLoading,
    summaries,
    topicLabelTrends,
    conversationIntelligenceStatus,
    triggerJSONDownload,
  };
};
