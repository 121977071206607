import { useGlobalModal } from 'components/GlobalModal/globalModal';
import { useLocalStorage } from 'hooks/useLocalStorage';

import { Banner } from '@postscript/components';
import PaymentMethodsModal from 'components/billing/modules/payments/PaymentMethodsModal';

import {
  PaymentMethodSteps,
  usePaymentMethods,
} from 'components/billing/context/paymentMethods';

export const FeeDisclosureBanner = () => {
  const { showModal, hideModal } = useGlobalModal();
  const { updateStep } = usePaymentMethods();

  const [hasDismissedBanner, setHasDismissedBanner] = useLocalStorage(
    'paymentMethodDetails-dismissed-fee-banner',
    false,
  );

  const goToStep = (step: PaymentMethodSteps) => {
    /**
     * When rendered in the PaymentMethods card, we need to open the PaymentMethodsModal to a specific step.
     * When rendered in the PaymentMethodsModal, the modal is already open, so we need to update the step.
     */
    showModal({
      dismissOnBackdropClick: false,
      children: <PaymentMethodsModal close={hideModal} initialStep={step} />,
    });

    updateStep('stripeAch');
  };

  if (hasDismissedBanner) {
    return null;
  }

  return (
    <Banner
      variant="warning"
      bodyText="Pay via ACH Bank Transfer to avoid the 2.9% convenience fee."
      // We want to always be collapsed
      collapseAt={9999}
      primaryAction={{
        text: 'Add Bank Account (ACH)',
        onClick: () => goToStep('stripeAch'),
        /** @ts-expect-error: These props are spread on the Button component */
        variant: 'text',
      }}
      onDismiss={() => setHasDismissedBanner(true)}
    />
  );
};
