import useIntegrations from 'components/responses/hooks/useIntegrations';
import Helpers from 'controllers/helpers';
import { api } from 'controllers/network/apiClient';
import { useFormikContext } from 'formik';
import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from 'react-query';
import { camelCaseKeys } from 'utils/formatters';
import {
  EmailIntegration,
  EmailIntegrationList,
  EmailIntegrationMutationPayload,
  PopupFormValues,
} from '../types';

// Use this list to filter the integrations response for email specific integrations.
// There's an integrations list in helpers.js we should update if we end up using this elsewhere.

const getEmailIntegrationsRoute = (
  popupId: number | string,
  suffix?: number | string,
): string =>
  Number.isNaN(+popupId)
    ? `/v2/block_popups/${popupId}/email-integrations${
        suffix ? `/${suffix}` : ''
      }`
    : `/v2/popups/${popupId}/email-integrations/${suffix ?? ''}`;

const getEmailIntegrationQueryKey = (
  popupId: number | string,
): (string | number)[] => {
  return ['popups', 'email-integrations', popupId];
};

const getEmailIntegrationMutationKey = (
  popupId: number | string,
  action: string,
): (string | number)[] => {
  return ['popups', 'email-integrations', popupId, action];
};

const getEmailIntegrationForPopup = async (
  popupId: number | string,
): Promise<EmailIntegration> => {
  const response = await api.get(getEmailIntegrationsRoute(popupId));
  return camelCaseKeys(response) as EmailIntegration;
};

export const useEmailIntegrationForPopup = (
  popupId?: number | string,
  options?: UseQueryOptions<
    EmailIntegration,
    any,
    EmailIntegration,
    (string | number)[]
  >,
) => {
  const isPopupIdDefined = popupId !== undefined;

  return useQuery({
    queryKey: isPopupIdDefined
      ? getEmailIntegrationQueryKey(popupId)
      : undefined,
    queryFn: isPopupIdDefined
      ? () => getEmailIntegrationForPopup(popupId)
      : undefined,
    staleTime: 5_000 * 60,
    enabled: !!options?.enabled && isPopupIdDefined,
  });
};

export type EmailIntegrationUpdatePayload = EmailIntegrationMutationPayload & {
  emailIntegrationId: number;
};

const updateEmailIntegrationForPopup = async (
  popupId: number | string,
  emailIntegrationId: number,
  integration: EmailIntegrationMutationPayload,
): Promise<EmailIntegration> => {
  const response = await api.put(
    getEmailIntegrationsRoute(popupId, emailIntegrationId),
    integration,
  );
  return camelCaseKeys(response) as EmailIntegration;
};

export const useUpdateEmailIntegrationForPopup = (popupId: number | string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (integration: EmailIntegrationUpdatePayload) => {
      const { emailIntegrationId, ...integrationPayload } = integration;
      return updateEmailIntegrationForPopup(
        popupId,
        emailIntegrationId,
        integrationPayload,
      );
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(getEmailIntegrationQueryKey(popupId), data);
        queryClient.invalidateQueries(getEmailIntegrationQueryKey(popupId));
      },
      mutationKey: getEmailIntegrationMutationKey(popupId, 'update'),
    },
  );
};

const createEmailIntegrationForPopup = async (
  popupId: number | string,
  integration: EmailIntegrationMutationPayload,
): Promise<EmailIntegration> => {
  const response = await api.post(
    getEmailIntegrationsRoute(popupId),
    integration,
  );
  return camelCaseKeys(response) as EmailIntegration;
};

export const useCreateEmailIntegrationForPopup = (popupId: number | string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (integration: EmailIntegrationMutationPayload) =>
      createEmailIntegrationForPopup(popupId, integration),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(getEmailIntegrationQueryKey(popupId), data);
        queryClient.invalidateQueries(getEmailIntegrationQueryKey(popupId));
      },
      mutationKey: getEmailIntegrationMutationKey(popupId, 'create'),
    },
  );
};

const deleteEmailIntegrationForPopup = async (
  popupId: number | string,
  emailIntegrationId: number,
): Promise<void> =>
  api.delete(getEmailIntegrationsRoute(popupId, emailIntegrationId));

export const useDeleteEmailIntegrationForPopup = (popupId: number | string) => {
  const queryClient = useQueryClient();
  return useMutation(
    (integrationId: number) => {
      return deleteEmailIntegrationForPopup(popupId, integrationId);
    },
    {
      onSuccess: () => {
        queryClient.setQueryData(
          getEmailIntegrationQueryKey(popupId),
          undefined,
        );
        queryClient.invalidateQueries(getEmailIntegrationQueryKey(popupId));
      },
      mutationKey: getEmailIntegrationMutationKey(popupId, 'delete'),
    },
  );
};

export const useAvailableEmailIntegrations = () => {
  const queryResult = useIntegrations();
  // Create a flat list of email integration names from the full integratons list.
  const emailIntegrationNames = Helpers.integrationArray.reduce(
    (espNames: string[], integration) => {
      if (integration?.integrationType === 'email') {
        espNames.push(integration.type.toLowerCase());
      }
      return espNames;
    },
    [],
  );

  return {
    ...queryResult,
    data: queryResult.data?.filter(
      (integration) =>
        emailIntegrationNames.includes(integration.type.toLowerCase()) &&
        integration.enabled,
    ),
  };
};

const getEmailIntegrationLists = async (
  integrationType: string,
  integrationId: number,
): Promise<EmailIntegrationList[]> => {
  // Klaviyo is returned uppercase and others are lowercase.
  // Because we capitalize them for the list view, we need to normalize them here.
  const normalizedIntegrationType = integrationType.toLowerCase();

  const response = await api.get(
    `/v2/integrations/${normalizedIntegrationType}/${integrationId}/email-lists`,
  );
  return response.lists;
};

export const useEmailIntegrationLists = (
  integrationType: string,
  integrationId: number,
  options?: any,
) => {
  return useQuery(
    ['popups', 'email-integrations', 'lists', integrationType, integrationId],
    () => getEmailIntegrationLists(integrationType, integrationId),
    {
      ...options,
    },
  );
};

export const useSetDefaultESPInPopupForm = () => {
  const {
    values: { id: popupId },
    setFieldValue,
  } = useFormikContext<PopupFormValues>();
  const { data: espIntegrations = [], isFetched: isIntegrationListFetched } =
    useAvailableEmailIntegrations();

  const {
    data: currentEmailIntegration,
    isFetched: isEmailIntegrationFetched,
  } = useEmailIntegrationForPopup(popupId, {
    enabled: espIntegrations.length > 0,
  });

  // We want to set a default ESP when we there are valid integrations available,
  // and we confirmed that none are set in the current popup.
  const shouldSetDefaultESP =
    isIntegrationListFetched &&
    espIntegrations.length > 0 &&
    (isEmailIntegrationFetched || !currentEmailIntegration);

  const setDefaultESPForPopup = () => {
    if (!shouldSetDefaultESP) {
      return;
    }

    const newEmailIntegrationObject = {
      enabled: true,
      integrationType: espIntegrations[0].type,
      integrationId: espIntegrations[0].id,
      lists: [],
    };

    setFieldValue('emailIntegration', newEmailIntegrationObject);
  };

  return setDefaultESPForPopup;
};
