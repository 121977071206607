/* eslint-disable no-alert */
import { ShopperStatusContext } from 'providers/shopperStatusProvider';
import { useContext } from 'react';

export const useShopperStatus = () => {
  const context = useContext(ShopperStatusContext);
  if (!context) {
    const err = 'useShopperStatus must be used within an ShopperStatusProvider';
    alert(`You haven't wrapped the context provider: ${err}`);
    throw new Error(err);
  }
  return context;
};
