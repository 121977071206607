import { ModalHeader } from '@postscript/components';

import { CREDIT_CARD_CONVENIENCE_FEE } from 'components/admin/utils/feature-flags';
import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { usePSLabs } from 'controllers/contexts/labsFeatures';
import PaymentMethodACHAccountholderForm from './PaymentMethodACHAccountholderForm';
import PaymentMethodACHMandate from './PaymentMethodACHMandate';
import PaymentMethodCardForm from './PaymentMethodCardForm';
import PaymentMethodDeleteConfirmation from './PaymentMethodDeleteConfirmation';
import PaymentMethodManagement from './PaymentMethodManagement';
import { ManagePaymentMethods } from './PaymentMethods/ManagePaymentMethods';
import PaymentMethodTypeChoice from './PaymentMethodTypeChoice';

interface Props {
  close: () => void;
  closeBtnTxt?: string;
}

export default function PaymentMethodSteps({
  close,
  closeBtnTxt,
}: Props): JSX.Element {
  const { steps, step } = usePaymentMethods();
  const { hasLabsFlag } = usePSLabs();

  return (
    <>
      <ModalHeader onCancel={close}>{steps?.[step]?.heading}</ModalHeader>
      {(() => {
        switch (step) {
          case 'management':
            return hasLabsFlag(CREDIT_CARD_CONVENIENCE_FEE) ? (
              <ManagePaymentMethods />
            ) : (
              <PaymentMethodManagement
                closeModal={close}
                closeBtnTxt={closeBtnTxt}
              />
            );
          case 'typeChoice':
            return <PaymentMethodTypeChoice />;
          case 'stripeCard':
            return <PaymentMethodCardForm />;
          case 'stripeAch':
            return <PaymentMethodACHAccountholderForm />;
          case 'stripeAchMandate':
            return <PaymentMethodACHMandate />;
          case 'deleteConfirmation':
            return <PaymentMethodDeleteConfirmation />;
          default:
            return null;
        }
      })()}
    </>
  );
}
