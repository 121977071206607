import {
  BodyText,
  Button,
  Heading,
  Icon,
  IconSet,
  Input,
  SelectMenu,
} from '@postscript/components';
import { getShopId } from 'components/account/AccountView/users/helpers';
import { Field, Form, Formik } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import styled, { css } from 'styled-components';

import { api } from 'controllers/network/apiClient';
import {
  AgentDashboardResponse,
  useAgentDashboard,
} from '../hooks/useDashboard';

export interface Controls {
  page: number;
  shopIds: number[];
  agentIds: string[] | number[];
  searchText: string;
  searchDirection: string;
  filters: string[];
  topicLabel?: string;
  tag?: string;
  sortBy: string;
  dateRangeStart?: string;
  dateRangeEnd?: string;
  maxPageSize?: number;
}

interface Props {
  badgeElements?: any;
  onControlsUpdate: (filters: any) => void;
  controls: Controls;
  isLoading: boolean;
  isMultiShop: boolean;
  isOpen: boolean;
  toggleFunction: any;
  fullClearFunction?: any;
}

const StyledSearchModalBackdrop = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: flex;
          opacity: 50%;
        `
      : css`
          display: none;
          opacity: 0;
        `}
  transition: all var(--hover-transition);
  transition-behavior: allow-discrete;

  height: 100vh;
  width: 100vw;
  background: var(--black);

  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
`;

const StyledSearchModal = styled.div<{ isOpen: boolean }>`
  ${({ isOpen }) =>
    isOpen
      ? css`
          display: flex;
          opacity: 1;
        `
      : css`
          display: none;
          opacity: 0;
        `}
  transition: display all var(--hover-transition);
  transition-behavior: allow-discrete;

  flex-direction: column;
  gap: var(--spacing-3);

  border-radius: var(--border-radius-medium);
  background: var(--surface-bkg-color);
  box-shadow: var(--box-shadow-large);
  padding: var(--spacing-3);

  position: absolute;
  top: -6px;
  right: -6px;
  left: -6px;
  z-index: 10;

  > form {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2);
  }
`;

const StyledSearchHeader = styled.header`
  display: flex;
  flex-direction: column;
  gap: 3px;
`;

const SearchHeaderFirstLine = styled.div`
  display: flex;
  gap: var(--spacing-1);
`;

const SearchHeaderBadges = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  padding-left: var(--spacing-5);
`;

const StyledTitle = styled.div`
  display: flex;
  gap: var(--spacing-1);
  align-items: center;
  flex: 1 1;
`;

const StyledSearchPrimary = styled.div`
  display: flex;
  gap: var(--spacing-2);
  align-items: flex-end;
  flex-wrap: wrap;

  & > div:first-child {
    flex: 1 0 240px;
  }

  & > div:last-child {
    flex: 0 1 204px;
  }
`;

const StyledFilterContainer = styled.div`
  display: flex;
  gap: var(--spacing-2);
  flex-wrap: wrap;
`;

const StyledTextDivider = styled.div`
  height: var(--spacing-6);
  display: flex;
  align-items: center;
`;

const StyledFilters = styled.div`
  display: flex;
  flex-direction: column;
  gap: var(--spacing-2);
  flex: 1 0 276px;
`;

const StyledButtons = styled.div`
  display: flex;
  gap: var(--spacing-1);
  justify-content: flex-end;
  padding-top: var(--spacing-2);
`;

export const CREATED_AT_ASC = 'CREATED_AT_1';
export const CREATED_AT_DESC = 'CREATED_AT_-1';
export const MOST_RECENT_INBOUND = 'MOST_RECENT_INBOUND';
export const MOST_RECENT_OUTBOUND = 'MOST_RECENT_OUTBOUND';
const SORT_BY: any = {
  [CREATED_AT_ASC]: {
    label: 'Conversations (oldest First)',
    value: CREATED_AT_ASC,
  },
  [CREATED_AT_DESC]: {
    label: 'Conversations (newest First)',
    value: CREATED_AT_DESC,
  },
  [MOST_RECENT_INBOUND]: {
    label: 'Inbound message (newest first)',
    value: MOST_RECENT_INBOUND,
  },
  [MOST_RECENT_OUTBOUND]: {
    label: 'Outbound message (newest first)',
    value: MOST_RECENT_OUTBOUND,
  },
};

const INBOUND = 'INBOUND';
const OUTBOUND = 'OUTBOUND';
const ALL = 'ALL';

const SEARCH_DIRECTION: any = {
  [ALL]: {
    label: 'Search all messages',
    value: ALL,
  },
  [INBOUND]: {
    label: 'Search inbound only',
    value: INBOUND,
  },
  [OUTBOUND]: {
    label: 'Search outbound only',
    value: OUTBOUND,
  },
};

export const LIVE = 'LIVE';
export const INACTIVE = 'INACTIVE';
export const HAS_SUMMARY = 'HAS_SUMMARY';
export const NO_SUMMARY = 'NO_SUMMARY';
export const CONVERTED = 'CONVERTED';
export const NOT_CONVERTED = 'NOT_CONVERTED';
export const UNSUBSCRIBED = 'UNSUBSCRIBED';
export const POSITIVE = 'POSITIVE';
export const NEGATIVE = 'NEGATIVE';
export const NEUTRAL = 'NEUTRAL';

const CONVERSATION_STATUS: any = {
  [LIVE]: {
    label: 'Happening now',
    value: 'LIVE',
  },
  [INACTIVE]: {
    label: 'Inactive',
    value: 'INACTIVE',
  },
};

const SUMMARY: any = {
  [NO_SUMMARY]: {
    label: 'No Summary',
    value: NO_SUMMARY,
  },
};

const OUTCOMES: any = {
  [CONVERTED]: {
    label: 'Converted',
    value: CONVERTED,
  },
  [NOT_CONVERTED]: {
    label: 'Did not convert',
    value: NOT_CONVERTED,
  },
  [UNSUBSCRIBED]: {
    label: 'Unsubscribed',
    value: UNSUBSCRIBED,
  },
};

const SENTIMENT: any = {
  [POSITIVE]: {
    label: 'Positive',
    value: POSITIVE,
  },
  [NEUTRAL]: {
    label: 'Neutral',
    value: NEUTRAL,
  },
  [NEGATIVE]: {
    label: 'Negative',
    value: NEGATIVE,
  },
};

// Fine so long as no duplicate keys.
const ALL_FILTERS = {
  ...CONVERSATION_STATUS,
  ...SUMMARY,
  ...OUTCOMES,
  ...SENTIMENT,
};

// exportable var to compare filter values
// with their human-readable labels
export const FILTER_LABEL_LOOKUP = {
  ...CONVERSATION_STATUS,
  ...SUMMARY,
  ...OUTCOMES,
  ...SENTIMENT,
};

const FILTER_OPTIONS = [
  {
    label: 'Status',
    options: Object.values(CONVERSATION_STATUS),
  },
  {
    label: 'Summary',
    options: Object.values(SUMMARY),
  },
  {
    label: 'Outcomes',
    options: Object.values(OUTCOMES),
  },
  {
    label: 'Sentiment',
    options: Object.values(SENTIMENT),
  },
];

interface DropdownOption {
  label: string;
  value: string;
}

const getSelectedFilters = (filters: string[]) => {
  return filters
    .map((filter) => ALL_FILTERS[filter])
    .filter((filter) => filter !== undefined);
};

const getControlsFromValues = (
  { shops, agents, searchText, filters, sortBy, searchDirection }: any,
  isMultiShop: boolean,
  includeHasSummary: boolean,
) => {
  const shopIds = isMultiShop
    ? shops.map((shop: any) => shop.value)
    : [getShopId()];
  return {
    searchText,
    shopIds,
    agentIds: agents.map((agent: any) => agent.value),
    filters: (!filters.includes(HAS_SUMMARY) && includeHasSummary
      ? [...filters, HAS_SUMMARY]
      : filters
    ).map((filter: any) => {
      if (filter === '') {
        return '';
      }
      return filter !== HAS_SUMMARY ? filter.value : HAS_SUMMARY;
    }),
    sortBy: sortBy.value,
    searchDirection: searchDirection.value,
  };
};

const transformAgentData = (
  agentData: AgentDashboardResponse,
): DropdownOption[] => {
  const transformedData: DropdownOption[] = [];

  Object.keys(agentData.active_agents).forEach((agentId) => {
    const agentInfo = agentData.active_agents[parseInt(agentId)];

    transformedData.push({
      label: agentInfo.name,
      value: agentId,
    });
  });

  return transformedData;
};

const FOCUSABLE_ELEMENTS =
  'a[href], area[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, *[tabindex], *[contenteditable]';

const returnFocusToActivator = (activator: HTMLElement) => {
  if (typeof activator?.focus === 'function') {
    activator.focus();
  }
};

const setFocus = (
  modalRef: React.RefObject<HTMLDivElement>,
  firstElementRef?: React.RefObject<HTMLElement>,
) => {
  if (firstElementRef?.current) {
    firstElementRef.current.focus();
    return;
  }
  if (modalRef.current) {
    modalRef.current.querySelector('input')?.focus();
  }
};

const trapFocus = (
  event: KeyboardEvent,
  modalRef: React.RefObject<HTMLDivElement>,
) => {
  if (!modalRef.current || event.key !== 'Tab') return;

  const focusable = modalRef.current.querySelectorAll(FOCUSABLE_ELEMENTS);
  const firstFocusable = focusable[0];
  const lastFocusable = focusable[focusable.length - 1];

  if (event.shiftKey && document.activeElement === firstFocusable) {
    event.preventDefault();
    (lastFocusable as HTMLElement).focus();
  }

  if (!event.shiftKey && document.activeElement === lastFocusable) {
    event.preventDefault();
    (firstFocusable as HTMLElement).focus();
  }
};

const ConversationIntelligenceControls = ({
  badgeElements,
  fullClearFunction,
  controls,
  onControlsUpdate,
  isLoading,
  isMultiShop,
  isOpen,
  toggleFunction,
}: Props) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [activator, setActivator] = useState<Element | null>(null);

  useEffect(() => {
    if (!isOpen) {
      returnFocusToActivator(activator as HTMLElement);
      return;
    }

    setActivator(document.activeElement);
    setFocus(modalRef);

    const eventHandlers = (event: KeyboardEvent) => {
      trapFocus(event, modalRef);
      if (event.key === 'Escape') {
        toggleFunction(false);
      }
    };

    window.addEventListener('keydown', eventHandlers);

    return () => {
      window.removeEventListener('keydown', eventHandlers);
    };
  }, [isOpen]);

  const [shopSearchText, setShopSearchText] = useState('');
  const [agentSearchText, setAgentSearchText] = useState('');
  const { data: shops } = useQuery({
    queryKey: ['sms-sales-shops'],
    queryFn: (): Promise<{ shops: DropdownOption[] }> =>
      api.get('/admin/get_all_shops'),
    select: (data) => data.shops,
  });

  const { data: agentData } = useAgentDashboard();

  const agentOptions = useMemo(() => {
    if (!agentData) return [];

    const agentDropdownOptions = transformAgentData(agentData);

    return agentDropdownOptions.filter((agent) =>
      agent.label.includes(agentSearchText),
    );
  }, [agentData, agentSearchText]);

  const shopOptions = useMemo(() => {
    if (!shops) return [];

    const filterShops = (shop: DropdownOption) => {
      const shopName = shop.label?.toLowerCase();
      const shopId = shop.value.toString();
      const searchString = shopSearchText.toLowerCase();
      return (
        shopId.startsWith(searchString) || shopName?.includes(searchString)
      );
    };

    const getShopOption = (shop: DropdownOption) => ({
      label: `${shop.label} (${shop.value})`,
      value: shop.value,
    });

    return shops.filter(filterShops).map(getShopOption).slice(0, 50);
  }, [shops, shopSearchText]);

  const shopsMap: any = {};
  shopOptions.forEach(({ value, label }: { value: string; label: string }) => {
    shopsMap[value] = { value, label };
  });

  const agentsMap: any = {};
  agentOptions.forEach(({ value, label }: { value: string; label: string }) => {
    agentsMap[value] = { value, label };
  });

  const initialFilterValues = useMemo(
    () => getSelectedFilters(controls.filters),
    [controls.filters],
  );

  const closeOnEscape = (event: KeyboardEvent) => {
    if (event.key === 'Escape' && isOpen) {
      toggleFunction(false);
    }
  };

  window.addEventListener('keydown', closeOnEscape);

  return (
    <Formik
      initialValues={{
        shops: shops
          ? shops.filter((shop) => controls.shopIds.includes(+shop.value))
          : [],
        agents: agentOptions
          ? controls.agentIds.map((agentId) => agentsMap[agentId])
          : [],
        searchText: controls.searchText,
        filters: initialFilterValues,
        sortBy: SORT_BY[controls.sortBy] || MOST_RECENT_INBOUND,
        searchDirection: SEARCH_DIRECTION[controls.searchDirection] || ALL,
      }}
      onSubmit={(values) => {
        const includeHasSummary = controls.filters.includes(HAS_SUMMARY);
        onControlsUpdate(
          getControlsFromValues(values, isMultiShop, includeHasSummary),
        );
        toggleFunction(false);
      }}
    >
      {({ values, setFieldValue, submitForm, resetForm }) => {
        useEffect(() => {
          const updatedFilters = getSelectedFilters(controls.filters);

          setFieldValue('filters', updatedFilters);
        }, [controls.filters, setFieldValue]);

        return (
          <>
            <StyledSearchModal
              aria-labelledby="ci-search-modal-title"
              aria-modal="true"
              id="ci-search-modal"
              isOpen={isOpen}
              ref={modalRef}
              role="dialog"
            >
              <StyledSearchHeader>
                <SearchHeaderFirstLine>
                  <StyledTitle>
                    <Icon
                      color="var(--text-color)"
                      component={IconSet.Search}
                      size={24}
                    />
                    <Heading size="xx-small" id="ci-search-modal-title">
                      Search and filter conversations
                    </Heading>
                  </StyledTitle>
                  <Button
                    icon={IconSet.Close}
                    monochrome
                    onClick={() => toggleFunction(false)}
                    size="small"
                    variant="text"
                  />
                </SearchHeaderFirstLine>
                <SearchHeaderBadges>{badgeElements}</SearchHeaderBadges>
              </StyledSearchHeader>

              <Form>
                <StyledSearchPrimary>
                  <Field
                    as={Input}
                    label="Search for"
                    placeholder="Enter a keyword or phrase"
                    icon={IconSet.Search}
                    name="searchText"
                    onChange={({ target }: any) => {
                      setFieldValue('searchText', target.value);
                    }}
                    type="text"
                    fieldWidth="100%"
                  />
                  <StyledTextDivider>
                    <BodyText bold>in</BodyText>
                  </StyledTextDivider>
                  <Field
                    as={SelectMenu}
                    label="Message type"
                    isClearable={false}
                    placeholder="Search Direction"
                    name="searchDirection"
                    value={values.searchDirection}
                    options={Object.values(SEARCH_DIRECTION)}
                    onChange={(value: any) => {
                      setFieldValue('searchDirection', value);
                    }}
                  />
                </StyledSearchPrimary>

                <StyledFilterContainer>
                  {/* External Filters */}
                  <StyledFilters>
                    <Field
                      as={SelectMenu}
                      label="Filters"
                      placeholder="Select filters"
                      name="filters"
                      isMulti
                      value={values.filters.filter(
                        (filter) => filter !== HAS_SUMMARY,
                      )}
                      options={FILTER_OPTIONS}
                      onChange={(value: any) => {
                        setFieldValue('filters', value);
                      }}
                      fieldWidth="100%"
                    />
                    <Field
                      as={SelectMenu}
                      label="Sort by"
                      isClearable={false}
                      placeholder="Sort"
                      name="sortBy"
                      value={values.sortBy}
                      options={Object.values(SORT_BY)}
                      onChange={(value: any) => {
                        setFieldValue('sortBy', value);
                      }}
                      fieldWidth="100%"
                    />
                  </StyledFilters>

                  {isMultiShop && (
                    <StyledFilters>
                      <Field
                        as={SelectMenu}
                        label="Filter by shop"
                        placeholder="Select shops"
                        name="shops"
                        isMulti
                        value={values.shops}
                        options={shopOptions}
                        onChange={(value: any) => {
                          setFieldValue('shops', value);
                        }}
                        onInputChange={setShopSearchText}
                        fieldWidth="100%"
                      />
                      <Field
                        as={SelectMenu}
                        label="Filter by agent"
                        placeholder="Select agents"
                        name="agents"
                        isMulti
                        value={values.agents}
                        options={agentOptions}
                        onChange={(value: any) => {
                          setFieldValue('agents', value);
                        }}
                        onInputChange={setAgentSearchText}
                        fieldWidth="100%"
                      />
                    </StyledFilters>
                  )}
                </StyledFilterContainer>

                {/* Filter, Reset */}
                <StyledButtons>
                  <Button
                    variant="secondary"
                    monochrome
                    onClick={() => {
                      resetForm();
                      setFieldValue('searchText', '');
                      setFieldValue('filters', [HAS_SUMMARY]);
                      if (fullClearFunction) {
                        fullClearFunction({
                          ...controls,
                          topicLabel: undefined,
                          tag: undefined,
                        });
                      }
                      submitForm();
                    }}
                  >
                    Clear
                  </Button>
                  <Button type="submit" disabled={isLoading} monochrome>
                    Search
                  </Button>
                </StyledButtons>
              </Form>
            </StyledSearchModal>
            <StyledSearchModalBackdrop
              isOpen={isOpen}
              onClick={() => toggleFunction(false)}
            />
          </>
        );
      }}
    </Formik>
  );
};

export default ConversationIntelligenceControls;
