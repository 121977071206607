import Utils from 'controllers/utils';
import cookie from 'react-cookies';
import Requests from './network_requests';

const Tokens = {
  refreshAccessToken() {
    const promise = new Promise((resolve) => {
      // If the cookie is no longer there (most likely due to expiration) the user
      // is effectively logged out so we need to make sure we clear all token
      // cookies and redirect them to the login page
      if (!cookie.load('refresh_token')) {
        Utils.clearAuthTokensAndRedirect();
        return;
      }

      Requests.post('/token/refresh', {}, true).then((result) => {
        if (result.access_token) {
          Utils.saveAuthTokens({ accessToken: result.access_token });
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
    return promise;
  },
};

export default Tokens;
