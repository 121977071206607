import { EXEMPT_FROM_SHOP_TOS_GATE } from 'components/admin/utils/feature-flags';
import AcceptTOSView from 'components/dtos/AcceptTOSView';
import ErrorBoundary from 'components/generic/ErrorBoundary';
import LoadingSpinnerBlock from 'components/generic/Loading/LoadingSpinnerBlock';
import AppBannerList from 'components/GlobalBanners/AppBannerList';
import GlobalBannerList from 'components/GlobalBanners/GlobalBannerList';
import { useBanners } from 'components/GlobalBanners/globalBanners';
import GlobalModalContainer from 'components/GlobalModal/GlobalModalContainer';
import LegacyGlobalBannerList from 'components/LegacyGlobalBanners/LegacyGlobalBannerList';
import { logNavItemClicked } from 'components/navigation/eventUtils';
import NavMenuBub from 'components/navigation/NavMenuBub';
import NavMenuButton from 'components/navigation/NavMenuButton';
import SidebarNavigation from 'components/navigation/SidebarNavigation';
import { LocationContext } from 'controllers/contexts';
import { useFeatureFlags } from 'controllers/contexts/featureFlags';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { PRODUCT_AREAS } from 'utils/events';
import useAccountStatus from '../../account/useAccountStatus';
import useAppLayout from './hooks/useAppLayout';
import {
  StyledAppContainer,
  StyledContent,
  StyledMain,
  StyledSidebarAndMain,
} from './styled';

/* Leaving these as unknown and casting to help convert from jsx. The previous
prop types were incomplete, and the component that renders it is also jsx, and a
beast, so double checking these types is worthwhile, but also needs its own
attention. */
type AppShellProps = Record<
  | 'changeAppState'
  | 'children'
  | 'getUserInfo'
  | 'inSuspense'
  | 'location'
  | 'logOut'
  | 'shops'
  | 'user',
  unknown
>;

const AppShell = ({
  changeAppState,
  children,
  getUserInfo,
  inSuspense,
  location,
  logOut,
  shops,
  user,
}: AppShellProps) => {
  const history = useHistory();
  const {
    appLayoutCssClasses,
    isFullScreenEditor,
    isSidebarOpen,
    isExperimentalViewportHeight,
    setIsSidebarOpen,
  } = useAppLayout();

  const { billingActive, installed, isFetched } = useAccountStatus();
  const { banners } = useBanners();
  const hasGlobalBanners = isFetched && billingActive && installed;

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const { hasFlag } = useFeatureFlags(); // jsx, this function exists

  const handleMainClick = () => setIsSidebarOpen(false);

  const handleNavMenuButtonClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ) => {
    setIsSidebarOpen(!isSidebarOpen);
    logNavItemClicked(event, 'Mobile Menu Button');
  };

  useEffect(() => {
    return history.listen(() => window?.scrollTo(0, 0));
  }, [history]);

  return (
    <LocationContext.Provider value={location as any}>
      <GlobalModalContainer />
      <StyledAppContainer
        $isExperimentalViewportHeight={isExperimentalViewportHeight}
        className={appLayoutCssClasses}
      >
        <NavMenuBub />
        <StyledSidebarAndMain
          $isExperimentalViewportHeight={!!isExperimentalViewportHeight}
        >
          <NavMenuButton
            isSidebarNavigationOpen={isSidebarOpen}
            onClick={handleNavMenuButtonClick}
          />
          <SidebarNavigation
            changeAppState={changeAppState}
            getUserInfo={getUserInfo}
            isFullScreenEditor={isFullScreenEditor}
            isSidebarNavigationOpen={isSidebarOpen}
            logOut={logOut}
            setIsSidebarNavigationOpen={setIsSidebarOpen}
            shops={shops}
            user={user}
          />
          <StyledMain
            $isExperimentalViewportHeight={isExperimentalViewportHeight}
            aria-hidden={isSidebarOpen}
            onClick={handleMainClick}
          >
            {!isFullScreenEditor && (
              <AppBannerList
                data-bannercount={banners.length}
                data-legacybannercount={2} // data-bannercount={banners.length}
                id="global-banner-list"
              >
                {hasGlobalBanners && (
                  <>
                    <GlobalBannerList />
                    <LegacyGlobalBannerList />
                  </>
                )}
              </AppBannerList>
            )}
            <StyledContent
              $isExperimentalViewportHeight={isExperimentalViewportHeight}
            >
              <ErrorBoundary
                productArea={PRODUCT_AREAS.GLOBAL}
                secondaryErrorPage={false}
              >
                {!hasFlag(EXEMPT_FROM_SHOP_TOS_GATE) &&
                  !(user as any)?.is_admin && <AcceptTOSView />}
                {inSuspense ? <LoadingSpinnerBlock /> : children}
              </ErrorBoundary>
            </StyledContent>
          </StyledMain>
        </StyledSidebarAndMain>
      </StyledAppContainer>
    </LocationContext.Provider>
  );
};

export default AppShell;
