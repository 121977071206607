import {
  Badge,
  BodyText,
  Button,
  getTextStyles,
  Heading,
  Icon,
  IconSet,
  TabGroup,
  Tooltip,
} from '@postscript/components';
import EditableHeaderText, {
  EditableHeaderTextProps,
} from 'components/layout/EditableHeaderText';
import { APP_LAYOUT_CSS_SELECTORS } from 'constants/constants';
import { useFullScreenEditor } from 'controllers/contexts/fullScreenEditor';
import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { COMMON_EVENT_NAMES, logEvent, ProductAreas } from 'utils/events';
import MediaQueries from 'utils/mediaQueries';

const HEADING_SIZE = 'large';
const FULLSCREEN_HEADING_SIZE = 'x-small';

const DESCRIPTION_SIZE = 'medium';
const FULLSCREEN_DESCRIPTION_SIZE = 'small';

export const EVENT_LISTENERS = {
  ON_CLICK: 'onClick',
  ON_MOUSE_ENTER: 'onMouseEnter',
} as const;

export type EventListeners =
  typeof EVENT_LISTENERS[keyof typeof EVENT_LISTENERS];

export const logPageHeader = (
  eventListener: EventListeners,
  productArea: ProductAreas,
): void => {
  const data = {
    location: 'page header',
    product_area: productArea,
  };

  if (eventListener === EVENT_LISTENERS.ON_CLICK) {
    logEvent('more info clicked', data);
  }

  if (eventListener === EVENT_LISTENERS.ON_MOUSE_ENTER) {
    logEvent(COMMON_EVENT_NAMES.TOOLTIP_SHOWN, data);
  }
};

/* MAIN */

interface Tag {
  icon?: () => JSX.Element;
  id: string;
  name: string;
}

interface PageHeaderBaseProps extends React.HTMLAttributes<HTMLElement> {
  actions?: React.ReactNode;
  breadCrumb?: {
    onClick?: () => void;
    text: string;
    to?: React.ReactNode;
  };
  sticky?: boolean;
  tabs?: React.ReactNode;
  tags?: {
    addButtonAction?: () => void;
    tagList: Array<Tag>;
    removeAction?: () => void;
  };
}

interface EditablePageHeader extends PageHeaderBaseProps {
  editablePageTitle?: EditableHeaderTextProps;
  editableDescription?: EditableHeaderTextProps;
  pageTitle?: never;
  titleBadge?: never;
  description?: never;
  descriptionLink?: never;
  descriptionTooltipOnClick?: never;
  descriptionTooltipOnMouseEnter?: never;
}

interface NonEditablePageHeader extends PageHeaderBaseProps {
  editablePageTitle?: never;
  editableDescription?: never;
  pageTitle: React.ReactNode;
  titleBadge?: React.ReactNode;
  description?: React.ReactNode;
  descriptionLink?: string;
  descriptionTooltipOnClick?: () => void;
  descriptionTooltipOnMouseEnter?: () => void;
}

export type PageHeaderProps = EditablePageHeader | NonEditablePageHeader;

interface PageHeaderContainerProps {
  breadCrumb?: boolean;
  isFullScreenEditorMode?: boolean;
  isStuck?: boolean;
  sticky?: boolean;
}

// prettier-ignore
const PageHeaderContainer = styled.header<PageHeaderContainerProps>`
  position: relative;
  padding-bottom: ${({ isFullScreenEditorMode }) =>
    isFullScreenEditorMode ? 'var(--spacing-2)' : 'var(--spacing-4)'};
  padding-left: 0;
  margin-bottom: var(--spacing-2);
  transition: margin var(--nav-menu-transition-speed);

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    margin-bottom: 0;
  }

  ${({ breadCrumb }) =>
    breadCrumb &&
    css`
      ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
        ${MediaQueries.desktopOnly} {
          margin-bottom: 0;
        }
      }
    `}

  // is sticky
  ${({ isStuck, sticky }) => sticky && css`
    ${MediaQueries.desktopOnly} {
      background: var(--main-bkg-color);
      position: sticky;
      top: 54px;
      z-index: 100;

      ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
        top: 0;
      }

      &:before {
        background: inherit;
        content: '';
        display: block;
        pointer-events: none;
        position: absolute;
        height: calc(100% + 54px);
        width: calc(100% + 72px);
        left: -18px;
        top: -54px;
        z-index: -1;
      }

      &:after {
        display: block;
        content: '';
        height: 1px;
        width: calc(100% + var(--spacing-6));
        background: var(--border-color-dim);
        position: absolute;
        opacity: 0;
        left: calc(-1 * var(--spacing-3));
        bottom: 0;
        transition: opacity var(--hover-transition);
      }

      ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} &:after {
        width: calc(100% + 132px);
        left: -78px;
      }

      ${isStuck && css`
        box-shadow: var(--box-shadow-medium);

        ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
          top: 12px;
        }

        &:before {
          opacity: 1;
        }
        &:after {
          opacity: 1;
        }
      `}
    }
  `}
`;

const StyledScrollMarker = styled.span<{ isFullScreenEditorMode?: boolean }>`
  height: 0;
  width: 0;
  visibility: hidden;
  position: fixed;
  top: 0;
  z-index: 99;
`;

/* BREADCRUMB */

const BreadCrumbLink = styled(Link)`
  color: var(--text-color-dim);
  display: inline-flex;
  vertical-align: top;
  ${getTextStyles.eyebrowText()}

  &:focus {
    outline: none;
  }

  &:hover {
    color: var(--link-color);
    text-decoration: underline;
  }

  &:focus-visible {
    text-decoration: underline;
  }

  &:active {
    color: var(--link-color-hover);
  }

  ${MediaQueries.desktopOnly} {
    position: absolute;
    top: -18px;
    left: 0;
    z-index: 102;
  }

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    position: absolute;
    top: 4px;
    left: -28px;
    z-index: 102;

    .breadcrumb-text {
      visibility: hidden;
      width: 0;
      height: 0;
    }
  }

  ${MediaQueries.tabletAndPhone} {
    padding-bottom: var(--spacing-1);
  }
`;

const BreadCrumbIconFullScreen = styled(Icon)`
  display: none;
  transition: var(--hover-transition) var(--transition-easing);
  transition-property: color, transform;

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    display: block;
  }

  ${BreadCrumbLink}:hover & {
    transform: translateX(calc(-1 * var(--spacing-1)));
  }
`;

const BreadCrumbIcon = styled(Icon)`
  transform: translateX(calc(var(--spacing-1) / -2));
  transition: var(--hover-transition) var(--transition-easing);
  transition-property: color, transform;

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    display: none;
  }

  ${BreadCrumbLink}:hover & {
    transform: translateX(calc(-1 * var(--spacing-1)));
  }
`;

/* TITLE & DESCRIPTION AND ACTION CONTAINER */

const PageTitleDescriptionAndActionContainer = styled.div`
  display: flex;

  ${MediaQueries.tabletAndPhone} {
    padding-top: var(--spacing-1);

    ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
      padding-top: 0;
    }
  }

  ${MediaQueries.phoneOnly} {
    flex-direction: column;
  }
`;

/* TITLE AND DESCRIPTION */
const PageTitleAndDescriptionContainer = styled.div<
  Pick<EditablePageHeader, 'editablePageTitle'>
>`
  display: flex;
  flex-direction: column;
  min-width: 0;

  ${({ editablePageTitle }) =>
    editablePageTitle
      ? 'flex: 0 1 auto; align-items: flex-start;'
      : 'flex: 0 1 100%;'}
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
`;

const PageTitle = styled(Heading)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

// prettier-ignore
const StyledEditablePageTitle = styled(EditableHeaderText)<EditableHeaderTextProps>`
  font: var(--heading-${HEADING_SIZE});
  letter-spacing: var(--heading-letter-spacing-${HEADING_SIZE});

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    font: var(--heading-${FULLSCREEN_HEADING_SIZE});
    font-weight: var(--extra-bold-font-weight);
    letter-spacing: var(--heading-letter-spacing-${FULLSCREEN_HEADING_SIZE});

  }
`;

const StyledDescriptionLink = styled.a`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font: var(--body-text-x-small);
  color: var(--text-color-dim);
  text-decoration: underline;
  position: absolute;
  height: var(--spacing-4);
  width: var(--spacing-3);

  &:hover {
    color: var(--highlight-color);
  }
  &:active {
    opacity: 0.75;
  }
  &:focus {
    outline: none;
    color: var(--highlight-color);
  }
`;

// prettier-ignore
const Description = styled(BodyText).attrs({size: DESCRIPTION_SIZE})<{ hasLink?: string }>`
  position: relative;
  margin: 0;
  max-width: 648px; // 80 chars
  padding-top: var(--spacing-1); // editable description padding is different

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    padding-top: 3px;
  }
`;

// prettier-ignore
const StyledEditableDescription = styled(EditableHeaderText)<EditableHeaderTextProps>`
  color: var(--text-color-dim);
  align-items: flex-start;

  font: var(--body-text-${DESCRIPTION_SIZE});
  letter-spacing: var(--body-text-letter-spacing-${DESCRIPTION_SIZE});

  ${APP_LAYOUT_CSS_SELECTORS.FULLSCREEN_EDITOR} & {
    font: var(--body-text-${FULLSCREEN_DESCRIPTION_SIZE});
    letter-spacing: var(--body-text-letter-spacing-${FULLSCREEN_DESCRIPTION_SIZE});
    padding-top: 3px;
  }

  // tweak icon position
  > span[role="img"] {
    margin-top: 4px;
  }
`;

/* TAGS */
const StyledAddTagsButton = styled(Button).attrs({
  icon: IconSet.Plus,
  iconPosition: 'left',
  monochrome: true,
  size: 'small',
  variant: 'plain',
})``;

const StyledTagBadge = styled(Badge).attrs({
  size: 'small',
  rounded: true,
  variant: 'neutral',
})`
  color: var(--purple-core);
`;

const StyledTagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-1);
  padding-top: 3px;
`;

/* PAGE TABS */
const StyledTabGroup = styled(TabGroup)`
  align-self: flex-start;
  margin-top: var(--spacing-2);
`;

/* PAGE ACTION */

const PageActions = styled.div<Pick<EditablePageHeader, 'editablePageTitle'>>`
  flex: 1 0 auto;
  ${({ editablePageTitle }) => (editablePageTitle ? ' margin-left: auto;' : '')}

  ${MediaQueries.desktopAndTablet} {
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
    padding: var(--spacing-1) 0 0 var(--spacing-4);
  }

  ${MediaQueries.tabletOnly} {
    padding-top: 0;
  }

  ${MediaQueries.phoneOnly} {
    padding: var(--spacing-4) 0 0;
    flex-grow: 100%;
  }
`;

const PageHeader = ({
  actions,
  breadCrumb,
  description,
  descriptionLink,
  descriptionTooltipOnClick,
  descriptionTooltipOnMouseEnter,
  editableDescription,
  editablePageTitle,
  pageTitle = 'Postscript',
  titleBadge,
  sticky = false,
  tabs,
  tags,
  ...props
}: PageHeaderProps): JSX.Element => {
  const [isStuck, setIsStuck] = useState(false);
  const [isFullScreenEditorMode] = useFullScreenEditor();
  const stickyRef = useRef<HTMLElement>(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([e]) => {
        setIsStuck(e.intersectionRatio < 1);
      },
      { threshold: [0, 1] },
    );
    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }
    return () => observer.disconnect();
  }, [stickyRef]);

  const renderPageTitle = () => {
    if (editablePageTitle) {
      const { ...eptProps } = editablePageTitle;
      return (
        <StyledEditablePageTitle
          errorSize={HEADING_SIZE}
          iconSize={isFullScreenEditorMode ? 23 : 30}
          {...eptProps}
        />
      );
    }
    if (pageTitle) {
      return (
        <PageTitle
          forwardedAs="h1"
          id="pageHeader__pageTitle"
          size={isFullScreenEditorMode ? FULLSCREEN_HEADING_SIZE : HEADING_SIZE}
        >
          {pageTitle}
        </PageTitle>
      );
    }
  };

  const renderDescriptionLink = () => {
    return (
      <>
        {' '}
        <StyledDescriptionLink
          aria-describedby="pageHeader__DescriptionLink"
          data-for="pageHeader__DescriptionLink"
          data-tip
          href={descriptionLink}
          onMouseEnter={descriptionTooltipOnMouseEnter}
          rel="noreferrer"
          tabIndex={0}
          target="_blank"
        >
          <Icon
            component={IconSet.Information}
            onClick={descriptionTooltipOnClick}
            size={14}
          />
        </StyledDescriptionLink>
        <Tooltip id="pageHeader__DescriptionLink" size="small">
          More info about {pageTitle}
        </Tooltip>
      </>
    );
  };

  const renderDescription = () => {
    if (tabs) return;
    let renderedDescription = null;
    if (editableDescription) {
      const { ...edProps } = editableDescription;
      renderedDescription = (
        <StyledEditableDescription
          errorSize="small"
          iconSize={18}
          {...edProps}
        />
      );
    }
    if (description) {
      renderedDescription = (
        <Description hasLink={descriptionLink} forwardedAs="span">
          {description}
          {descriptionLink && renderDescriptionLink()}
        </Description>
      );
    }
    return renderedDescription;
  };

  const renderTagContents = () => {
    if (!tags || !tags.addButtonAction || !tags.tagList) return;

    const tagList: Array<JSX.Element> = [];

    if (tags.tagList.length > 0) {
      tags.tagList.forEach((tag) => {
        tagList.push(
          <StyledTagBadge
            icon={tags.removeAction ? IconSet.Close : tag.icon}
            iconAction={tags.removeAction && tags.removeAction}
            id={tag.id}
            key={tag.id}
          >
            {tag.name}
          </StyledTagBadge>,
        );
      });
    }

    return (
      <>
        {tagList}
        <StyledAddTagsButton onClick={tags.addButtonAction}>
          Add Tag(s)
        </StyledAddTagsButton>
      </>
    );
  };

  const renderTags = () => {
    if (!tags) return;
    return <StyledTagContainer>{renderTagContents()}</StyledTagContainer>;
  };

  const renderPageTabs = () => {
    if (!tabs) return;
    return (
      <StyledTabGroup
        description="Some assorted settings"
        id="page-level-tabs"
        placement="page-level"
      >
        {React.Children.map(tabs, (tab: React.ReactNode) => {
          if (!React.isValidElement(tab)) return;
          return React.cloneElement(tab, {
            key: `tab-${tab.props.children}`,
          });
        })}
      </StyledTabGroup>
    );
  };

  return (
    <PageHeaderContainer
      breadCrumb={!!breadCrumb}
      isFullScreenEditorMode={isFullScreenEditorMode}
      isStuck={isStuck}
      sticky={sticky}
      role="banner"
      data-cy="page-header"
      {...props}
    >
      {breadCrumb && (
        <BreadCrumbLink
          aria-label={isFullScreenEditorMode ? breadCrumb.text : undefined}
          data-cy="page-header-breadcrumb"
          onClick={breadCrumb.onClick}
          to={breadCrumb.to || '#'}
          tabIndex={0}
        >
          <BreadCrumbIconFullScreen
            aria-hidden
            size={16}
            component={IconSet.ArrowLeft}
          />
          <BreadCrumbIcon
            aria-hidden
            size={18}
            component={IconSet.ChevronLeftLarge}
          />
          <span className="breadcrumb-text">Back to {breadCrumb.text}</span>
        </BreadCrumbLink>
      )}
      <PageTitleDescriptionAndActionContainer>
        <PageTitleAndDescriptionContainer editablePageTitle={editablePageTitle}>
          <TitleContainer>
            {renderPageTitle()}
            {titleBadge && titleBadge}
          </TitleContainer>
          {renderDescription()}
          {renderTags()}
          {renderPageTabs()}
        </PageTitleAndDescriptionContainer>
        {actions && (
          <PageActions editablePageTitle={editablePageTitle}>
            {actions}
          </PageActions>
        )}
      </PageTitleDescriptionAndActionContainer>
      <StyledScrollMarker
        className="page-header-scroll-marker"
        isFullScreenEditorMode={isFullScreenEditorMode}
        ref={sticky ? stickyRef : null}
      />
    </PageHeaderContainer>
  );
};

export default PageHeader;
