import moment from 'moment';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { MOST_RECENT_INBOUND } from '../components/ConversationIntelligenceControls';
import { DATE_FORMAT, MAX_PAGE_SIZE } from '../pages/constants';

export const useConversationIntelligenceQueryParams = (
  isMultiShop: boolean,
  shopId: number,
) => {
  const { search } = useLocation();
  const history = useHistory();
  const queryParams = useMemo(() => new URLSearchParams(search), [search]);

  const queryShops = queryParams.get('shop_ids') ?? '';
  const shopIds = isMultiShop
    ? queryShops
        .split(',')
        .filter((shopId) => shopId !== '')
        .map(parseInt)
    : [shopId];

  const queryAgents = queryParams.get('agent_ids') ?? '';
  const agentIds = queryAgents
    .split(',')
    .filter((agentId) => agentId !== '')
    .map(parseInt);

  const searchText = queryParams.get('search_text') ?? '';
  const searchDirection = queryParams.get('search_direction') ?? 'ALL';
  const topicLabel = queryParams.get('topic_label') ?? '';
  const page = parseInt(queryParams.get('page') ?? '1', 10);
  const maxPageSize = parseInt(
    queryParams.get('maxPageSize') ?? MAX_PAGE_SIZE.toString(),
  );

  const queryFilters = queryParams.get('filters') ?? '';
  const filters = queryFilters.split(',').filter((filter) => filter !== '');

  const sortBy = queryParams.get('sort_by') ?? MOST_RECENT_INBOUND;
  const dateRangeEnd =
    queryParams.get('date_range_end') ?? moment().format(DATE_FORMAT);

  const dateRangeStart = queryParams.get('date_range_start')
    ? moment(queryParams.get('date_range_start')).format(DATE_FORMAT)
    : moment(dateRangeEnd)
        .subtract(29, 'days')
        .startOf('day')
        .format(DATE_FORMAT);

  const clearFilterParams = () => {
    const updatedQueryParams = new URLSearchParams(queryParams);

    updatedQueryParams.delete('filters');

    history.replace({
      search: updatedQueryParams.toString(),
    });
  };

  return {
    shopIds,
    agentIds,
    searchText,
    searchDirection,
    topicLabel,
    page,
    maxPageSize,
    filters,
    sortBy,
    dateRangeStart,
    dateRangeEnd,
    clearFilterParams,
  };
};
