import axios, { AxiosError } from 'axios';
import { load as loadCookie } from 'react-cookies';
import Constants from '../constants';
import Utils from '../utils';

const DEFAULT_ERROR_MESSAGE = 'Something went wrong.';
const ACCESS_TOKEN_COOKIE_KEY = 'access_token';
const REFRESH_TOKEN_COOKIE_KEY = 'refresh_token';
const REFRESH_TOKEN_ENDPOINT = '/token/refresh';
const BAD_AUTH_STATUS_CODES = [401, 422];
const LOGOUT_AUTH_STATUS_CODE = 418;
const RETRY_LIMIT = 2;
const TOKEN_EXPIRATION_RESPONSE = 'Token has expired';

interface Options {
  body?: any;
  throwErrorAsString?: boolean;
  returnNestedResponseData?: boolean;
}

const DEFAULT_OPTIONS: Options = {
  throwErrorAsString: true,
  returnNestedResponseData: true,
};

const { internalApiHost } = Constants;

const client = axios.create({
  baseURL: internalApiHost,
});

const refreshAccessToken = async () => {
  const refreshToken = loadCookie(REFRESH_TOKEN_COOKIE_KEY);

  // If the cookie is no longer there (most likely due to expiration) the user
  // is effectively logged out so we need to make sure we clear all token
  // cookies and redirect them to the login page
  if (!refreshToken) {
    Utils.clearAuthTokensAndRedirect();
    return;
  }

  try {
    const {
      data: { access_token: token },
    } = await axios.post(
      `${internalApiHost}${REFRESH_TOKEN_ENDPOINT}`,
      {},
      {
        headers: {
          Authorization: `Bearer ${refreshToken}`,
        },
      },
    );

    Utils.saveAuthTokens({ accessToken: token });
  } catch (error: any) {
    if (LOGOUT_AUTH_STATUS_CODE === error.response?.status) {
      Utils.logOutAndRedirect();
    } else if (error.response?.data?.msg === TOKEN_EXPIRATION_RESPONSE) {
      // If the token is considered expired by the endpoint the user is
      // effectively logged out so we need to make sure we clear all token
      // cookies and redirect them to the login page
      Utils.clearAuthTokensAndRedirect();
    }
  }
};

const extractErrorMessage = (error: Error | AxiosError) => {
  if (typeof error === 'string') return error;

  if (axios.isAxiosError(error))
    return (
      error.response?.data?.error_message ||
      (error.response?.data?.errors?.length &&
        error.response?.data?.errors[0]) ||
      error.response?.data?.message ||
      error.message ||
      error
    );

  return error?.message || error;
};

client.interceptors.request.use(({ headers, ...config }) => {
  const token = loadCookie(ACCESS_TOKEN_COOKIE_KEY);

  return {
    ...config,
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : undefined,
    },
  };
});

client.interceptors.response.use(
  (data) => {
    if (data.data?.success === false) {
      throw new Error(
        data.data.error || data.data.message || DEFAULT_ERROR_MESSAGE,
      );
    }

    return data;
  },
  async (error) => {
    const { config } = error;

    config.retries = config.retries || 0;

    // Refresh token and retry
    if (
      BAD_AUTH_STATUS_CODES.includes(error.response?.status) &&
      config.retries < RETRY_LIMIT
    ) {
      config.retries += 1;

      await refreshAccessToken();

      return client(config);
    }

    // Retry a failed get request
    if (config.method === 'get' && config.retries < RETRY_LIMIT) {
      config.retries += 1;

      return client(config);
    }

    throw error;
  },
);

export const api = {
  get: async <TResponse = any>(
    url: string,
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.get(url);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  post: async <TResponse = any>(
    url: string,
    body = {},
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.post(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  put: async <TResponse = any>(
    url: string,
    body = {},
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.put(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  patch: async <TResponse = any>(
    url: string,
    body = {},
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.patch(url, body);
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  delete: async <TResponse = any>(
    url: string,
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString, body } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.delete(url, { data: body });
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
  postFormData: async <TResponse = any>(
    url: string,
    formData: FormData,
    options?: Options,
  ): Promise<TResponse> => {
    const { returnNestedResponseData, throwErrorAsString } = {
      ...DEFAULT_OPTIONS,
      ...options,
    };

    try {
      const response = await client.post(url, formData, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return returnNestedResponseData ? response.data : response;
    } catch (error: any) {
      if (throwErrorAsString) throw extractErrorMessage(error);
      throw error;
    }
  },
};
