import {
  Button,
  IconSet,
  Layout,
  ModalBody,
  toast,
} from '@postscript/components';
import { useQueryClient } from 'react-query';
import styled from 'styled-components';

import { usePaymentMethods } from 'components/billing/context/paymentMethods';
import { useGetPaymentMethod } from 'components/billing/context/useBilling';

import {
  AddAchButton,
  AddCreditCardButton,
} from 'components/billing/modules/payments/PaymentMethods/buttons';
import { PaymentMethodDetails } from 'components/billing/modules/payments/PaymentMethods/PaymentMethodDetails';
import LoadingSpinner from 'components/generic/Loading/LoadingSpinner';

import { paymentMethodsQueryKey } from 'components/billing/modules/payments/hooks/usePaymentMethods';
import { logEvent } from 'utils/events';

import { Flex } from 'components/billing/modules/payments/PaymentMethods/styles';

const Actions = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  align-self: stretch;
`;

export const ManagePaymentMethods = () => {
  const { refetch: refetchPaymentMethod } = useGetPaymentMethod();
  const {
    updateStep,
    paymentMethods,
    unverifiedPaymentMethods,
    defaultPaymentMethodId,
    isLoading: arePaymentMethodsLoading,
    setDefaultPaymentMethod,
  } = usePaymentMethods();

  const queryClient = useQueryClient();

  const allPaymentMethods = [...paymentMethods, ...unverifiedPaymentMethods]
    .map((paymentMethod) => ({
      ...paymentMethod,
      isDefault: paymentMethod.id === defaultPaymentMethodId,
      isVerified: !paymentMethod.setupIntentStatus,
    }))
    .sort((paymentMethod) =>
      paymentMethod.id === defaultPaymentMethodId ? -1 : 1,
    );

  const setNewDefaultMethod = async (id: string) => {
    try {
      if (id === defaultPaymentMethodId) return;

      const toPaymentMethodType =
        paymentMethods.find((method) => method.id === id)?.card !== null
          ? 'card'
          : 'ach';
      const fromPaymentMethodType =
        paymentMethods.find((method) => method.id === defaultPaymentMethodId)
          ?.card !== null
          ? 'card'
          : 'ach';

      await setDefaultPaymentMethod(id);

      logEvent('default payment method changed', {
        to_payment_method_type: toPaymentMethodType,
        from_payment_method_type: fromPaymentMethodType,
      });

      await refetchPaymentMethod(); // Updates the payment method summary on the overview page

      toast.success('Default payment method changed.');

      // Invalidate the payment methods query to update the UI
      queryClient.invalidateQueries(paymentMethodsQueryKey);
    } catch (error) {
      if (error instanceof Error) {
        toast.error(error.message);
      }
    }
  };

  if (arePaymentMethodsLoading) {
    return (
      <ModalBody>
        <LoadingSpinner>Loading payment methods</LoadingSpinner>
      </ModalBody>
    );
  }

  return (
    <ModalBody>
      <Layout vertical gap="var(--spacing-3)">
        {allPaymentMethods.map((paymentMethod) => (
          <PaymentMethodDetails
            key={paymentMethod.id}
            paymentMethod={paymentMethod}
            actions={
              <Actions>
                {paymentMethod.isVerified && (
                  <Button
                    variant="secondary"
                    size="small"
                    onClick={() => setNewDefaultMethod(paymentMethod.id)}
                  >
                    Set as Active
                  </Button>
                )}

                <Button
                  variant="delete"
                  size="small"
                  icon={IconSet.Trash2}
                  onClick={() =>
                    updateStep('deleteConfirmation', {
                      paymentMethodToDeleteId: paymentMethod.id,
                    })
                  }
                />
              </Actions>
            }
          />
        ))}

        <Flex style={{ gap: 'var(--spacing-2)', alignSelf: 'stretch' }}>
          <AddAchButton onClick={() => updateStep('stripeAch')} />
          <AddCreditCardButton onClick={() => updateStep('stripeCard')} />
        </Flex>
      </Layout>
    </ModalBody>
  );
};
